<template>
  <Modal :modalOpened="modalOpened" @close="modalOpened = false">
    <div class="review-modal">
      <div
        class="text-size-16 text-center py-6 text-borderColor lora-bold workshop-signup-title"
      >
        {{ content.writeReview }}
      </div>
      <div class="px-6">
        <div class="flex flex-col justify-between">
          <div class="mt-8 flex items-center">
            <div class="me-4 text-size-16 w-28">
              {{ content.yourRating }}
            </div>
            <div class="set-rating text-size-20">
              <div class="rating-upper" :style="`width:calc( ( ${rating} / 5 ) * 100%)`">
                <span><i class="fas fa-star" @click="setRating(1)"></i></span>
                <span><i class="fas fa-star" @click="setRating(2)"></i></span>
                <span><i class="fas fa-star" @click="setRating(3)"></i></span>
                <span><i class="fas fa-star" @click="setRating(4)"></i></span>
                <span><i class="fas fa-star" @click="setRating(5)"></i></span>
              </div>
              <div class="rating-lower">
                <span><i class="fas fa-star" @click="setRating(1)"></i></span>
                <span><i class="fas fa-star" @click="setRating(2)"></i></span>
                <span><i class="fas fa-star" @click="setRating(3)"></i></span>
                <span><i class="fas fa-star" @click="setRating(4)"></i></span>
                <span><i class="fas fa-star" @click="setRating(5)"></i></span>
              </div>
            </div>
          </div>
          <div class="flex items-start mt-5">
            <div class="me-4 text-size-16 w-28">
              {{ content.yourReviews }}
            </div>
            <div>
              <textarea
                style="resize: none"
                class="w-full form-input"
                :placeholder="content.reviewPlaceHolder"
                cols="40"
                v-model="review"
              ></textarea>
            </div>
          </div>
          <button
            class="header-btn hover:text-bluePrimary hover:bg-blueSecondary lora-bold whitespace-nowrap bg-bluePrimary text-white duration-300 w-full mt-20"
            @click="addReview"
          >
            {{ content.post }}
          </button>
        </div>
      </div>
    </div>
  </Modal>

  <div class="layout-padding my-8">
    <DetailsSkeleton v-if="loading" />
    <div v-else-if="product" class="grid grid-cols-2 gap-x-0 lg:gap-x-10 lg:row-start-2">
      <div class="col-span-2 lg:col-span-1">
        <div class="breadcrumb mt-3">
          <router-link to="/">{{ content.home }}</router-link> /
          <router-link to="/shop"> {{ content.shop }}</router-link> /
          {{ content.candles }}
        </div>
        <div class="page-title lora-bold my-5">
          {{ product.details.title }}
        </div>
        <div class="flex flex-wrap items-center mt-2">
          <div class="flex items-center pe-8 me-8 border-end w-max-content">
            <div class="rating">
              <div
                class="rating-upper"
                :style="`width:calc( ( ${product.rating} / 5 ) * 100%)`"
              >
                <span><i class="fas fa-star"></i></span>
                <span><i class="fas fa-star"></i></span>
                <span><i class="fas fa-star"></i></span>
                <span><i class="fas fa-star"></i></span>
                <span><i class="fas fa-star"></i></span>
              </div>
              <div class="rating-lower">
                <span><i class="fas fa-star"></i></span>
                <span><i class="fas fa-star"></i></span>
                <span><i class="fas fa-star"></i></span>
                <span><i class="fas fa-star"></i></span>
                <span><i class="fas fa-star"></i></span>
              </div>
            </div>
            <div class="ms-4">{{ format(product.ratings) }} {{ content.review_s }}</div>
          </div>
          <div
            v-if="product.stock > 0"
            class="Availability lora-bold text-borderColor h-5"
          >
            {{ product.stock }} {{ content.inStock }}
          </div>
          <div v-else class="Availability lora-bold text-rejected h-5">
            {{ content.outOfStock }}
          </div>
        </div>
        <div class="mt-5" v-if="product.collection">
          {{ product.collection.title }}
        </div>
        <div class="mt-2">
          {{ product.details.description }}
        </div>
        <div class="my-6 space-y-4">
          <div class="flex items-center pe-5 me-5" v-if="sizes.length > 0">
            <p class="w-10">{{ content.size }}</p>
            <Pill
              v-for="(size, index) in sizes"
              :key="size.id"
              class="me-2 w-max-content"
              :class="{ 'active-pill-product': selectedSize == size.id }"
              :text="size.size.title"
              @click="setSize(index, size.id)"
            />
          </div>
          <div class="flex items-center" v-if="colors?.length >= 1">
            <p class="w-14">{{ content.colors }}</p>
            <ColorRadio
              v-for="(color, index) in colors"
              :key="color.id"
              class="me-2 cursor-pointer"
              :color="color.color.color"
              @updateColor="setColor(index, color.color.id)"
              :selectedColor="selectedColor == color.color.id"
            />
          </div>
          <div class="flex items-center flex-wrap" v-if="product?.scents?.length >= 1">
            <p class="w-14">{{ content.scents }}</p>
            <ScentPill
              v-for="(scent, index) in scents"
              :key="scent.id"
              class="me-2 w-max-content"
              :class="{
                'active-pill-product': selectedScent == scent.scent.id,
              }"
              :text="scent.scent.title"
              :image="scent.scent.image"
              @click="setScent(index, scent.scent.id)"
            />
          </div>
        </div>
        <div class="flex flex-wrap items-center mt-6">
          <div class="text-size-16 lora-bold mb-4 me-10 whitespace-nowrap">
            {{
              product.details?.price
                ? format(product.details.price)
                : format(product?.sizes[sizeIndex]?.price)
            }}
            {{ content.iqd }}
          </div>
          <div class="flex items-center mb-4" v-if="product.stock>0">
            <div class="me-6">{{ content.quantity }}</div>
            <div class="relative">
              <input
                type="number"
                min="1"
                :max="product.stock"
                class="qty-input"
                v-model="qty"
              />
              <button class="qty-increase" @click="increaseQty">+</button>
              <button class="qty-decrease" @click="decreaseQty">-</button>
            </div>
          </div>
        </div>
        <p class="error h-3">
          {{ colorError }}
        </p>
        <p class="error h-3">
          {{ scentError }}
        </p>
        <div class="mt-12" v-if="product.stock>0">
          <button
            class="header-btn text-white hover:text-bluePrimary bg-bluePrimary lora-bold whitespace-nowrap hover:bg-blueSecondary duration-300 me-4"
            @click="buyNow"
          >
            {{ content.buyNow }}
          </button>
          <span
            v-if="addingToCart || addedToCart"
            class="header-btn bg-cancelled bg-opacity-10 text-cancelled border-cancelled border-1 lora-bold whitespace-nowrap duration-300"
            disabled
          >
            {{
              addingToCart
                ? content.addingToCart
                : addedToCart
                ? content.addedToCart
                : content.addToCart
            }}
          </span>
          <button
            v-else
            class="header-btn hover:text-white text-borderColor border-borderColor border-1 hover:border-borderColor lora-bold whitespace-nowrap hover:bg-borderColor duration-300"
            @click="addToCart"
          >
            {{ content.addToCart }}
          </button>
        </div>
        <div v-else class="text-lg text-bluePrimary">
        {{ content.currentlyUnavailable }}
        </div>
      </div>
      <div class="col-span-2 lg:col-span-1 mt-16 lg:my-0">
        <div class="grid grid-cols-10 gap-x-2">
          <div class="md:col-span-8 col-span-10">
            <swiper
              :slides-per-view="1"
              :space-between="20"
              :loop="true"
              :effect="'fade'"
              @swiper="setThumbsSwiper"
              :allowTouchMove="false"
            >
              <swiper-slide v-for="(slide, index) in galleryImages" :key="index">
                <div
                  :style="`background-image: url(${slide});`"
                  class="about-slide-image"
                />
              </swiper-slide>
            </swiper>
          </div>
          <div class="md:col-span-2 col-span-10">
            <div class="slide-navigation md:block flex">
              <div
                class="card"
                v-for="(slide, index) in galleryImages"
                :key="index"
                @click="changeSlide(index + 1)"
                :class="{ 'active-slide': currentSlide == index }"
              >
                <div
                  class="slider-thumb"
                  :class="{ 'active-thumb': currentSlide == index + 1 }"
                >
                  <img :src="slide" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr class="my-12" style="color: #eee" />
    <div class="flex justify-between flex-wrap items-center">
      <div class="text-size-16 mb-4 me-6">
        {{ content.customerReviews }}
      </div>
      <div>
        <button
          class="header-btn text-bluePrimary hover:text-white hover:bg-bluePrimary lora-bold whitespace-nowrap bg-blueSecondary duration-300"
          @click="openModal"
        >
          {{ content.writeReview }}
        </button>
      </div>
    </div>

    <div class="mt-7" v-if="loading">
      <CustomerReviewsCardSkeleton />
    </div>
    <div class="mt-7" v-else-if="product?.reviews">
      <div class="my-5" v-for="review in showReviews" :key="review.id">
        <CustomerReviewsCard :review="review" />
      </div>
      <div
        v-if="product.reviews.length > reviewsToShow"
        class="flex items-center justify-center"
      >
        <button
          class="header-btn text-bluePrimary hover:text-white hover:bg-bluePrimary lora-bold whitespace-nowrap bg-blueSecondary duration-300"
          @click="loadMore"
        >
          {{ content.loadMore }}
        </button>
      </div>
    </div>
    <div class="mt-12">
      <div class="page-title lora-bold">{{ content.youMightAlsoLike }}</div>
      <div v-if="loading" class="grid grid-cols-12 mt-6 gap-y-10 sm:gap-x-10 gap-x-0">
        <div
          class="col-span-12 sm:col-span-6 lg:col-span-3"
          v-for="index in 4"
          :key="index"
        >
          <ProductCardSkeleton />
        </div>
      </div>
      <div v-else class="grid grid-cols-12 mt-6 gap-y-10 sm:gap-x-10 gap-x-0">
        <div
          class="col-span-12 sm:col-span-6 lg:col-span-3"
          v-for="product in similar"
          :key="product.id"
        >
          <ProductCard :product="product" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import { EffectFade } from "swiper";
import SwiperCore from "swiper";

SwiperCore.use([EffectFade]);

import "swiper/swiper-bundle.css";

import Pill from "/src/components/ui/Pill.vue";
import ScentPill from "/src/components/ui/ScentPill.vue";
import ColorRadio from "/src/components/ui/Products/ProductColorRadio.vue";
import CustomerReviewsCard from "/src/components/ui/Products/CustomerReviewsCard.vue";
import CustomerReviewsCardSkeleton from "/src/components/ui/Skeletons/CustomerReviewsCardSkeleton.vue";
import DetailsSkeleton from "/src/components/ui/Skeletons/DetailsSkeleton.vue";
import ProductCardSkeleton from "/src/components/ui/Skeletons/ProductCardSkeleton.vue";
import ProductCard from "/src/components/ui/Products/ProductCard.vue";
import { format } from "/src/assets/numberFormat";

export default {
  components: {
    Swiper,
    SwiperSlide,
    Pill,
    ScentPill,
    ColorRadio,
    CustomerReviewsCard,
    CustomerReviewsCardSkeleton,
    ProductCard,
    DetailsSkeleton,
    ProductCardSkeleton,
  },
  props: ["id"],
  inject: ["content"],
  emits: ["updateColor"],
  data() {
    return {
      loading: true,
      selectedSize: null,
      sizeIndex: null,
      selectedColor: null,
      colorIndex: null,
      selectedScent: null,
      scentIndex: null,
      qty: 1,
      galleryImages: [],
      rating: 0,
      review: "",
      currentSlide: 0,
      modalOpened: false,
      error: null,
      colorError: null,
      scentError: null,
      reviewed: false,
      addingToCart: false,
      addedToCart: false,
      reviewsToShow: 3,
      // imgs: ["/static/images/slider.png", "/static/images/press.png"],
    };
  },
  async mounted() {
    await this.$store.dispatch("products/product", this.id);
    await this.$store.dispatch("products/similar", this.id);
    this.loading = false;
    this.setSize(0, this.product.sizes[0]?.id ?? null);
    // setTimeout(() => {
    //   if (this.galleryImages.length == 0) {
    //     this.setGalleryImages();
    //   }
    // }, 500);

    // if(this.cart && this.cart.length > 0){
    //   let item = this.cart.find(item => item.product?.id == this.id);
    //   if(item){
    //     this.qty = item.quantity;
    //   }
    // }
  },
  watch: {
    async id() {
      this.loading = true;
      window.scrollTo({ top: 0, behavior: "smooth" });
      await this.$store.dispatch("products/product", this.id);
      await this.$store.dispatch("products/similar", this.id);
      this.loading = false;
      // setTimeout(() => {
      //   // if (this.galleryImages.length == 0) {
      //     this.setGalleryImages();
      //   // }
      // }, 500);
    },
    product() {
      if (this.product) {
        this.setGalleryImages();
      }
    },
    // cart(){
    //   if(this.cart && this.cart.length > 0){
    //     let item = this.cart.find(item => item.product?.id == this.id);
    //     if(item){
    //       this.qty = item.quantity;
    //     }
    //   }
    // }
  },
  computed: {
    product() {
      return this.$store.getters["products/product"];
    },
    similar() {
      return this.$store.getters["products/similar"];
    },
    sizes() {
      let sizes = this.product?.sizes ?? null;
      if (!sizes) return [];

      return sizes;
    },
    colors() {
      let colors = this.product?.colors;
      return colors.filter((color) => color?.color);
    },
    scents() {
      let scents = this.product?.scents;
      return scents.filter((scent) => scent?.scent);
    },
    images() {
      let images = [];

      if (this.product?.details?.image) images.push(this.product?.details?.image);

      if (this.shop?.images) {
        for (let i = 0; i < this.shop?.images?.length; i++) {
          images.push(this.shop?.images[i].url);
        }
      }

      return images;
    },
    auth() {
      return this.$store.getters["auth/auth"];
    },
    cart() {
      return this.auth
        ? this.$store.getters["cart/cart"]
        : this.$store.getters["cart/cartLocal"];
    },
    showReviews() {
      // let reviews = this.product?.reviews;
      return this.product?.reviews.slice(0, this.reviewsToShow);
    },
  },
  methods: {
    loadMore() {
      this.reviewsToShow += 3;
    },
    setThumbsSwiper(swiper) {
      this.swiperEvent = swiper;
    },
    setGalleryImages() {
      this.galleryImages = [];
      this.galleryImages.push(this.product.details.image);
      for (let i = 0; i < this.product?.images?.length; i++) {
        this.galleryImages.push(this.product?.images[i]?.url);
      }
    },
    setRating(rate) {
      this.rating = rate;
    },
    increaseQty() {
      if (this.qty < this.product.stock) this.qty = this.qty + 1;
    },
    decreaseQty() {
      if (this.qty > 1) this.qty = this.qty - 1;
    },
    updateColor(color) {
      this.selectedColor = color;
      this.colorError = null;
    },
    changeSlide(index) {
      this.swiperEvent.slideTo(index, 1000);
      this.currentSlide = index;
    },
    setSize(index, id) {
      this.sizeIndex = index;
      this.selectedSize = id;
    },
    setColor(index, id) {
      this.colorIndex = index;
      this.selectedColor = id;
    },
    setScent(index, id) {
      this.scentIndex = index;
      this.selectedScent = id;
    },
    format(number) {
      return format(number);
    },
    buyNow() {
      this.error = null;
      this.colorError = null;
      this.scentError = null;

      let query = {
        product: this.id,
        quantity: this.qty,
      };

      if (this.product.sizes.length >= 1 && !this.selectedSize) return;
      if (this.product.colors.length >= 1 && !this.selectedColor) {
        this.colorError = this.content.pickColor;
        return;
      }
      if (this.product.scents.length >= 1 && !this.selectedScent) {
        this.scentError = this.content.pickScent;
        return;
      }

      if (this.product.sizes && this.selectedSize) {
        query.size = this.selectedSize;
      }
      // else return;

      if (this.product.colors && this.selectedColor) {
        query.color = this.selectedColor;
      }
      if (this.product.scents && this.selectedScent) {
        query.scent = this.selectedScent;
      }
      // else {
      //   return;
      // }

      this.$router.push({
        name: "checkout",
        query,
      });
    },
    async addToCart() {
      this.error = null;
      this.colorError = null;
      this.scentError = null;

      if (this.product.sizes.length >= 1 && !this.selectedSize) return;
      if (this.product.colors.length >= 1 && !this.selectedColor) {
        this.colorError = this.content.pickColor;
        return;
      }
      if (this.product.scents.length >= 1 && !this.selectedScent) {
        this.scentError = this.content.pickScent;
        return;
      }

      try {
        this.addingToCart = true;
        let input = {};
        if (this.auth) {
          input = {
            id: this.id,
            quantity: this.qty,
          };

          if (this.selectedColor) input.color = this.selectedColor;
          if (this.selectedSize) input.size = this.selectedSize;
          if (this.selectedScent) input.scent = this.selectedScent;

          await this.$store.dispatch("cart/add", input);
        } else {
          input = {
            product: this.product,
            quantity: this.qty,
          };

          if (this.product.colors.length >= 1)
            input.color = this.product.colors[this.colorIndex];
          if (this.product.sizes.length >= 1)
            input.size = this.product.sizes[this.sizeIndex];
          if (this.product.scents.length >= 1)
            input.scent = this.product.scents[this.scentIndex];

          await this.$store.dispatch("cart/addLocal", input);
        }
        this.addingToCart = false;
        this.addedToCart = true;
        this.$store.dispatch("feedback/setFeedback", {
          type: "success",
          title: this.content.success,
          message: this.content.addToCartSuccess,
        });
        setTimeout(() => {
          this.addedToCart = false;
        }, 6000);
      } catch (error) {
        this.addingToCart = false;
        this.addedToCart = false;
        this.error = true;
        this.$store.dispatch("feedback/setFeedback", {
          type: "fail",
          title: this.content.fail,
          message: this.content.addToCartFail,
        });
      }
    },
    openModal() {
      if (!this.auth) {
        this.$store.dispatch("feedback/setFeedback", {
          type: "fail",
          title: this.content.fail,
          message: this.content.needToSignIn,
        });
        return;
      }
      if (!this.product.review || this.reviewed) {
        this.$store.dispatch("feedback/setFeedback", {
          type: "fail",
          title: this.content.fail,
          message: this.content.alreadyReviewed,
        });
        return;
      }

      this.modalOpened = !this.modalOpened;
    },
    async addReview() {
      if (this.rating < 0 || this.rating > 5 || this.review == "") return;

      try {
        await this.$store.dispatch("reviews/add", {
          id: this.id,
          rating: this.rating,
          review: this.review,
        });
        this.modalOpened = false;
        this.reviewed = true;
        // show success feedback
        this.$store.dispatch("feedback/setFeedback", {
          type: "success",
          title: this.content.success,
          message: this.content.reviewAdded,
        });
      } catch (error) {
        // show fail feedback
        this.$store.dispatch("feedback/setFeedback", {
          type: "fail",
          title: this.content.fail,
          message: this.content.reviewFail,
        });
      }
    },
  },
};
</script>

<style>
.workshops-pagination {
  position: absolute;
  bottom: 30px;
  left: 0vw;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 2;
}
.workshops-pagination .swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
  padding: 20px 4vw;
  display: flex;
}
.workshops-pagination .swiper-pagination-bullet {
  border-radius: 0;
  height: 3px;
  width: 60px !important;
  background: #302b87 !important;
  opacity: 0.5 !important;
}
.workshops-pagination .swiper-pagination-bullet-active {
  background: #302b87;
  opacity: 1 !important;
}
@media (max-width: 567px) {
  .workshops-pagination .swiper-pagination-bullet {
    width: 30px !important;
  }
}
</style>
