export default {
  wicks: state => state.wicks,
  scents: state => state.scents,
  sizes: state => state.sizes,
  colors: state => state.colors,
  containers: state => state.containers,
  packages: state => state.packages,
  refillContainers: state => state.refillContainers,
  wick: state => id => state.wicks.find(item => item.id == id),
  scent: state => id => state.scents.find(item => item.id == id),
  size: state => id => state.sizes.find(item => item.id == id),
  color: state => id => state.colors.find(item => item.id == id),
  container: state => id => state.containers.find(item => item.id == id),
  refillContainer: state => id => state.refillContainers.find(item => item.id == id),
  // containerSize: state => ids => {
  //   let container = state.containers.filter(item => item.id == ids[0])[0]
  //   console.log(container);
  //   return container.sizes.filter(item => item.id == ids[1])[0]
  // },
  onePackage: state => id => state.packages.find(item => item.id == id),
}