import { createApp } from "vue"
import GAuth from 'vue3-google-oauth2'
import App from "./App.vue"
import router from "./router"
import store from "./store"


import FillButton from "/src/components/ui/Buttons/FillButton.vue"
import OutlineButton from "/src/components/ui/Buttons/OutlineButton.vue"
import PageTitle from "/src/components/ui/PageTitle.vue"
// import VueEasyLightbox from "vue-easy-lightbox"
import Modal from "/src/components/ui/Modal.vue"
import Alert from "/src/components/ui/Alert.vue"
import VueGtag from "vue-gtag"



const gClientId = '300261001231-125ikt0p6i0ejd23hktscgefdm6nsi77.apps.googleusercontent.com'

const gAuthOptions = {
  clientId: gClientId,
  scope: 'profile',
  prompt: 'select_account',
  fetch_basic_profile: true
}

const app = createApp(App)
app.use(store)
app.use(router)
// app.use(VueEasyLightbox)
app.use(GAuth, gAuthOptions)
app.use(VueGtag, {
  config: { id: "UA-221041790-1" }
  // config: { id: "G-63NZCEBHM9" }
  // config: { id: "G-5QL3X0Q8D6" }
  // config: { id: "304409934" }
}, router)
app.component("Alert", Alert)
app.component("PageTitle", PageTitle)
app.component("Modal", Modal)
app.component("FillButton", FillButton)
app.component("OutlineButton", OutlineButton)
app.mount("#app")
