<template>
  <div class="large-screen-header">
    <div class="upper-header layout-padding flex justify-between">
      <div class="left-section flex items-center h-full">
        <div class="flex items-center flex-wrap justify-center wholesale-section">
          <div class="whitespace-nowrap">
            {{ label?.title }}
          </div>
          <div>
            <router-link to="/whitelabel/request">
              <button
                class="header-btn text-bluePrimary hover:text-white hover:bg-bluePrimary lora-bold whitespace-nowrap bg-blueLight duration-300 xl:mx-5 mx-3"
              >
                {{ content.calculatePriceAndOrder }}
              </button>
            </router-link>
          </div>
        </div>
      </div>
      <div class="right-section flex items-center h-full">
        <div v-if="mobile" class="header-follow flex justify-between items-center">
          <div class="lg:mx-5 mx-3 text-md whitespace-nowrap">
            {{ content.followUs }}
          </div>
          <a :href="facebook.url" target="_blank" v-if="facebook">
            <i class="fab cursor-pointer mx-2 fa-facebook-square"></i>
          </a>
          <a :href="instagram.url" target="_blank" v-if="instagram">
            <i class="fab cursor-pointer mx-2 fa-instagram"></i>
          </a>
          <a :href="twitter.url" target="_blank" v-if="twitter">
            <i class="fab cursor-pointer mx-2 fa-twitter-square"></i>
          </a>
          <a :href="pinterest.url" target="_blank" v-if="pinterest">
            <i class="fab cursor-pointer mx-2 fa-pinterest-square"></i>
          </a>
          <a :href="tiktok.url" target="_blank" v-if="tiktok">
            <i class="fab cursor-pointer mx-2 fa-tiktok"></i>
          </a>
        </div>
        <div v-else class="header-follow flex justify-between items-center">
          <div class="lg:mx-5 mx-3 text-md whitespace-nowrap">
            {{ content.followUs }}
          </div>
          <button v-if="facebook" @click="newWindow(facebook.url)">
            <i class="fab cursor-pointer mx-2 fa-facebook-square"></i>
          </button>
          <button v-if="instagram" @click="newWindow(instagram.url)">
            <i class="fab cursor-pointer mx-2 fa-instagram"></i>
          </button>
          <button v-if="twitter" @click="newWindow(twitter.url)">
            <i class="fab cursor-pointer mx-2 fa-twitter-square"></i>
          </button>
          <button v-if="pinterest" @click="newWindow(pinterest.url)">
            <i class="fab cursor-pointer mx-2 fa-pinterest-square"></i>
          </button>
          <button v-if="tiktok" @click="newWindow(tiktok.url)">
            <i class="fab cursor-pointer mx-2 fa-tiktok"></i>
          </button>
        </div>
        <div class="language-dropdown-btn relative">
          <div
            class="flex items-center cursor-pointer"
            @click="opneMenu('languages')"
            id="languages-btn"
          >
            <div class="current-language mx-1">
              {{ content.selectedLanguage }}
            </div>
            <i class="fas mx-1 fa-caret-down"></i>
          </div>
          <div>
            <transition name="fade">
              <LanguagesDropdown v-show="openedMenu == 'languages'"
            /></transition>
          </div>
        </div>
        <div class="account-dropdown-btn relative flex justify-between items-center">
          <div
            v-if="user"
            @click="opneMenu('account')"
            class="cursor-pointer flex items-center"
            id="account-btn"
          >
            <div
              @click="opneMenu('account')"
              class="user-img rounded-full border border-secondaryColor"
              :style="`background: url(
                ${user.profile ?? '/static/images/profile-ph.png'}
              )`"
            ></div>
            <div class="username">{{ user.name }}</div>
          </div>

          <div v-else>
            <router-link to="/signin">
              <span
                class="lora-bold hover:text-primaryColor transition-all duration-200"
                >{{ content.signIn }}</span
              >
            </router-link>
            {{ content.or }}
            <router-link to="/signup">
              <span
                class="lora-bold hover:text-primaryColor transition-all duration-200"
                >{{ content.register }}</span
              >
            </router-link>
          </div>
          <transition name="fade">
            <AccountDropdown v-show="openedMenu == 'account'" @closeMenu="closeAll" />
          </transition>
        </div>
      </div>
    </div>
    <div class="lower-header layout-padding flex justify-between">
      <div class="header-logo">
        <router-link to="/">
          <img :src="`/static/images/header-logo-${language}.png`" />
        </router-link>
      </div>
      <div class="flex h-full items-center relative">
        <router-link to="/">
          <div
            class="xl:mx-6 hover:text-primaryColor transition-all duration-200 mx-4 cursor-pointer text-15px"
          >
            {{ content.home }}
          </div>
        </router-link>
        <router-link
          class="xl:mx-6 hover:text-primaryColor transition-all duration-200 mx-4 cursor-pointer text-15px flex items-center relative"
          to="/shop"
        >
          {{ content.shop }}
        </router-link>

        <div class="relative">
          <router-link
            class="xl:mx-6 hover:text-primaryColor transition-all duration-200 mx-4 cursor-pointer text-15px flex items-center relative"
            to="/whitelabel/projects"
          >
            {{ content.whiteLabelCandles }}
          </router-link>
         
        </div>
        <router-link to="/refill">
          <div
            class="xl:mx-6 hover:text-primaryColor transition-all duration-200 mx-4 cursor-pointer text-15px"
          >
            {{ content.refills }}
          </div>
        </router-link>
        <router-link to="/workshops">
          <div class="relative">
            <div
              class="xl:mx-6 hover:text-primaryColor transition-all duration-200 mx-4 cursor-pointer text-15px relative flex items-center"
            >
              {{ content.workshops }}
            </div>
          </div>
        </router-link>
        <div class="relative">
          <router-link
            class="xl:mx-6 hover:text-primaryColor transition-all duration-200 mx-4 cursor-pointer text-15px flex items-center relative"
            to="/whoweare"
          >
            {{ content.about }}
          </router-link>
          
        </div>
      
        <transition name="fade">
          <ShopMenu
            v-show="openedMenu == 'shop'"
            @mouseleave="openedMenu = null"
            @closeMenu="closeAll"
        /></transition>
      </div>

      <div class="cart-section justify-between flex h-full items-center">
        <i class="fas fa-search cursor-pointer" @click="$router.push('/search')"></i>
        <router-link class="relative" to="/cart">
          <i class="fas fa-shopping-cart cursor-pointer"></i>

          <div class="items-in-cart" v-if="cart">{{ cartQuantity }}</div>
        </router-link>
      </div>
    </div>
    <div
      v-if="showPending"
      class="layout-padding flex justify-between items-center bg-secondaryLight text-bluePrimary py-4"
    >
      <div class="flex justify-between items-center">
        <p class="font-semibold">{{ content.accountPending }}</p>
        <button
          class="mx-4 text-white alert-btn font-semibold bg-secondaryColor"
          @click="resendEmail"
        >
          {{ content.resendEmail }}
        </button>
      </div>
      <button @click="closePending" class="px-2 text-xl">
        <i class="far fa-times-circle"></i>
      </button>
    </div>
  </div>
  <div class="small-screen-header">
    <div class="lower-header layout-padding flex justify-between">
      <router-link to="/">
        <div class="header-logo">
          <img :src="`/static/images/logo-mobile-${language}.png`" />
        </div>
      </router-link>

      <div class="cart-section relative justify-between flex h-full items-center">
        <i
          class="fas fa-search cursor-pointer search-icon-mobile"
          @click="$router.push('/search')"
        ></i>
        <img
          src="/static/images/icons/language.svg"
          class="language-icon cursor-pointer me-3"
          id="languages-mobile-btn"
          @click="opneMenu('languages')"
        />
        <transition name="fade">
          <LanguagesDropdown v-show="openedMenu == 'languages'" />
        </transition>
        <router-link class="relative me-3" to="/cart">
          <i class="fas fa-shopping-cart"></i>
          <div v-if="cart" class="items-in-cart">{{ cartQuantity }}</div>
          <div v-if="localCart" class="items-in-cart">{{ localCart }}</div>
        </router-link>
        <div
          v-if="user"
          class="user-img"
          @click="opneMenu('account')"
          id="account-mobile-btn"
          :style="`background-image:url(${
            user.profile ?? '/static/images/profile-ph.png'
          })`"
        ></div>
        <div
          v-else
          class="user-img"
          @click="$router.push('/signin')"
          id="account-mobile-btn"
          :style="`background:url(/static/images/icons/user-icon.svg)`"
        ></div>
        <div>
          <label
            for="check"
            class="hamburger-icon"
            :class="{ 'menu-icon-opened': mobileMenuOpened }"
          >
            <input type="checkbox" id="check" v-model="mobileMenuOpened" />
            <span></span>
            <span></span>
            <span></span>
          </label>
        </div>
        <transition name="fade">
          <AccountDropdown v-show="openedMenu == 'account'" @closeMenu="closeAll"
        /></transition>
      </div>
    </div>
    <div
      v-if="showPending"
      class="pending-header layout-padding grid grid-cols-10 md:flex md:justify-between md:items-center bg-secondaryLight text-bluePrimary py-3"
    >
      <div class="flex justify-between items-center col-span-9 order-1">
        <p class="font-semibold">{{ content.accountPending }}</p>
        <button
          class="hidden md:block mx-4 text-white alert-btn font-semibold bg-secondaryColor"
          @click="resendEmail"
        >
          {{ content.resendEmail }}
        </button>
      </div>
      <button
        @click="closePending"
        class="md:px-2 py-1 end-0 flex justify-end text-lg col-span-1 order-2"
      >
        <i class="far fa-times-circle"></i>
      </button>
      <div class="md:hidden col-span-full order-3 mt-2">
        <button
          class="text-white alert-btn font-semibold bg-secondaryColor"
          @click="resendEmail"
        >
          {{ content.resendEmail }}
        </button>
      </div>
    </div>
  </div>
  <transition name="menu-animation">
    <div
      class="mobile-menu"
      :class="{ 'mobile-menu-opened': mobileMenuOpened }"
      v-show="mobileMenuOpened"
    >
      <router-link to="/" @click="mobileMenuOpened = false">
        <div class="mobile-menu-item-title">
          {{ content.home }}
        </div>
      </router-link>
      <router-link to="/shop" @click="mobileMenuOpened = false">
        <div class="mobile-menu-item-title">
          {{ content.shop }}
        </div>
      </router-link>
      <router-link to="/whitelabel/projects" @click="mobileMenuOpened = false">
        <div class="mobile-menu-item-title">
          {{ content.whiteLabelCandles }}
        </div>
      </router-link>
    
      <router-link @click="mobileMenuOpened = false" to="/refill">
        <div class="mobile-menu-item-title">
          {{ content.refills }}
        </div>
      </router-link>
      <router-link @click="mobileMenuOpened = false" to="/workshops">
        <div class="mobile-menu-item-title">
          {{ content.workshops }}
        </div>
      </router-link>
      <router-link @click="mobileMenuOpened = false" to="/whoweare">
        <div class="mobile-menu-item-title">
          {{ content.about }}
        </div>
      </router-link>
      <router-link @click="mobileMenuOpened = false" to="/sellingpoints/ourstudio">
        <div class="mobile-menu-item-title">
          {{ content.ourStudio }}
        </div>
      </router-link>
      <router-link @click="mobileMenuOpened = false" to="/sellingpoints/sellingsites">
        <div class="mobile-menu-item-title">
          {{ content.sellingSites }}
        </div>
      </router-link>
      <router-link
        @click="mobileMenuOpened = false"
        to="/sellingpoints/onlinesellingpoints"
      >
        <div class="mobile-menu-item-title">
          {{ content.onlineSellingPoints }}
        </div>
      </router-link>
    
    </div>
  </transition>
</template>

<script>
import LanguagesDropdown from "/src/components/ui/MenuDropdown/LanguageDropdown.vue";
import AccountDropdown from "/src/components/ui/MenuDropdown/AccountDropdown.vue";
// import AboutMenu from "/src/components/ui/MenuDropdown/AboutMenu.vue";
// import MoreLinksMenu from "/src/components/ui/MenuDropdown/MoreLinksMenu.vue";
import ShopMenu from "/src/components/ui/MenuDropdown/ShopMenu.vue";
// import WhiteLabelMenu from "/src/components/ui/MenuDropdown/WhiteLabelMenu.vue";
// import MenuCollapse from "/src/components/ui/Collapse/MenuCollapse.vue";
// import MenuAllButton from "/src/components/ui/Buttons/MenuAllButton.vue";

export default {
  components: {
    LanguagesDropdown,
    AccountDropdown,
    // AboutMenu,
    // MoreLinksMenu,
    ShopMenu,
    // WhiteLabelMenu,
    // MenuCollapse,
    // MenuAllButton,
  },
  inject: ["content"],
  data() {
    return {
      openedMenu: null,
      pending: true,
      mobileMenuOpened: false,
    };
  },
  watch: {
    mobileMenuOpened() {
      if (this.mobileMenuOpened == true) {
        document.body.classList.add("overflow-hidden");
      } else if (this.mobileMenuOpened == false) {
        document.body.classList.remove("overflow-hidden");
      }
    },
  },
  mounted() {
    window.addEventListener("click", this.closeMenu);
  },
  computed: {
    language() {
      return localStorage.getItem("lang") ?? "en";
    },
    mobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      }
      return false;
    },
    user() {
      return this.$store.getters["user/user"];
    },
    localCart() {
      let items = 0;
      if (localStorage.getItem("cart")) {
        items = JSON.parse(localStorage.getItem("cart")).items.length;
      }
      return items;
    },
    localRefillCart() {
      let items = 0;
      if (localStorage.getItem("refill-cart")) {
        items = JSON.parse(localStorage.getItem("refill-cart")).items.length;
      }
      return items;
    },
    showPending() {
      return this.user?.status == "pending" && this.pending;
    },
    cart() {
      return this.user
        ? this.$store.getters["cart/cart"]
        : this.$store.getters["cart/cartLocal"];
    },
    refillCart() {
      return this.user
        ? this.$store.getters["refill/cart"]
        : this.$store.getters["refill/cartLocal"];
    },
    cartQuantity() {
      let quantity = 0;
      if (this.cart) {
        this.cart.forEach((item) => {
          quantity += item.quantity;
        });
      }
      if (this.cart) {
        this.refillCart.forEach((item) => {
          quantity += item.quantity;
        });
      }
      return quantity;
    },
    socialMedia() {
      return this.$store.getters["contact/socialMedia"];
    },
    facebook() {
      return this.socialMedia.filter((item) => item.title == "facebook")[0];
    },
    instagram() {
      return this.socialMedia.filter((item) => item.title == "instagram")[0];
    },
    twitter() {
      return this.socialMedia.filter((item) => item.title == "twitter")[0];
    },
    pinterest() {
      return this.socialMedia.filter((item) => item.title == "pinterest")[0];
    },
    tiktok() {
      return this.socialMedia.filter((item) => item.title == "tiktok")[0];
    },
    collections() {
      let collections = this.$store.getters["collections/collections"];
      return collections.length < 6 ? collections : collections.slice(0, 5);
    },
    label() {
      return this.$store.getters["pages/text"]("label");
    },
    categories() {
      return this.$store.getters["categories/categories"];
    },
    candles() {
      let candles = this.categories[0]?.subCategories ?? null;
      return candles ? (candles.length < 6 ? candles : candles.slice(0, 5)) : null;
    },
  },
  methods: {
    newWindow(url) {
      window.open(url, "Name", "popup");
    },
    closePending() {
      this.pending = false;
    },
    closeAll() {
      this.openedMenu = null;
    },
    async resendEmail() {
      try {
        this.closePending();
        await this.$store.dispatch("auth/resendEmail");
        this.$store.dispatch("feedback/setFeedback", {
          type: "success",
          title: this.content.success,
          message: this.content.checkEmail,
        });
      } catch (error) {
        this.$store.dispatch("feedback/setFeedback", {
          type: "fail",
          title: this.content.fail,
          message: this.content.errorOccured,
        });
      }
    },
    closeMenu(event) {
      if (this.user) {
        if (
          !document.getElementById("account-menu")?.contains(event.target) &&
          !document.getElementById("account-btn")?.contains(event.target) &&
          !document.getElementById("languages-menu")?.contains(event.target) &&
          !document.getElementById("languages-mobile-btn")?.contains(event.target) &&
          !document.getElementById("account-mobile-btn")?.contains(event.target) &&
          !document.getElementById("languages-btn")?.contains(event.target) &&
          !document.getElementById("shop-menu")?.contains(event.target) &&
          !document.getElementById("shop-btn")?.contains(event.target) &&
          !document.getElementById("about-menu")?.contains(event.target) &&
          !document.getElementById("about-btn")?.contains(event.target) &&
          !document.getElementById("links-menu")?.contains(event.target) &&
          !document.getElementById("links-btn")?.contains(event.target) &&
          !document.getElementById("white-label-menu")?.contains(event.target) &&
          !document.getElementById("white-label-btn")?.contains(event.target)
        ) {
          this.openedMenu = null;
        }
      } else {
        if (
          !document.getElementById("languages-menu")?.contains(event.target) &&
          !document.getElementById("languages-mobile-btn")?.contains(event.target) &&
          !document.getElementById("languages-btn")?.contains(event.target) &&
          !document.getElementById("shop-menu")?.contains(event.target) &&
          !document.getElementById("shop-btn")?.contains(event.target) &&
          !document.getElementById("about-menu")?.contains(event.target) &&
          !document.getElementById("about-btn")?.contains(event.target) &&
          !document.getElementById("links-menu")?.contains(event.target) &&
          !document.getElementById("links-btn")?.contains(event.target) &&
          !document.getElementById("white-label-menu")?.contains(event.target) &&
          !document.getElementById("white-label-btn")?.contains(event.target)
        ) {
          this.openedMenu = null;
        }
      }
    },
    opneMenu(menu) {
      if (this.openedMenu == menu) {
        this.openedMenu = null;
      } else {
        this.openedMenu = menu;
      }
    },
  },
};
</script>
