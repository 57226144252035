import { http } from '../../../graphql'
import gql from "graphql-tag";

export default {
  add: async (context, payload) => {
    let mutation = gql`
      mutation addToCart($input: CartInput!) {
        cart: addToCart(input: $input){
          ${productSchema}
        }
      }
    `
    const response = await http(false, mutation, true, {
      input: {
        id: payload.id,
        quantity: payload.quantity,
        color: payload.color,
        scent: payload.scent,
        size: payload.size,
      }
    })
    context.commit('setCart', response.cart)
  },
  addLocal: (context, payload) => {
    let localCart = localStorage.getItem('cart')

    let cart = {
      items: [],
    }
    if (localCart && localCart != '' && localCart != 'null' && localCart != 'undefined')
      cart = JSON.parse(localCart)

    let item = cart?.items?.filter(item => item.product.id == payload.product.id)[0]
    if (item) {
      let itemIndex = cart?.items?.findIndex(item => item.product.id == payload.product.id);
      if ((item.color?.id == payload.color?.id)
        && (item.size?.id == payload.size?.id)
        && (item.scent?.id == payload.scent?.id))
        context.dispatch('editLocal', { ...payload, index: itemIndex })
      else {
        let input = {
          product: payload.product,
          quantity: payload.quantity,
        }
        if (payload.color) input.color = payload.color
        if (payload.scent) input.scent = payload.scent
        if (payload.size) {
          input.size = payload.size
          input.total = parseFloat(payload.quantity) * parseFloat(payload.size.price)
        } else {
          input.total = parseFloat(payload.quantity) * parseFloat(payload.product.details.price)
        }

        cart.items.push(input)

        localStorage.setItem('cart', JSON.stringify(cart))
        context.commit('setLocal', cart)
      }
    } else {
      let input = {
        product: payload.product,
        quantity: payload.quantity,
      }
      if (payload.color) input.color = payload.color
      if (payload.scent) input.scent = payload.scent
      if (payload.size) {
        input.size = payload.size
        input.total = parseFloat(payload.quantity) * parseFloat(payload.size.price)
      } else {
        input.total = parseFloat(payload.quantity) * parseFloat(payload.product.details.price)
      }

      cart.items.push(input)

      localStorage.setItem('cart', JSON.stringify(cart))
      context.commit('setLocal', cart)
    }
  },
  edit: async (context, payload) => {
    let mutation = gql`
      mutation editCart($input: CartInput!){
        cart: editCart(input: $input){
          ${productSchema}
        }
      } 
    `

    let input = {
      id: payload.id,
      quantity: payload.quantity,
    }

    if (payload.color) input.color = payload.color
    if (payload.scent) input.scent = payload.scent
    if (payload.size) input.size = payload.size

    const response = await http(false, mutation, true, { input: input })
    context.commit('setCart', response.cart)
  },
  editLocal: (context, payload) => {
    let cart = JSON.parse(localStorage.getItem('cart'))

    cart.items[payload.index].quantity = payload.quantity
    if (cart.items[payload.index].size)
      cart.items[payload.index].total = parseFloat(payload.quantity) * parseFloat(cart.items[payload.index].size.price)
    else
      cart.items[payload.index].total = parseFloat(payload.quantity) * parseFloat(cart.items[payload.index].product.details.price)

    localStorage.setItem('cart', JSON.stringify(cart))
    context.commit('setLocal', cart)
  },
  remove: async (context, payload) => {
    let mutation = gql`
      mutation removeCart($id: ID!){
        cart: removeCart(id: $id){
          ${productSchema}
        }
      } 
    `
    const response = await http(false, mutation, true, { id: payload })
    context.commit('setCart', response.cart)
  },
  removeLocal: (context, payload) => {
    let cart = JSON.parse(localStorage.getItem('cart'))

    if (cart.items.length >= payload) {
      cart.items.splice(payload, 1)

      localStorage.setItem('cart', JSON.stringify(cart))
      context.commit('setLocal', cart)
    }
  },
  loadLocal: context => {
    let localCart = localStorage.getItem('cart')

    let cart = {
      items: [],
    }
    if (localCart && localCart != '' && localCart != 'null' && localCart != 'undefined')
      cart = JSON.parse(localCart)

    context.commit('setLocal', cart)
  },
  saveLocalToDB: async context => {
    let localCart = localStorage.getItem('cart')

    if (localCart && localCart != '' && localCart != 'null' && localCart != 'undefined') {
      let cart = JSON.parse(localCart)
      for (let i = 0; i < cart.items.length; i++) {
        let mutation = gql`
          mutation addToCart ($input: CartInput!){
            cart: addToCart (input: $input){
              id
              quantity
              total
              product {
                id
                stock
                rating
                ratings
                details {
                  title
                  description
                  image
                  price
                }
                collection {
                  id
                  title
                }
              }
              color {
                id
                title
                color
              }
              scent {
                id
                title
                image
              }
              size {
                id
                size {
                  id
                  title
                }
                price
              }
            }
          }
        `

        let input = {
          id: cart.items[i].product.id,
          quantity: cart.items[i].quantity,
        }
        if (cart.items[i].size) input.size = cart.items[i].size.id
        if (cart.items[i].color?.color) input.color = cart.items[i].color?.color?.id
        if (cart.items[i].scent?.scent) input.scent = cart.items[i].scent?.scent?.id

        const response = await http(false, mutation, true, { input: input })
        if (i == cart.items.length - 1)
          context.commit('setCart', response.cart)
      }
    }

    localStorage.removeItem('cart')


    // await context.dispatch('user/user', null, { root: true })

  }
}

const productSchema = `
  id
  quantity
  total
  product {
    id
    stock
    rating
    ratings
    details {
      id
      title
      description
      image
      price
    }
    collection {
      id
      title
    }
  }
  color {
    id
    title
    color
  }
  scent {
    id
    title
    image
  }
  size {
    id
    size {
      id
      title
    }
    price
  }
`