<template>
  <router-link :to="{ name: 'productDetails', params: { id: product?.id } }">
    <div class="product-card">
      <div class="product-card-img-container overflow-hidden">
        <div
          class="product-card-img relative"
          :style="`background: url(${product?.details?.image})`"
        >
          <div
            v-if="product?.new"
            class="
              absolute
              rounded-full
              px-4
              py-1
              new-product
              bg-bluePrimary
              text-white
            "
          >
            {{ content.new }}
          </div>
        </div>
      </div>
      <div>
        <div>
          <div class="lora-bold mt-5 px-5 cursor-pointer">
            {{ product?.details?.title }}
          </div>
          <div class="lora-italic mt-2 px-5" v-if="product?.collection">
            {{ product?.collection?.title }}
          </div>
          <div class="px-5 flex items-center mt-2">
            <div class="rating text-size-14">
              <div
                class="rating-upper"
                :style="`width:calc( ( ${product?.rating} / 5 ) * 100%)`"
              >
                <span><i class="fas fa-star"></i></span>
                <span><i class="fas fa-star"></i></span>
                <span><i class="fas fa-star"></i></span>
                <span><i class="fas fa-star"></i></span>
                <span><i class="fas fa-star"></i></span>
              </div>
              <div class="rating-lower">
                <span><i class="fas fa-star"></i></span>
                <span><i class="fas fa-star"></i></span>
                <span><i class="fas fa-star"></i></span>
                <span><i class="fas fa-star"></i></span>
                <span><i class="fas fa-star"></i></span>
              </div>
            </div>
            <div class="ms-2">
              {{ format(product?.ratings) }} {{ content.review_s }}
            </div>
          </div>
        </div>
        <div class="flex items-center px-5 mb-6 justify-between mt-6">
          <div class="price lora-bold">
            {{
              format(product?.details?.price) ??
              format(product?.sizes[0]?.price)
            }}
            {{ content.iqd }}
          </div>
          <!-- <div class="add-to-cart cursor-pointer">
          <i class="fas text-secondaryColor fa-cart-plus"></i>
        </div> -->
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
import { format } from "/src/assets/numberFormat";

export default {
  inject: ["content"],
  props: ["product"],
  methods: {
    format(number) {
      return format(number);
    },
  },
};
</script>