<template>
  <div class="collapse-container" :class="{ first: first }">
    <div
      class="flex items-center justify-between cursor-pointer"
      :class="{ 'mb-6': collapseOpened }"
      @click="collapseOpened = !collapseOpened"
    >
      <div class="lora-bold">
        {{ title }}
      </div>
      <i
        class="
          fas
          fa-chevron-right
          btn-arrow-icon
          text-base
          transition-all
          delay-75
          ms-8
        "
        :class="{ 'rotate-icon': collapseOpened }"
      ></i>
    </div>
    <div
      class="collapse-items"
      :class="{ 'show-collapse-items-filter': collapseOpened }"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: ["title", "first"],
  data() {
    return {
      collapseOpened: false,
    };
  },
};
</script>