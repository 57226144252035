<template>
  <div class="grid place-content-center layout-padding">
    <div class="signin-container flex flex-col items-center my-12">
      <div class="text-size-24 section-padding">
        {{ content.forgetPassword }}
      </div>
      <!--    <div class="mt-5 lora-bold section-padding">
        {{ content.dontHaveAnAccount }}
        <router-link to="/signup">
          <span class="text-secondaryColor lora-bold">{{
            content.register
          }}</span></router-link
        >
      </div> -->
      <div class="mt-8 w-full section-padding">
        <div class="grid grid-cols-4">
          <label
            for="email"
            class="col-span-full sm:col-span-1 flex items-center"
          >
            {{ content.email }}
          </label>
          <input
            type="email"
            id="email"
            class="form-input col-span-4 sm:col-span-3"
            v-model="email"
            placeholder="example@companyname.com"
          />
        </div>

        <div class="grid grid-cols-4 mt-4">
          <div class="col-span-1 flex items-center"></div>
          <div class="col-span-4 sm:col-span-3 flex justify-end items-center">
            <div class="text-size-12 w-max-content cursor-pointer">
              <button
                class="
                  flex
                  items-center
                  justify-between
                  w-max-content
                  btn-signIn
                "
                @click="forgotPassword"
              >
                <div class="lora-bold">
                  {{ content.sendPasswordResetEmail }}
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
      
      <SocialLogin />
      <!-- <hr
        class="hr-text w-full h-24 my-12"
        :data-content="content.orConnectUsing"
      />
      <div class="section-padding flex flex-col items-center">
        <div
          class="
            signin-with-google
            grid grid-cols-4
            place-content-center
            lora-bold
            mt-6
          "
        >
          <img src="/static/images/icons/google.png" class="me-6 col-span-1" />
          <div class="col-span-3">
            {{ content.signInWithGoogle }}
          </div>
        </div>
        <div
          class="
            signin-with-facebook
            grid grid-cols-4
            place-content-center
            lora-bold
            mt-4
          "
        >
          <img
            src="/static/images/icons/facebook.png"
            class="me-6 col-span-1"
          />
          <div class="col-span-3">
            {{ content.signInWithFacebook }}
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import SocialLogin from "./SocialLogin.vue";

export default {
  components: { SocialLogin },
  inject: ["content"],
  data() {
    return {
      email: null,
    };
  },
  methods: {
    async forgotPassword() {
      if (this.email == "") return;

      try {
        await this.$store.dispatch("auth/forgotPassword", this.email);
        this.$store.dispatch("feedback/setFeedback", {
          type: "success",
          title: this.content.success,
          message: this.content.checkEmail,
        });
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

