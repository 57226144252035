const Account = () => import('../views/Main/Account/Account.vue');
const Profile = () => import('../views/Main/Account/Profile.vue');
const PasswordSetting = () => import('../views/Main/Account/PasswordSetting.vue');
const OrderHistory = () => import('../views/Main/Orders/OrderHistory.vue');
const RefillOrderDetails = () => import('../views/Main/Orders/RefillOrderDetails.vue');
const TicketOrderDetails = () => import('../views/Main/Orders/TicketOrderDetails.vue');
const SubscriptionOrderDetails = () => import('../views/Main/Orders/SubscriptionOrderDetails.vue');
const PersonalizedOrderDetails = () => import('../views/Main/Orders/PersonalizedOrderDetails.vue');
const MyReviews = () => import('../views/Main/Account/MyReviews.vue');
const AccountSetting = () => import('../views/Main/Account/AccountSetting.vue');
const OrderDetails = () => import('../views/Main/Orders/OrderDetails.vue');



export default [
  {
    path: "/account",
    name: "account",
    component: Account,
    meta: { requiresAuth: true },
    children: [
      {
        path: "/account/accountsetting",
        name: "accountSetting",
        component: AccountSetting,
        meta: { requiresAuth: true },
      },
      {
        path: "/account/profile",
        name: "profile",
        component: Profile,
        meta: { requiresAuth: true },
      },
      {
        path: "/account/passwordsetting",
        name: "passwordSetting",
        component: PasswordSetting,
        meta: { requiresAuth: true },
      },
      {
        path: "/account/orderHistory",
        name: "orderHistory",
        component: OrderHistory,
        meta: { requiresAuth: true },
      },
      {
        path: "/account/myreviews",
        name: "myReviews",
        component: MyReviews,
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: "/orders/:id",
    name: "orderDetails",
    component: OrderDetails,
    props: true,
    // meta: { requiresAuth: true },
  },
  {
    path: "/refills/:id",
    name: "refillOrderDetails",
    component: RefillOrderDetails,
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: "/tickets/:id",
    name: "ticketOrderDetails",
    component: TicketOrderDetails,
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: "/subscriptions/:id",
    name: "subscriptionOrderDetails",
    component: SubscriptionOrderDetails,
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: "/personalized/:id",
    name: "personalizedOrderDetails",
    component: PersonalizedOrderDetails,
    props: true,
    meta: { requiresAuth: true },
  },
];
