import { http } from '../../../graphql'
import gql from "graphql-tag";

export default {
  policies: async context => {
    let query = gql`
      query privacyPolicy {
        policies: privacyPolicy {
          id
          title
          content
        }
      }
    `
    const response = await http(true, query, false)
    context.commit('setPolicies', response.policies)
  },
  terms: async context => {
    let query = gql`
      query terms {
        terms {
          id
          title
          content
        }
      }
    `
    const response = await http(true, query, false)
    context.commit('setTerms', response.terms)
  }
}