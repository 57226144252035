import { http } from '../../../graphql'
import gql from "graphql-tag";

export default {
  reviews: async (context) => {
    // let page = payload?.page ?? 1
    // let first = payload?.first ?? 6

    let query = gql`
      # query reviews($page: Int!, $first: Int!) {
      #   reviews (page: $page, first: $first){
      query reviews {
        reviews {
          # paginatorInfo {
          #   count
          #   currentPage
          #   firstItem
          #   hasMorePages
          #   lastItem
          #   lastPage
          #   perPage
          #   total
          # }
          # data {
            id
            rating
            review
            visible
            date
            customer {
              id
              name
              profile
            }
            product {
              id
              rating
              ratings
              collection {
                id
                title
              }
              details {
                id
                title
                image
              }
            }
          # }
        }
      }
    `
    // const response = await http(true, query, true, { page: page, first: first })
    const response = await http(true, query, true)
    context.commit('setReviews', response.reviews)
  },
  review: async (context, payload) => {
    let query = gql`
      query review($id: ID!) {
        review (id: $id){
          id
          rating
          review
          visible
          date
          customer {
            id
            name
            profile
          }
          product {
            id
            rating
            ratings
            collection {
              id
              title
            }
            details {
              id
              title
              image
            }
          }
        }
      }
    `
    const response = await http(true, query, true, { id: payload })
    context.commit('setReview', response.review)
  },
  add: async (context, payload) => {
    let mutation = gql`
      mutation addReview ($input: ReviewInput!){
        review (input: $input){
          id
        }
      }
    `
    await http(false, mutation, true, {
      input: {
        id: payload.id,
        rating: payload.rating,
        review: payload.review,
      }
    })
  },
  deleteReview: async (context, payload) => {
    let mutation = gql`
      mutation deleteReview ($id: ID!){
        reviews: deleteReview(id: $id){
          id
          rating
          review
          visible
          date
          customer {
            id
            name
            profile
          }
          product {
            id
            rating
            ratings
            collection {
              id
              title
            }
            details {
              id
              title
              image
            }
          }
        }
      }
    `
    const response = await http(false, mutation, true, { id: payload })
    context.commit('setReviews', response.reviews)
  }
}