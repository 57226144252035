import { http } from '../../../graphql'
import gql from "graphql-tag";

export default {
  products: async context => {
    let query = gql`
      query products {
        products{
          ${productsSchema}
        }
      }
    `
    const response = await http(true, query, false)
    context.commit('setProducts', response.products)
  },
  candles: async context => {
    let query = gql`
      query candles {
        candles{
          ${productsSchema}
        }
      }
    `
    const response = await http(true, query, false)
    context.commit('setCandles', response.candles)
  },
  accessories: async context => {
    let query = gql`
      query accessories {
        accessories{
          ${productsSchema}
        }
      }
    `
    const response = await http(true, query, false)
    context.commit('setAccessories', response.accessories)
  },
  giftCards: async context => {
    let query = gql`
      query giftCards {
        giftCards{
          ${productsSchema}
        }
      }
    `
    const response = await http(true, query, false)
    context.commit('setGiftCards', response.giftCards)
  },
  giftSets: async context => {
    let query = gql`
      query giftSets {
        giftSets{
          ${productsSchema}
        }
      }
    `
    const response = await http(true, query, false)
    context.commit('setGiftSets', response.giftSets)
  },
  product: async (context, payload) => {
    let query = gql`
      query product($id: ID!) {
        product (id: $id){
          id
          stock
          rating
          ratings
          new
          review
          collection {
            id
            title
          }
          subCategory {
            id
            title
            category {
              id
              title
            }
          }
          details {
            id
            title
            description
            image
            price
          }
          colors {
            id
            color {
              id
              title
              color
            }
          }
          scents {
            id
            scent {
              id
              title
              image
            }
          }
          sizes {
            id
            size {
              id
              title
              value
            }
            price
          }
          images {
            id
            url
          }
          reviews {
            id
            customer {
              name
              profile
            }
            rating
            review
            date
          }
        }
      }
    `
    const auth = context.rootGetters['auth/auth']
    const response = await http(true, query, auth, { id: payload })
    context.commit('setProduct', response.product)
  },
  filter: async (context, payload) => {
    let page = payload.page ? parseInt(payload.page) : 1
    let items = 12;
    let query = gql`
      query filter($input: FilterInput!, $page: Int!, $items: Int!) {
        products: filter(input: $input, items: $items, page: $page){
          pagination {
            current
            prev
            next
            pages
          }
          products {
            id
            stock
            rating
            ratings
            new
            details {
              id
              title
              image
              price
            }
            sizes {
              id
              price
            }
          }
        }
      }
    `
    // let query = gql`
    //   query filter($input: FilterInput!) {
    //     products: filter(input: $input){
    //       id
    //       stock
    //       rating
    //       ratings
    //       new
    //       details {
    //         id
    //         title
    //         image
    //         price
    //       }
    //       sizes {
    //         id
    //         price
    //       }
    //     }
    //   }
    // `

    let input = {}
    if (payload.category) input.category = payload.category
    if (payload.subCategory) input.sub_category = payload.subCategory
    if (payload.collection) input.collection = payload.collection
    if (payload.color) input.color = payload.color
    if (payload.size) input.size = payload.size

    const response = await http(true, query, false, { input: input, page: page, items: items })
    // const response = await http(true, query, false, { input: input })
    switch (payload.category) {
      case 1:
        context.commit('setCandles', response.products)
        break;
      case 2:
        context.commit('setAccessories', response.products)
        break;
      case 3:
        context.commit('setGiftCards', response.products)
        break;
      case 4:
        context.commit('setGiftSets', response.products)
        break;
      default:
        context.commit('setProducts', response.products)
        break;
    }
  },
  similar: async (context, payload) => {
    let query = gql`
      query similarProducts ($id: ID!){
        products: similarProducts (id: $id) {
          ${productsSchema}
        }
      }
    `
    const response = await http(true, query, false, { id: payload })
    context.commit('setSimilar', response.products)
  },
  search: async (context, payload) => {
    let query = gql`
      query search ($s: String!){
        products: search (s: $s) {
          ${productsSchema}
        }
      }
    `
    const response = await http(true, query, false, { s: payload })
    context.commit('setSearchProducts', response.products)
  },
  mostSelling: async context => {
    let query = gql`
      query mostSelling {
        products: mostSelling {
          ${productsSchema}
        }
      }
    `
    const response = await http(true, query, false)
    context.commit('setMostSelling', response.products)
  },
  topRated: async context => {
    let query = gql`
      query topRated {
        products: topRated {
          ${productsSchema}
        }
      }
    `
    const response = await http(true, query, false)
    context.commit('setTopRated', response.products)
  }
}

const productsSchema = `
  id
  stock
  rating
  ratings
  new
  details {
    id
    title
    image
    price
  }
  sizes {
    id
    price
  }
  `