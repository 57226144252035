<template>
  <div class="modal-background" :class="{ 'modal-opened': modalOpened }">
    <div
      class="modal-underlay"
      @click="closeModal"
      :class="{ 'modal-opened': modalOpened }"
    ></div>
    <div class="modal-slot">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: ["modalOpened"],
  methods: {
    closeModal() {
      this.$emit("close");
    },
  },
  watch: {
    modalOpened() {
      if (this.modalOpened == true) {
        document.body.classList.add("overflow-hidden");
      }
      if (this.modalOpened == false) {
        document.body.classList.remove("overflow-hidden");
      }
    },
  },
};
</script>