import { createStore } from 'vuex'

import actions from './actions'

import Auth from './auth'

import Feedback from './feedback'

// customer -
import User from './user'
// cart -
import Cart from './shop/cart'

// cities -
import City from './content/cities'

// categories -
import Category from './shop/categories'
// subcategories
// collections -
import Collection from './shop/collections'
// products -?
import Product from './shop/products'
// orders -
import Orders from './shop/orders'
// refill -
import Refill from './shop/refills'
// white label -
import Label from './shop/label'
// personalized -
import Personalized from './shop/personalized'
// subscription -
import Subscriptions from './shop/subscription'
// newsletter
import Newsletter from './newsletter'

// reviews -
import Reviews from './shop/reviews'

// wicks -
// colors -
// scents -
// sizes -
// containers -
import Material from './shop/material'

// pages
// home sliders -
import Pages from './content/pages'
// selling points -
// online shops -
import Shops from './content/shops'
// workshops
import Workshop from './content/workshops'
// tickets
// press  -
import Press from './content/press'
// project -
import Project from './content/projects'
// events -
import Event from './content/events'

// terms -
// privacy policy -
import Extra from './content/extra'
// social media -
// contact -
import Contact from './content/contact'

import PayLater from './shop/paylater'

export default createStore({
  state: {
    // server: 'https://api.lala-candles.com/graphql',
    intended: null,
  },
  getters: {
    intended: state => state.intended,
  },
  mutations: {
    setIntended: (state, payload) => { state.intended = payload },
  },
  actions,
  modules: {
    auth: Auth,
    feedback: Feedback,
    user: User,
    cart: Cart,
    cities: City,
    categories: Category,
    collections: Collection,
    products: Product,
    orders: Orders,
    refill: Refill,
    label: Label,
    personalized: Personalized,
    subscription: Subscriptions,
    newsletter: Newsletter,
    materials: Material,
    reviews: Reviews,
    pages: Pages,
    shops: Shops,
    workshops: Workshop,
    press: Press,
    projects: Project,
    events: Event,
    extra: Extra,
    contact: Contact,
    paylater: PayLater,
  }
})
