<template>
  <div
    class="
      flex
      items-center
      flex-wrap
      layout-padding
      lora-bold
      justify-between
      my-14
    "
  >
    <div class="section-title smd:mb-0 mb-3 me-8">
      {{ content.mostSellingItems }}
    </div>
    <ViewAllButton :text="content.browseAllItems" to="/shop" />
  </div>
  <div class="layout-padding relative pb-24">
    <swiper
      :slides-per-view="4"
      :space-between="20"
      :breakpoints="breakpoints"
      :pagination="pagination"
      :loop="true"
    >
      <!-- <swiper-slide>
        <ProductCardSkeleton />
      </swiper-slide> -->
      <swiper-slide v-for="product in products" :key="product.id">
        <ProductCard :product="product" />
      </swiper-slide>
    </swiper>
    <div class="most-selling-pagination"></div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore, { Navigation, Pagination } from "swiper";
import "swiper/swiper-bundle.css";
import "swiper/components/navigation/navigation.min.css";
SwiperCore.use([Navigation, Pagination]);
import ProductCard from "/src/components/ui/Products/ProductCard.vue";
// import ProductCardSkeleton from "/src/components/ui/Skeletons/ProductCardSkeleton.vue";

import ViewAllButton from "/src/components/ui/Buttons/ViewAllButton.vue";
export default {
  components: {
    ProductCard,
    ViewAllButton,
    Swiper,
    SwiperSlide,
    // ProductCardSkeleton,
  },
  inject: ["content"],
  data() {
    return {
      breakpoints: {
        // when window width is >= 320px
        0: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        // when window width is >= 480px
        567: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        // when window width is >= 640px
        992: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
        1200: {
          slidesPerView: 4,
          spaceBetween: 20,
        },
        1500: {
          slidesPerView: 4,
          spaceBetween: 20,
        },
        1800: {
          slidesPerView: 4,
          spaceBetween: 20,
        },
      },
      pagination: {
        el: ".most-selling-pagination",
        type: "bullets",
        clickable: true,
        renderBullet: function () {
          return `<span class="dot swiper-pagination-bullet" style="background: #F26C57 !important; margin-inline-start:10px;width:10px;height:2px;margin-top:30px" ></span>`;
        },
      },
    };
  },
  computed: {
    products() {
      return this.$store.getters["products/mostSelling"];
    },
  },
};
</script>


<style  >
.most-selling-pagination {
  position: absolute;
  bottom: 50px;
  left: 0vw;
  width: 100%;
  display: flex;
  justify-content: center;
}
.most-selling-pagination .swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
  padding: 20px 4vw;
  display: flex;
}
.most-selling-pagination .swiper-pagination-bullet {
  border-radius: 0;
  height: 3px;
  width: 45px !important;
  background: #302b87 !important;
  opacity: 0.5 !important;
}
.most-selling-pagination .swiper-pagination-bullet-active {
  background: #302b87;
  opacity: 1 !important;
}
@media (max-width: 567px) {
  .most-selling-pagination .swiper-pagination-bullet {
    width: 30px !important;
  }
}
</style>