<template>
  <div class="grid place-content-center layout-padding">
    <div class="signin-container flex flex-col items-center my-16">
      <div class="text-size-24 section-padding">
        {{ content.signIn }}
      </div>
      <div class="mt-5 lora-bold section-padding">
        {{ content.dontHaveAnAccount }}
        <router-link to="/signup">
          <span class="text-secondaryColor lora-bold">
            {{ content.register }}
          </span>
        </router-link>
      </div>
      <form class="mt-8 w-full section-padding" @submit.prevent="login">
        <div class="grid grid-cols-4">
          <label for="email" class="col-span-1 flex items-center">
            {{ content.email }}
          </label>
          <input
            type="email"
            id="email"
            class="form-input col-span-4 sm:col-span-3"
            v-model="email"
            placeholder="example@companyname.com"
          />
        </div>
        <div class="grid grid-cols-4 mt-4">
          <label for="password" class="col-span-1 flex items-center">
            {{ content.password }}
          </label>
          <div class="relative w-full col-span-4 sm:col-span-3">
            <div class="show-password" @click="showPassword = !showPassword">
              <i class="far fa-eye-slash" v-if="showPassword"></i>
              <i class="far fa-eye" v-else></i>
            </div>
            <input
              :type="showPassword ? 'text' : 'password'"
              id="password"
              class="form-input w-full"
              v-model="password"
              placeholder="********"
            />
          </div>
        </div>
        <div class="grid grid-cols-4">
          <div class="col-span-3 mt-4 text-rejected text-xs col-start-2" v-if="error">
            {{ error }}
          </div>
        </div>
        <div class="grid grid-cols-4 mt-4">
          <div class="col-span-1 flex items-center"></div>
          <div class="col-span-4 sm:col-span-3 flex justify-between items-center">
            <router-link to="/forgetpassword">
              <div class="text-size-12 w-max-content cursor-pointer">
                {{ content.forgetPassword }}
              </div>
            </router-link>
            <div class="text-size-12 w-max-content cursor-pointer">
              <button class="flex items-center justify-between w-max-content btn-signIn">
                <div class="lora-bold">
                  {{ content.signIn }}
                </div>
              </button>
            </div>
          </div>
        </div>
      </form>

      <SocialLogin />
    </div>
  </div>
</template>

<script>
import SocialLogin from "./SocialLogin.vue";

export default {
  components: { SocialLogin },
  inject: ["content"],
  data() {
    return {
      email: "",
      password: "",
      error: null,
      showPassword: false,
    };
  },
  computed: {
    intended() {
      return this.$store.getters.intended;
    },
  },
  methods: {
    async login() {
      this.error = null;
      try {
        await this.$store.dispatch("auth/login", {
          email: this.email,
          password: this.password,
        });
        await this.$store.dispatch("cart/saveLocalToDB");
        await this.$store.dispatch("refill/saveLocalToDB");
        // setTimeout( async () => {
        //   await this.$store.dispatch("user/user");
        // }, 500);
        let route = this.intended ?? "/";
        this.$store.dispatch("setIntended", null);

        // let user = await this.$store.getters["user/user"];
        // if (user) {
        //   let gender = user.gender ?? "None";
        //   this.$gtag.customMap({ dimension2: "age" });
        //   this.$gtag.event("age_dimension", { age: user.age });
        //   this.$gtag.customMap({ dimension2: "gender" });
        //   this.$gtag.event("gender_dimension", { gender: gender });
        // }

        // await this.$store.dispatch("user/user");
        this.$router.replace(route);
      } catch (error) {
        console.log(error);
        this.error = error[0].message;
      }
    },
  },
};
</script>
