<template>
  <teleport to="body">
    <div
      v-if="feedback"
      id="feedback-backdrop"
      class="fixed min-w-full min-h-full top-0 left-0 z-40"
      @click="close"
    ></div>
    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <div class="fixed mb-4 md:mb-8 layout-padding feedback-card" v-if="feedback">
        <Alert
          customClasses="max-w-550 alert-shadow"
          :type="feedback.type"
          :title="feedback.title"
          :message="feedback.message"
        >
          <router-link
            v-if="feedback.route && feedback.routeText"
            :to="feedback.route"
            class="alert-btn text-text-white text-white lora-bold bg-secondaryColor duration-300 mt-6"
          >
            {{ feedback.routeText }}
          </router-link>
        </Alert>
      </div>
    </transition>
  </teleport>
</template>

<script>
export default {
  computed: {
    feedback() {
      return (
        this.$store.getters["feedback/feedback"] &&
        this.$store.getters["user/popup"] == false
      );
    },
  },
  // data() {
  //   return {
  //     feedback: {
  //       type: "success",
  //       title: "feedback.title",
  //       message: "feedback.message feedback.message feedback. message messagemessage message message",
  //       route: '/',
  //       routeText: 'go back to home message message message message'
  //     },
  //   };
  // },
  mounted() {
    setTimeout(() => {
      if (!this.feedback) this.close();
    }, 6000);
  },
  methods: {
    close() {
      this.$store.dispatch("feedback/setFeedback", null);
    },
  },
};
</script>
