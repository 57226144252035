<template>
  <div class="layout-padding min-h-96">
    <PageTitle
      class="my-8"
      :title="content.cart"
      :childOne="content.shop"
      :childTwo="content.cart"
    />
    <div
      class="my-5 grid grid-cols-12 gap-x-0 md:gap-x-10"
      v-if="cart?.length > 0 || refillCart?.length > 0"
    >
      <div class="col-span-12 lg:col-span-8 mb-12">
        <div class="page-title mb-6">{{ content.items }}</div>
        <OrderItemCard
          :cart="cart"
          @update-quantity="updateQuantity"
          @removeItem="removeItem"
        />
        <RefillItemCard
          :cart="refillCart"
          @update-quantity="updateRefillQuantity"
          @removeItem="removeRefillItem"
        />
      </div>
      <div class="col-span-12 lg:col-span-4">
        <OrderSummery
          :cart="cart"
          :refill-cart="refillCart"
          @update-quantity="updateQuantity"
        />
      </div>
    </div>
    <div class="my-5 gap-x-0 md:gap-x-10" v-else>
      <p class="text-2xl text-center mt-10">{{ content.cartIsEmpty }}</p>
    </div>
  </div>
</template>

<script>
import OrderItemCard from "/src/components/ui/Cart/OrderItemCard.vue";
import RefillItemCard from "/src/components/ui/Cart/RefillItemCard.vue";
import OrderSummery from "/src/components/ui/Cart/OrderSummery.vue";

export default {
  props: ["id"],
  inject: ["content"],
  components: { OrderItemCard, RefillItemCard, OrderSummery },
  data() {
    return {
      localCart: null,
      localRefillCart: null,
    };
  },
  computed: {
    cart() {
      return this.auth ? this.$store.getters["cart/cart"] : this.localCart;
    },
    refillCart() {
      return this.auth ? this.$store.getters["refill/cart"] : this.localRefillCart;
    },
    auth() {
      return this.$store.getters["auth/auth"];
    },
  },
  mounted() {
    if (!this.auth) {
      this.getLocalCart();
    }
  },
  methods: {
    getLocalCart() {
      this.localCart = this.$store.getters["cart/cartLocal"];
      this.localRefillCart = this.$store.getters["refill/cartLocal"];
    },
    async updateQuantity(index, quantity) {
      // this.cart[index].quantity = quantity;
      let cart = this.cart[index];
      try {
        this.auth
          ? await this.$store.dispatch("cart/edit", {
              id: cart.id,
              quantity: quantity,
            })
          : (await this.$store.dispatch("cart/editLocal", {
              index: index,
              quantity: quantity,
            }),
            this.getLocalCart());
      } catch (error) {
        console.log(error);
      }
    },
    async removeItem(index) {
      // console.log(index);
      let cart = this.cart[index];
      try {
        this.auth
          ? await this.$store.dispatch("cart/remove", cart.id)
          : (await this.$store.dispatch("cart/removeLocal", index), this.getLocalCart());
      } catch (error) {
        console.log(error);
      }
    },
    async updateRefillQuantity(index, quantity) {
      // this.cart[index].quantity = quantity;
      let cart = this.refillCart[index];
      try {
        this.auth
          ? await this.$store.dispatch("refill/edit", {
              id: cart.id,
              quantity: quantity,
            })
          : (await this.$store.dispatch("refill/editLocal", {
              index: index,
              quantity: quantity,
            }),
            this.getLocalCart());
      } catch (error) {
        console.log(error);
      }
    },
    async removeRefillItem(index) {
      // console.log(index);
      let cart = this.refillCart[index];
      try {
        this.auth
          ? await this.$store.dispatch("refill/remove", cart.id)
          : (await this.$store.dispatch("refill/removeLocal", index),
            this.getLocalCart());
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
