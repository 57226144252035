<template>
  <div class="grid place-content-center layout-padding min-h-96">
    <Alert
      customClasses="max-w-550"
      :type="status == 'success' ? 'success' : 'fail'"
      :title="title"
      :message="message"
    >
      <router-link
        class="
          alert-btn
          text-text-white text-white
          lora-bold
          whitespace-nowrap
          bg-secondaryColor
          duration-300
          mt-6
        "
        to="/"
      >
        {{ content.goHome }}
      </router-link>
    </Alert>
  </div>
</template>

<script>
export default {
  inject: ["content"],
  props: ["status"],
  computed: {
    title() {
      return this.status == "success"
        ? this.content.success
        : this.content.fail;
    },
    message() {
      return this.status == "success"
        ? this.content.accountVerified
        : this.status == "verified"
        ? this.content.accountAlreadyVerified
        : this.content.accountVerificationFailed;
    },
  },
};
</script>