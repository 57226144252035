// const pages = {
//   home: ['categories/categories', 'pages/sliders'],
// }

export default {
  setIntended: (context, payload) => {
    context.commit('setIntended', payload)
  },
  //   loadPage: async (context, payload) => {

  //   },
  //   loadOthers: async (context, payload) => {

  //   }
}