<template>
  <div class="account-dropdown-menu bg-blueLight" id="account-menu">
    <!-- <router-link @click="$emit('closeMenu')" to="/account/accountsetting">
      <div class="py-2 menu-item hover:text-secondaryColor">
        {{ content.yourAccount }}
      </div>
    </router-link> -->
    <div class="menu-item text-menuItemColor">
      {{ content.yourAccount }}
    </div>
    <router-link @click="$emit('closeMenu')" to="/account/profile">
      <div class="py-2 menu-item hover:text-secondaryColor">
        {{ content.myProfile }}
      </div>
    </router-link>
    <router-link @click="$emit('closeMenu')" to="/account/passwordsetting">
      <div class="py-2 menu-item hover:text-secondaryColor">
        {{ content.passwordSetting }}
      </div>
    </router-link>
    <router-link @click="$emit('closeMenu')" to="/account/orderhistory">
      <div class="py-2 menu-item hover:text-secondaryColor">
        {{ content.orderHistory }}
      </div>
    </router-link>
    <router-link @click="$emit('closeMenu')" to="/account/myreviews">
      <div class="py-2 menu-item hover:text-secondaryColor">
        {{ content.reviews }}
      </div>
    </router-link>
    <div class="py-2 signout-btn" @click="logout">
      {{ content.signout }}
    </div>
  </div>
</template>

<script>
export default {
  emits: ["closeMenu"],
  inject: ["content"],
  computed: {
    language() {
      return localStorage.getItem("lang");
    },
  },
  methods: {
    async logout() {
      try {
        this.$emit("closeMenu");
        await this.$store.dispatch("auth/logout");
        this.$router.replace("/");
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>