<template>
  <div class="grid place-content-center w-full my-20 min-h-96">
    <h2 class="mb-10 text-6xl md:text-9xl text-center text-secondaryColor drop-shadow-md">404</h2>
    <div class="text-secondaryColor text-2xl md:text-4xl text-center">{{ content.pageNotFound }}</div>
      <router-link
        to="/"
        class="alert-btn mx-auto w-max text-text-white text-white lora-bold  bg-secondaryColor duration-300 mt-6"
      >
        {{ content.goHome }}
      </router-link>
  </div>
</template>

<script>
export default {
  inject: ["content"],
};
</script>