<template>
  <transition name="bg">
    <div
      v-show="bg"
      class="z-40 fixed top-0 left-0 w-full min-h-screen bg-blueLight"
    ></div>
  </transition>
  <div
    v-if="bg"
    class="
      z-50
      fixed
      w-full
      min-h-screen
      bg-transparent
      text-bluePrimary
      grid
      place-content-center
    "
  >
    <!-- <transition name="text">
      <h1 v-if="text" class="text-3xl w-52">
        {{ content.loading }}
        <span v-for="index in dots" :key="index">.</span>
      </h1>
    </transition> -->
   <div class="h-60 w-60 relative">
      <img src="/static/images/loading-img-o.png" alt="" class="absolute h-32 top-12 left-0 w-full object-contain loading-img-o" />
        <img src="/static/images/loading-img-p.png" alt="" class="absolute h-52 w-full object-contain loading-img-p" />
   </div>

  </div>
  <TheHeader />
  <router-view></router-view>
  <TheFooter />
  <Feedback />
  <PhoneInput v-if="!bg" />
</template>

<script>
import Languages from "/src/assets/Languages.js";
import TheHeader from "/src/components/Layout/TheHeader";
import TheFooter from "/src/components/Layout/TheFooter";
import Feedback from "./components/ui/Feedback.vue";
import PhoneInput from "./components/ui/PhoneInput.vue";

export default {
  components: {
    TheHeader,
    TheFooter,
    Feedback,
    PhoneInput,
  },
  provide: {
    content: Languages[localStorage.getItem("lang") ?? "en"],
  },
  inject: ["Vue3GoogleOauth"],
  data() {
    return {
      bg: true,
      text: true,
      dots: 0,
    };
  },
  computed: {
    content() {
      return Languages[localStorage.getItem("lang") ?? "en"];
    },
  },
  created() {
    let lang = localStorage.getItem("lang");
    if (!lang) {
      localStorage.setItem("lang", "en");
    }
    if (localStorage.getItem("lang") == "en") {
      document.body.removeAttribute("dir");
      document.body.classList.remove("rtl");
    } else {
      document.body.setAttribute("dir", "rtl");
      document.body.classList.add("rtl");
    }

    this.timer();
  },
  async mounted() {
    this.$store.dispatch("contact/contact");
    this.$store.dispatch("contact/socialMedia");
    this.$store.dispatch("pages/texts");
    this.$store.dispatch("cart/loadLocal");
    this.$store.dispatch("refill/loadLocal");
    await this.$store.dispatch("collections/collections");
    await this.$store.dispatch("pages/sliders");
    await this.$store.dispatch("products/mostSelling");
    await this.$store.dispatch("products/topRated");

    if (!this.Vue3GoogleOauth.isInit) {
      try {
        // const authCode =
        await this.$gAuth.getAuthCode();
        // console.log("authCode", authCode);
      } catch (error) {
        //on fail do something
        console.error(error);
        // return null;
      }
    }
    // if (this.Vue3GoogleOauth.isAuthorized) {
    //   console.log("auth");
    // } else {
    //   console.log("no auth");
    // }

    try {
      await this.$store.dispatch("user/user");
    } catch (error) {
      // console.log(error);
    }

    this.text = false;
    setTimeout(() => {
      this.bg = false;
    }, 300);

    this.$store.dispatch("shops/studio");
    this.$store.dispatch("shops/sellingPoints");
    this.$store.dispatch("categories/categories");
    this.$store.dispatch("materials/containers");
    this.$store.dispatch("materials/refillContainers");
    this.$store.dispatch("materials/colors");
    this.$store.dispatch("materials/scents");
    this.$store.dispatch("materials/sizes");
    this.$store.dispatch("materials/wicks");
    this.$store.dispatch("materials/packages");
    this.$store.dispatch("cities/cities");

    /**
     * auth app.vue
     * cart app.vue
     * cities app.vue wait
     * categories app.vue
     * collections ap.vue
     * products
     * topRated
     * mostSelling
     *
     * REQUESTS
     * orders
     * refill
     * label
     * personalized
     * subscription
     * newsletter
     *
     * materials app.vue wait
     * reviews
     * pages app.vue
     * sliders app.vue
     * workshops
     * shops
     * press
     * projects
     * events
     * extra
     * contact app.vue
     *
     *
     */

    this.$gtag.event("load", { method: "mounted" });

    // setTimeout(() => {
    //   this.$store.dispatch("feedback/setFeedback", {
    //     type: "success",
    //     title: this.content.success,
    //     message: this.content.orderSuccess,
    //   });
    // }, 5000);
  },
  methods: {
    timer() {
      if (this.bg && this.text)
        setTimeout(() => {
          if (this.dots == 3) this.dots = 0;
          else this.dots++;
          this.timer();
        }, 400);
    },
  },
};
</script>

<style>
.text-leave-active {
  transition: opacity 300ms ease;
}

.text-leave-to {
  opacity: 0;
}

.bg-leave-active {
  transition: all 300ms ease-in;
}

.bg-leave-to {
  width: 0px;
}
@keyframes fadeAnimation {
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
@-o-keyframes fadeAnimation{
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
@-moz-keyframes fadeAnimation{
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
@-webkit-keyframes fadeAnimation{
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
@keyframes fadeAnimation2 {
  0%   { opacity:0; }
  50%  { opacity:1; }
  100% { opacity:0; }
}
@-o-keyframes fadeAnimation2{
  0%   { opacity:0; }
  50%  { opacity:1; }
  100% { opacity:0; }
}
@-moz-keyframes fadeAnimation2{
  0%   { opacity:0; }
  50%  { opacity:1; }
  100% { opacity:0; }
}
@-webkit-keyframes fadeAnimation2{
  0%   { opacity:0; }
  50%  { opacity:1; }
  100% { opacity:0; }
}
.loading-img-o {
   -webkit-animation: fadeAnimation 3s infinite;
   -moz-animation: fadeAnimation 3s infinite;
   -o-animation: fadeAnimation 3s infinite;
    animation: fadeAnimation 3s infinite;
}
.loading-img-p {
   -webkit-animation: fadeAnimation2 3s infinite;
   -moz-animation: fadeAnimation2 3s infinite;
   -o-animation: fadeAnimation2 3s infinite;
    animation: fadeAnimation2 3s infinite;
}
</style>

