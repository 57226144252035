<template>
  <div :style="`background:url(${img})`" class="slide-background">
    <div class="slide-content" :style="textColor ? `color: ${textColor};` : ''">
      <div class="title">{{ title }}</div>
      <div class="description mt-10">{{ description }}</div>
      <div class="flex flex-wrap">
        <FillButton
          :text="firstBtnText"
          v-if="linkOne"
          :to="linkOne"
          class="lg:mt-10 mt-5 me-4"
        />
        <OutlineButton
          :text="secondBtnText"
          v-if="linkTwo"
          :to="linkTwo"
          :text-color="textColor"
          class="lg:mt-10 mt-5"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    "img",
    "title",
    "description",
    "linkOne",
    "linkTwo",
    "firstBtnText",
    "secondBtnText",
    "textColor",
  ],
  inject: ["content"],
};
</script>

<style scoped>
.slide-background {
  width: 100%;
  min-height: 550px;
  background-size: cover !important;
  background-position: center !important;
  border-radius: 10px;
  padding: 40px 4vw;
  display: flex;
  align-items: center;
}
.slide-content {
  width: 35vw;
  color: #fff;
}
.slide-background .title {
  font-size: 36px;
}
@media (max-width: 1300px) {
  .slide-content {
    width: 45vw;
  }
}
@media (max-width: 992px) {
  .slide-content {
    width: 55vw;
  }
}
@media (max-width: 768px) {
  .slide-content {
    width: 75vw;
  }
  .slide-background .title {
    font-size: 24px;
  }
  .slide-background {
    width: 100%;
    min-height: 350px;
    background-size: cover !important;
    background-position: center !important;
    border-radius: 10px;
    padding: 40px 4vw;
    display: flex;
    align-items: center;
  }
}
@media (max-width: 568px) {
  .slide-content {
    width: 100%;
  }
  .slide-background .title {
    font-size: 22px;
  }
}
</style>
