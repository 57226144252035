import { http } from '../../../graphql'
import gql from "graphql-tag";
import router from "../../../router"

export default {
  workshops: async context => {
    let query = gql`
      query workshops {
        workshops {
          id
          title
          date
          tickets {
            id
            min
            max
            price
            soldout
          }
        }
      }
    `
    const response = await http(true, query, false)
    context.commit('setWorkshops', response.workshops)
  },
  workshop: async (context, payload) => {
    let query = gql`
      query workshop($id: ID!) {
        workshop(id: $id){
          id
          title
          date
          tickets {
            id
            min
            max
            price
            soldout
          }
        }
      }
    `
    await http(true, query, false, { id: payload })
  },
  buyTicket: async (context, payload) => {
    let mutation = gql`
      mutation ticket ($input: TicketInput!){
        order: ticket (input: $input) {
          id
          token
        }
      }
    `
    const response = await http(false, mutation, true, {
      input: {
        ticket: payload.ticket,
        method: payload.method ?? 'cash'
      }
    })
    // context.commit('setWorkshop', response.workshop)


    if (payload.method === 'zaincash') {
      window.location.replace(`https://api.zaincash.iq/transaction/pay?id=${response.order.token}`)
    } else if (payload.method == 'fastpay') {
      window.location.replace(response.order.token)
    } else if (payload.method === 'nasspay') {
      let json = JSON.parse(response.order.token)
      let transactionId = json.transactionId
      let token = json.token
      // let user = '7735313620'
      let user = '7517924925'

      window.location.replace(`https://checkout.nasswallet.com/payment-gateway?id=${transactionId}&token=${token}&userIdentifier=${user}`, '_blank')
      // window.open(`https://uatcheckout1.nasswallet.com/payment-gateway?id=${transactionId}&token=${token}&userIdentifier=${user}`, '_blank')
    }
  },
  myTickets: async context => {
    let query = gql`
      query tickets {
        tickets{
          id
          paid
          method
          date
          status
          ticket {
            id
            min
            max
            price
            soldout
            workshop {
              id
              title
              date
            }
          }
        }
      }
    `
    const response = await http(true, query, true)
    context.commit('setTickets', response.tickets)
  },
  buyTicketGuest: async (context, payload) => {
    let mutation = gql`
      mutation TicketGuest ($input: GuestTicketInput!){
        order: ticketGuest (input: $input) {
          id
          uuid
          token
        }
      }
    `

    let input = {
      ticket_id: payload.ticketId,
      method: payload.method ?? 'cash',
      name: payload.name,
      email: payload.email,
      phone: payload.phone,
      address: payload.address,
      token:payload.token
    }

    if (payload.token) input.token = payload.token

    const response = await http(false, mutation, true, { input })

    if (payload.method === 'zaincash') {
      window.location.replace(`https://api.zaincash.iq/transaction/pay?id=${response.order.token}`)
    } else if (payload.method == 'fastpay') {
      window.location.replace(response.order.token)
    } else if (payload.method === 'nasspay') {
      let json = JSON.parse(response.order.token)
      let transactionId = json.transactionId
      let token = json.token
      let user = '7517924925'

      window.location.replace(`https://checkout.nasswallet.com/payment-gateway?id=${transactionId}&token=${token}&userIdentifier=${user}`, '_blank')
      // window.open(`https://uatcheckout1.nasswallet.com/payment-gateway?id=${transactionId}&token=${token}&userIdentifier=${user}`, '_blank')
    }
    else if (payload.method === 'cash') {
      console.log(response);
      router.push({ query: { id: response.order.uuid, token: response.order.token, email: response.order.email } })
    }
  },
  getGuestTicket: async (context, payload) => {
    let query = gql`
      query guestTicket($uuid: String!, $email: String!) {
        guestTicket(uuid: $uuid, email: $email) {
          id
          paid
          method
          date
          status
          name
          email
          phone
          address
          ticket {
            id
            min
            max
            price
            soldout
            workshop {
              id
              title
              date
            }
          }
        }
      }
    `
    const response = await http(true, query, true, {
      uuid: payload.uuid,
      email: payload.email
    })
      context.commit('setGuestTicket', response.guestTicket)

  }
  // myTicket: async (context, payload) => {},
}