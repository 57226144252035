<template>
  <div
    class="alert alert-success"
    :class="customClasses"
    v-if="type == 'success'"
  >
    <div class="grid grid-cols-6">
      <div class="col-span-1 flex justify-center">
        <div class="alert-icon-circle success-icon-bg">
          <i class="fas fa-check text-successColor"></i>
        </div>
      </div>
      <div class="col-span-5 ms-2">
        <div class="text-borderColor text-size-20">{{ title }}</div>
        <div class="text-borderColor" v-if="messageArray">
          <span class="block" v-for="(item, index) in message" :key="index">
            {{ item }}
          </span>
        </div>
        <div class="text-borderColor" v-else>
          {{ message }}
        </div>
      </div>
    </div>
    <div class="flex justify-end">
      <slot></slot>
    </div>
  </div>
  <div
    class="alert alert-fail"
    :class="customClasses"
    v-else-if="type == 'fail'"
  >
    <div class="grid grid-cols-6">
      <div class="col-span-1 flex justify-center">
        <div class="alert-icon-circle fail-icon-bg">
          <i class="fas fa-times text-failColor"></i>
        </div>
      </div>
      <div class="col-span-5 ms-2">
        <div class="text-secondaryColor text-size-20">{{ title }}</div>
        <div class="text-secondaryColor">{{ message }}</div>
      </div>
    </div>
    <div class="flex justify-end"><slot></slot></div>
  </div>
  <div
    class="alert alert-success"
    :class="customClasses"
    v-else-if="type == 'info'"
  >
    <div class="grid grid-cols-6">
      <div class="col-span-1 flex justify-center">
        <div class="alert-icon-circle success-icon-bg">
          <i class="fas fa-info text-successColor"></i>
        </div>
      </div>
      <div class="col-span-5 ms-2">
        <div class="text-borderColor">{{ message }}</div>
      </div>
    </div>
    <div class="flex justify-end"><slot></slot></div>
  </div>
</template>

<script>
export default {
  props: ["type", "title", "message", "customClasses"],
  computed: {
    messageArray() {
      return this.message instanceof Array;
    },
  },
};
</script>