<template>
  <div class="wholesale-card-container mt-6">
    <div class="wholesale-card" :style="`background: url(${img})`">
      <div
        class="relative"
        :class="type == 'one' ? 'wholesale-overlay-1' : 'wholesale-overlay-2'"
      >
        <div class="card-content">
          <div class="title text-white lora-bold card-title">
            {{ title }}
          </div>
          <div class="title text-white card-desc mt-4">
            {{ description }}
          </div>
        </div>
        <router-link :to="to">
          <div class="wholesale-btn">
            <i class="fas fa-arrow-right text-bluePrimary"></i></div
        ></router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["img", "title", "description", "to", "type"],
};
</script>