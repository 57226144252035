<template>
  <div class="collection-card">
    <div
      class="collection-card-img"
      :style="`background: url(${collection.image})`"
    > </div>
      <div
        class="
          collection-card-overlay
          flex flex-col
          justify-center
          items-center
          text-white
          px-4
        "
      >
        <div class="title lora-bold">{{collection.title}}</div>
        <div class="description mt-3 w-56 text-center mb-5">
          {{collection.story}}
        </div>
        <OutlineButton :text="content.viewCollection" :to="{ name: 'collectionsDetails', params: { id: collection.id }}" />
      </div>
    
  </div>
</template>

<script>
export default {
  inject: ["content"],
  props: ['collection'],
};
</script>