import actions from './actions'
import getters from './getters'
import mutations from './mutations'

export default {
  namespaced: true,
  state: () => ({
    studio: null,
    sellingPoints: [],
    sellingPoint: null,
    onlineShops: [],
  }),
  actions,
  getters,
  mutations,
}