<template>
  <div class="layout-padding min-h-96">
    <PageTitle
      class="my-8"
      :title="content.contactUs"
      :childOne="content.contactUs"
      routeOne="/contact"
      type="withBg"
    />
    <div class="grid grid-cols-3 gap-x-20">
      <div class="col-span-3 mb-6 lg:col-span-1 border-contact">
        <div class="contact-title lora-bold">{{ content.reachUsAt }}</div>
        <div class="mt-8">
          <p class="mt-2">{{ content.email }} : {{ email?.value }}</p>
          <p class="mt-2">{{ content.phone }} :{{ phone?.value }}</p>
          <p class="mt-2">{{ content.address }} : {{ address?.value }}</p>
        </div>
      </div>
      <div class="col-span-3 mb-6 lg:col-span-1 border-contact">
        <div class="contact-title lora-bold">
          {{ content.followUsOnSocialMedia }}
        </div>
        <div class="mt-8">
          <p v-if="facebook">
            {{ content.facebook }}:
            <button @click="newWindow(facebook.url)">
              {{ facebook.url.split(".com/").pop().split("/")[0] }}
            </button>
          </p>
          <p v-if="instagram">
            {{ content.instagram }}:
            <button @click="newWindow(instagram.url)">
              {{ instagram.url.split(".com/").pop().split("/")[0] }}
            </button>
          </p>
          <p v-if="twitter">
            {{ content.twitter }}:
            <button @click="newWindow(twitter.url)">
              {{ twitter.url.split(".com/").pop().split("/")[0] }}
            </button>
          </p>
          <p v-if="pinterest">
            {{ content.pinterest }}:
            <button @click="newWindow(pinterest.url)">
              {{ pinterest.url.split(".com/").pop().split("/")[0] }}
            </button>
          </p>
          <p v-if="tiktok">
            {{ content.tiktok }}:
            <button @click="newWindow(tiktok.url)">
              {{ tiktok.url.split(".com/").pop().split("/")[0] }}
            </button>
          </p>
        </div>
      </div>
      <div class="col-span-3 mb-6 lg:col-span-1">
        <div class="contact-title lora-bold">
          {{ content.more }}
        </div>
        <router-link to="/sellingpoints/onlinesellingpoints">
          <div
            class="contact-more-btn flex justify-between items-center mb-2 mt-8"
          >
            {{ content.onlineSellingPoints }}
            <i
              class="
                fas
                fa-chevron-right
                btn-arrow-icon
                text-base
                transition-all
                delay-75
                ms-8
              "
            ></i>
          </div>
        </router-link>
        <router-link to="/sellingpoints/sellingsites">
          <div class="contact-more-btn flex justify-between items-center my-2">
            {{ content.sellingSites }}
            <i
              class="
                fas
                fa-chevron-right
                btn-arrow-icon
                text-base
                transition-all
                delay-75
                ms-8
              "
            ></i>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inject: ["content"],
  computed: {
    socialMedia() {
      return this.$store.getters["contact/socialMedia"];
    },
    facebook() {
      return this.socialMedia.filter((item) => item.title == "facebook")[0];
    },
    instagram() {
      return this.socialMedia.filter((item) => item.title == "instagram")[0];
    },
    twitter() {
      return this.socialMedia.filter((item) => item.title == "twitter")[0];
    },
    pinterest() {
      return this.socialMedia.filter((item) => item.title == "pinterest")[0];
    },
    tiktok() {
      return this.socialMedia.filter((item) => item.title == "tiktok")[0];
    },
    contact() {
      return this.$store.getters["contact/contact"];
    },
    email() {
      return this.contact?.filter((item) => item.type == "email")[0];
    },
    phone() {
      return this.contact?.filter((item) => item.type == "phone")[0];
    },
    address() {
      return this.contact?.filter((item) => item.type == "address")[0];
    },
  },
  methods: {
    newWindow(url) {
      window.open(url, "Name", "popup");
    },
  }
};
</script>

