import { http } from '../../../graphql'
import gql from "graphql-tag";

export default {
  collections: async context => {
    let query = gql`
      query collections {
        collections {
          id
          title
          image
          story
        }
      }
    `
    const response = await http(true, query, false)
    context.commit('setCollections', response.collections)
  },
  collection: async (context, payload) => {
    // let id = parseInt(payload)
    let query = gql`
      query collection($id: ID!) {
        collection(id: $id) {
          id
          title
          image
          story
          products {
            id
            stock
            new
            rating
            ratings
            sizes {
              id
              size {
                id
                title
                value
              }
              price
            }
            details {
              id
              title
              description
              image
              price
            }
          }
        }
      }
    `
    const response = await http(true, query, false, { id: payload })
    context.commit('setCollection', response.collection)
  },
}