import actions from './actions'
import getters from './getters'
import mutations from './mutations'

export default {
  namespaced: true,
  state: () => ({
    workshops: [],
    workshop: null,
    tickets: [],
    guestTicket: null
  }),
  actions,
  getters,
  mutations,
}