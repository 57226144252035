<template>
  <div
    class="grid grid-cols-2 gap-x-0 lg:gap-x-10 lg:row-start-2 details-skeleton"
  >
    <div class="col-span-2 lg:col-span-1 ">
      <div class="flex">
        <div class="breadcrumb me-3 mt-3"></div>
        <div class="breadcrumb me-3 mt-3"></div>
        <div class="breadcrumb me-3 mt-3"></div>
      </div>
      <div class="page-title lora-bold my-5"></div>
      <div class="flex flex-wrap items-center mt-2">
        <div class="flex items-center pe-8 me-8 border-end w-max-content">
          <div class="rating"></div>
        </div>
        <div class="Availability"></div>
      </div>
      <div class="mt-5 collection"></div>
      <div class="mt-5 description"></div>
      <div class="mt-2 description"></div>

      <div class="mt-2 description"></div>
      <div class="mt-2 description"></div>

      <div class="flex flex-wrap items-center mt-5">
        <div class="flex items-center pe-8 me-8 border-end w-max-content">
          <div class="size"></div>
        </div>
        <div class="size"></div>
      </div>
      <div class="flex flex-wrap items-center mt-6">
        <div class="flex items-center mb-4">
          <div class="relative size"></div>
        </div>
      </div>
      <div class="mt-12">
        <button class="button me-4"></button>
        <button class="button"></button>
      </div>
    </div>
    <div class="col-span-2 lg:col-span-1 mt-16 lg:my-0 ">
      <div class="grid grid-cols-10 gap-x-2">
        <div class="md:col-span-12 col-span-12">
          <div class="about-slide-image-skeleton" />
        </div>
      </div>
    </div>
  </div>
</template>