<template>
  <div class="layout-padding mt-8 relative" v-if="slides">
    <swiper
      :slides-per-view="1"
      :space-between="50"
      :effect="'fade'"
      :navigation="navigation"
      :pagination="pagination"
      :loop="true"
      :autoplay='autoplay'
    >
      <swiper-slide v-for="slide in slides" :key="slide.id">
        <Slide
          :img="slide.image"
          :title="slide.title"
          :description="slide.description"
          :firstBtnText="slide.buttonOne"
          :secondBtnText="slide.buttonTwo"
          :link-one="slide.linkOne"
          :link-two="slide.linkTwo"
          :text-color="slide.color"
        />
      </swiper-slide>
      <!-- <swiper-slide
        ><Slide
          :img="'/static/images/slider.png'"
          title="Explore Our Floral Candle Collection!"
          description="The collection comes with both wax melts and container in different shapes, colors and scents. "
          :firstBtnText="content.shopNow"
          :secondBtnText="content.viewCollection"
          :to="'/'"
      /></swiper-slide>
      <swiper-slide
        ><Slide
          :img="'/static/images/slide-3.png'"
          title="Explore Our Floral Candle Collection!"
          description="The collection comes with both wax melts and container in different shapes, colors and scents. "
          :to="'/'"
          :firstBtnText="content.shopNow"
          :secondBtnText="content.viewCollection"
      /></swiper-slide> -->
    </swiper>
    <div class="swiper-pagination home-slide-pagination"></div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore, { Navigation, Pagination, EffectFade,Autoplay } from "swiper";
import "swiper/swiper-bundle.css";
import "swiper/components/navigation/navigation.min.css";
import Slide from "/src/components/ui/Home/Slide.vue";
SwiperCore.use([Navigation, Pagination, EffectFade,Autoplay]);

export default {
  components: {
    Swiper,
    SwiperSlide,
    Slide,
  },
  inject: ["content"],
  data() {
    return {
      navigation: {
        nextEl: "#nextMember",
        prevEl: "#prevMember",
      },
      autoplay: {
        delay: 3000,
      },
      pagination: {
        el: ".swiper-pagination",
        type: "bullets",
        clickable: true,
        renderBullet: function () {
          return `<span class="dot swiper-pagination-bullet" style="background: #302B87 !important; margin-inline-start:10px;width:10px;height:2px;margin-top:30px" ></span>`;
        },
      },
    };
  },
  computed: {
    slides() {
      return this.$store.getters["pages/sliders"];
    },
  },
};
</script>

<style  >
.home-slide-pagination {
  position: absolute;
  bottom: 20px;
  left: 8vw;
}
.home-slide-pagination .swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
  padding: 20px 4vw;
  display: flex;
}
.home-slide-pagination .swiper-pagination-bullet {
  border-radius: 0;
  height: 3px;
  width: 45px !important;
  background: #302b87 !important;
  opacity: 0.5 !important;
}
.home-slide-pagination .swiper-pagination-bullet-active {
  background: #302b87;
  opacity: 1 !important;
}
</style>