import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  from,
} from "@apollo/client/core";
import { onError } from "@apollo/client/link/error";

import store from './store'

const errorLink = onError(({ response }) => {
  // if (operation.operationName === "IgnoreErrorsQuery") {
  // console.log('errors');
  // console.log(response);
  if (response?.errors) {
    // console.log(response.errors);
    response.e = response.errors;
    response.errors = null;
  }
  
  if (response?.e && response?.e.length >= 1 && response?.e[0]?.debugMessage == 'Unauthorized.phone') {
    store.dispatch("user/setShowedPopup", false);
    store.dispatch("user/setPopup", true);
  }
  // }
});
// const errorLink = onError(({ graphQLErrors, networkError }) => {
//   if (graphQLErrors)
//     graphQLErrors.forEach(({ message, locations, path }) =>
//       console.log(
//         `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
//       )
//     );
//   if (networkError) console.log(`[Network error]: ${networkError}`);
// });

// const url = 'http://localhost:8000/graphql'
const url = 'https://api.lala-candles.com/graphql'
// const url = 'https://test.lala-candles.com/graphql'
// const url = "http://192.168.1.212:8000/graphql";

let lang = localStorage.getItem("lang");

if (
  !lang ||
  lang == "" ||
  lang == "undefined" ||
  lang == undefined ||
  lang == "null"
)
  lang = "en";

let uri = `${url}?lang=${lang}`

const authGql = new ApolloClient({
  link: from([
    errorLink,
    new HttpLink({
      uri: uri,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
    }),
  ]),
  cache: new InMemoryCache(),
});

const noAuthGql = new ApolloClient({
  link: from([
    errorLink,
    new HttpLink({
      uri: uri,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }),
  ]),
  cache: new InMemoryCache(),
});

const http = async (query = true, content, auth = false, variables = null) => {
  let result = new Promise((resolve, reject) => {
    let gqlQuery = {
      query: content,
    };
    let mutation = {
      mutation: content,
    };
    if (variables) gqlQuery.variables = variables;
    if (variables) mutation.variables = variables;
    if (auth) {
      if (query) {
        authGql.query(gqlQuery).then((response) => {
          if (response.e) reject(response.e)
          resolve(response.data);
        });
      } else {
        authGql.mutate(mutation).then((response) => {
          if (response.e) reject(response.e)
          resolve(response.data);
        });
      }
    } else {
      if (query) {
        noAuthGql.query(gqlQuery).then((response) => {
          if (response.e) reject(response.e)
          resolve(response.data);
        });
      } else {
        noAuthGql.mutate(mutation).then((response) => {
          if (response.e) reject(response.e)
          resolve(response.data);
        });
      }
    }
  });
  return result;
};

export { authGql, noAuthGql, http };
