<template>
  <div class="bg-white py-4 mt-3 flex items-center justify-between">
    <div class="flex-1 flex justify-between sm:hidden">
      <router-link
        v-if="prevUrl"
        :to="prevUrl"
        class="relative inline-flex items-center px-4 py-2 border border-menuItemColor text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
      >
        {{ content.previous }}
      </router-link>
      <p
        class="cursor-pointer relative inline-flex items-center px-4 py-2 border border-menuItemColor text-sm font-medium rounded-md text-gray-500 bg-white hover:bg-gray-50"
      >
        {{ content.previous }}
      </p>
      <router-link
        v-if="nextUrl"
        :to="nextUrl"
        class="ml-3 relative inline-flex items-center px-4 py-2 border border-menuItemColor text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
      >
        {{ content.next }}
      </router-link>
      <p
        v-else
        class="cursor-pointer ml-3 relative inline-flex items-center px-4 py-2 border border-menuItemColor text-sm font-medium rounded-md text-gray-500 bg-white hover:bg-gray-50"
      >
        {{ content.next }}
      </p>
    </div>
    <div class="hidden sm:flex-1 sm:flex sm:items-center sm:justify-center">
      <nav class="relative z-0 inline-flex -space-x-px" aria-label="Pagination">
        <router-link
          v-if="prevUrl"
          :to="prevUrl"
          class="relative inline-flex items-center px-2 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50"
        >
          <span>{{ content.previous }}</span>
        </router-link>
        <p
          v-else
          class="cursor-pointer relative inline-flex items-center px-2 py-2 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
        >
          <span>{{ content.previous }}</span>
        </p>
        <router-link
          :to="data.current != 1 ? { query: { page: '1' } } : '#'"
          aria-current="page"
          class="pagination-link"
          :class="{ active: data.current === 1 }"
        >
          1
        </router-link>
        <a v-if="data.pages > 5 && 1 < data.current - 2" href="#" class="pagination-link">
          ...
        </a>
        <router-link
          v-for="index in links"
          :key="index"
          :to="{ query: { page: `${index}` } }"
          class="pagination-link"
          :class="{ active: data.current == index }"
        >
          {{ index }}
        </router-link>
        <a
          v-if="data.pages > 5 && data.pages > data.current + 2"
          href="#"
          class="pagination-link"
        >
          ...
        </a>
        <router-link
          :to="data.current != data.pages ? { query: { page: `${data.pages}` } } : '#'"
          class="pagination-link"
          :class="{ active: data.current == data.pages }"
        >
          {{ data.pages }}
        </router-link>
        <router-link
          v-if="nextUrl"
          :to="nextUrl"
          class="relative inline-flex items-center px-2 py-2 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
        >
          <span>{{ content.next }}</span>
        </router-link>
        <p
          v-else
          class="cursor-pointer relative inline-flex items-center px-2 py-2 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
        >
          <span>{{ content.next }}</span>
        </p>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  props: ["data"],
  inject: ["content"],
  // mounted() {
  //   console.log('pagination', this.data);
  // },
  watch: {
    $route: {
      handler() {
        window.scrollTo({ top: 0, behavior: "smooth" });
      },
      deep: true,
    },
  },
  computed: {
    links() {
      let from = 2;

      let pages = [...Array(this.data.pages).keys()];

      if (this.data.current > 2) {
        from = this.data.current - 1;
      }

      let last = from + 3;
      if (this.data.pages < this.data.current + 3) {
        last = this.data.pages;
        from = last - 3;
        if (from <= 1) from = 2;
      }

      let links = pages.slice(from, last);

      return links;
    },
    firstUrl() {
      return { query: { page: 1 } };
    },
    lastUrl() {
      return { query: { page: this.data.pages } };
    },
    prevUrl() {
      return this.data.prev ? { query: { page: this.data.prev } } : null;
    },
    nextUrl() {
      return this.data.next ? { query: { page: this.data.next } } : null;
    },
  },
};
</script>
