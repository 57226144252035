<template>
  <div class="order-summery-card">
    <div>
      <div class="text-center text-bluePrimary pt-6 lora-bold">
        {{ content.orderSummery }}
      </div>

      <div class="grid grid-cols-3 px-4 xl:px-10 mt-12">
        <div class="col-span-1 lora-bold">{{ content.items }}</div>
        <div class="col-span-1 lora-bold text-center">{{ content.qty }}</div>
        <div class="col-span-1 lora-bold text-align-end">
          {{ content.price }}
        </div>
      </div>
      <div v-if="product" class="grid grid-cols-3 px-4 xl:px-10 mt-4">
        <div class="col-span-1">
          {{ product?.product?.details?.title }} {{ product?.size?.title }}
        </div>
        <div class="col-span-1 text-center">{{ product?.quantity }}</div>
        <div class="col-span-1 text-align-end">
          {{ product?.size?.price ?? product?.product?.details?.price }}
          {{ content.iqd }}
        </div>
      </div>
      <div v-else>
        <div
          class="grid grid-cols-3 px-4 xl:px-10 mt-4"
          v-for="item in cart"
          :key="item.id"
        >
          <div class="col-span-1">
            {{ item.product.details.title }} {{ item?.size?.size?.title ?? "" }}
          </div>
          <div class="col-span-1 text-center">{{ item.quantity }}</div>
          <div class="col-span-1 text-align-end">
            {{
              item.size?.price
                ? format(item.size?.price)
                : format(item.product.details.price)
            }}
            {{ content.iqd }}
          </div>
        </div>
        <div
          class="grid grid-cols-3 px-4 xl:px-10 mt-4"
          v-for="item in refillCart"
          :key="item.id"
        >
          <div class="col-span-1">
            {{ content.refillRequest }}
            <br />
            <p class="text-sm mt-1 opacity-70">
              {{ item.container.title }} | {{ item.size.title }} | {{ item.wick.title }} |
              {{ item.color.title }} | {{ item.scent.title }}
            </p>
          </div>
          <div class="col-span-1 text-center">{{ item.quantity }}</div>
          <div class="col-span-1 text-align-end">
            {{ format(item.total) }} {{ content.iqd }}
          </div>
        </div>
      </div>
      <hr style="color: #37328b22" class="my-5" />
      <div class="flex justify-between px-4 xl:px-10 mt-4">
        <div class="ms-16 w-24 text-align-end">{{ content.subtotal }}:</div>
        <div class="col-span-2 text-align-end">
          {{ format(subTotal) }} {{ content.iqd }}
        </div>
      </div>

      <div class="flex justify-between px-4 xl:px-10 mt-2">
        <div class="ms-16 w-24 text-align-end whitespace-nowrap">
          {{ content.deliveryCost }}:
        </div>
        <div class="col-span-2 text-align-end">
          {{ delivery && city ? format(city.price) : format(0) }} {{ content.iqd }}
        </div>
      </div>
      <div class="flex justify-between px-4 xl:px-10 mt-4 text-size-20">
        <div class="ms-16 ps-1 whitespace-nowrap">{{ content.total }}:</div>
        <div class="col-span-2 text-align-end whitespace-nowrap">
          {{ format(totalPrice) }} {{ content.iqd }}
        </div>
      </div>
      <div class="mx-3 my-4 py-4">
        <button
          v-if="buttonDisabled"
          class="w-full header-btn bg-cancelled bg-opacity-10 text-cancelled border-cancelled border-1 lora-bold whitespace-nowrap duration-300 cursor-default"
        >
          {{ content.submitOrder }}
        </button>
        <button
          v-else
          class="alert-btn text-text-white text-white lora-bold whitespace-nowrap bg-bluePrimary duration-300 w-full hover:text-bluePrimary hover:bg-blueSecondary"
          @click="submitOrder"
        >
          {{ content.submitOrder }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { format } from "../../../assets/numberFormat";

export default {
  inject: ["content"],
  props: ["product", "delivery", "cityId", "option", "buttonDisabled"],
  emits: ["submitOrder"],
  computed: {
    auth() {
      return this.$store.getters["auth/auth"];
    },
    cart() {
      return this.auth? this.$store.getters["cart/cart"] :JSON.parse(localStorage.getItem('cart'))?.items??[];
    },
    refillCart() {
      return this.auth? this.$store.getters["refill/cart"] :JSON.parse(localStorage.getItem('refill-cart'))?.items??[];
     },
    city() {
      return this.cityId ? this.$store.getters["cities/city"](this.cityId) : null;
    },
    subTotal() {
      let total = 0;
      if (this.product) {
        if (this.product?.size) {
          total = parseFloat(this.product?.size?.price);
        } else {
          total = parseFloat(this.product?.product?.details?.price);
        }

        if (this.$route.query.quantity) {
          total *= parseInt(this.$route.query.quantity);
        }

        return total;
      } else {
        for (let i = 0; i < this.cart.length; i++) {
          if (this.cart[i]?.size) {
            total += parseFloat(this.cart[i].size.price) * this.cart[i].quantity;
          } else {
            total +=
              parseFloat(this.cart[i].product.details?.price) * this.cart[i].quantity;
          }
        }
        if (this.refillCart) {
          for (let i = 0; i < this.refillCart.length; i++) {
            total += parseFloat(this.refillCart[i].total);
          }
        }
      }
      return total;
    },
    totalPrice() {
      let total = this.subTotal;
      if (this.delivery && this.city) total += parseFloat(this.city.price);
      return total;
    },
  },
  methods: {
    submitOrder() {
      this.$emit("submitOrder");
    },
    format(number) {
      return format(number);
    },
  },
};
</script>
