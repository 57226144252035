<template>
  <div class="grid grid-cols-2 layout-padding gap-x-5">
    <div class="col-span-2 lg:col-span-1" v-if="label">
      <WholesaleCard
        :img="label.image"
        :title="label.title"
        :description="label.subtitle"
        :to="'/whitelabel/request'"
        type="one"
      />
    </div>
    <div class="col-span-2 lg:col-span-1" v-if="personalized">
      <WholesaleCard
        :img="personalized.image"
        :title="personalized.title"
        :description="personalized.subtitle"
        :to="'/personalized-candles'"
        type="two"
      />
    </div>
  </div>
</template>
<script>
import WholesaleCard from "/src/components/ui/Home/Wholesale Section/WholesaleCard.vue";
export default {
  components: {
    WholesaleCard,
  },
  computed: {
    personalized() {
      return this.$store.getters["pages/text"]("personalized");
    },
    label() {
      return this.$store.getters["pages/text"]("label");
    },
  },
};
</script>