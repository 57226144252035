<template>
  <router-link :to="to">
    <div class="flex items-center justify-between w-max-content btn-fill">
      <div class="lora-bold" :class="color">
        {{ text }}
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  props: ["text", "to", "color"],
};
</script>

<style scoped>
.btn-all {
  padding: 10px 0px;
  width: max-content;
  font-size: 12px;
}
.all-btn-text {
  font-size: 12px;
}
.btn-arrow-icon {
  margin-inline-start: 10px;
  transition: 0.3s all ease-out;
}
.fas {
  font-size: 12px;
}
.btn-all:hover .btn-arrow-icon {
  margin-inline-start: 20px;
}
</style>