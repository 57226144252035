<template>
  <router-link :to="to">
    <div class="flex items-center justify-between w-max-content btn-all">
      <div class="text-bluePrimary lora-bold all-btn-text">
        {{ text }}
      </div>
      <i
        class="fas fa-chevron-right btn-arrow-icon text-base text-bluePrimary"
      ></i></div
  ></router-link>
</template>

<script>
export default {
  props: ["text", "to"],
};
</script>

<style scoped>
.btn-all {
  padding: 10px 0px;
  width: max-content;
  font-size: 14px;
}
.all-btn-text {
  font-size: 14px;
}
.btn-arrow-icon {
  margin-inline-start: 10px;
  transition: 0.3s all ease-out;
}
.fas {
  font-size: 14px;
}
.rtl .fas {
  font-size: 14px;
  transform: rotate(180deg);
}
.btn-all:hover .btn-arrow-icon {
  margin-inline-start: 20px;
}
</style>