// const e2p = s => s.replace(/\d/g, d => '۰۱۲۳۴۵۶۷۸۹'[d])
const e2a = s => s.replace(/\d/g, d => '٠١٢٣٤٥٦٧٨٩'[d])

const toLocale = (str) => {
  let lang = localStorage.getItem('lang')
  if (lang == 'ku' || lang == 'ar')
    return e2a(str)
  return str
}

const format = number => {
  if(number == null) return null
  number = number.toString()
  let lang = localStorage.getItem('lang')
  if (lang == 'ku' || lang == 'ar')
    return new Intl.NumberFormat('ar').format(number).toString().replace("٬", "٫")
  return new Intl.NumberFormat('en').format(number)
}

module.exports = { toLocale, format }