import { http } from '../../../graphql'
import gql from "graphql-tag";

export default {
  contact: async context => {
    let query = gql`
      query contact {
        contact {
          id
          type
          value
        }
      }
    `
    const response = await http(true, query, false)
    context.commit('setContact', response.contact)
  },
  socialMedia: async context => {
    let query = gql`
      query socialMedia {
        socialMedia {
          id
          title
          url
        }
      }
    `
    const response = await http(true, query, false)
    context.commit('setSocialMedia', response.socialMedia)
  }
}