import Home from "/src/views/Main/Home.vue";
import Contact from "../views/Main/About/Contact.vue";
import Shop from "/src/views/Main/Shop/Shop.vue";
import Search from "/src/views/Main/Shop/Search.vue";
import ProductDetails from "/src/views/Main/Product/ProductDetails.vue";
import Cart from "/src/views/Main/Shop/Cart.vue";
import Checkout from "/src/views/Main/Shop/Checkout.vue";
import NotFound from "/src/views/Main/NotFound.vue";

const WhoWeAre = () => import("../views/Main/About/WhoWeAre.vue");
const OurStory = () => import("../views/Main/About/OurStory.vue");
const Founder = () => import("../views/Main/About/Founder.vue");
const OurStudio = () => import("../views/Main/SalePoints/OurStudio.vue");
const SellingPointsMain = () => import("../views/Main/SalePoints/SellingPointsMain.vue");
const OnlineSellingPoints = () => import("../views/Main/SalePoints/OnlineSellingPoints.vue");
const SellingSites = () => import("../views/Main/SalePoints/SellingSites.vue");
const ShippingPrices = () => import("../views/Main/ShippingPrices.vue");
const PrivacyPolicy = () => import("../views/Main/PrivacyPolicy.vue");
const Terms = () => import("../views/Main/Terms.vue");

const Press = () => import(/* webpackChunkName: "press" */ "../views/Main/Press/Press.vue");
const PressDetails = () => import(/* webpackChunkName: "press" */"../views/Main/Press/PressDetails.vue");
const Events = () => import("../views/Main/Events.vue");
const PersonalizedCandles = () => import("../views/Main/Personalized.vue");
const GiftSubscription = () => import("../views/Main/GiftSubscription.vue");
const SubscriptionCheckout = () => import("../views/Main/Subscription/Checkout.vue");
const Workshops = () => import("../views/Main/Workshops.vue");
const Projects = () => import("../views/Main/WhiteLabelCandles/Projects.vue");
const Request = () => import("../views/Main/WhiteLabelCandles/Request.vue");
const Refill = () => import("../views/Main/Shop/Refill.vue");
const ProjectDetails = () => import("../views/Main/WhiteLabelCandles/ProjectDetails.vue");
const Collections = () => import("../views/Main/Collections/Collections.vue");
const CollectionDetails = () => import("../views/Main/Collections/CollectionDetails.vue");

const PaymentSuccess = () => import("../views/Main/Payment/Success.vue");
const PaymentFail = () => import("../views/Main/Payment/Fail.vue");

export default [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/whoweare",
    name: "WhoWeAre",
    component: WhoWeAre,
  },
  {
    path: "/contact",
    name: "contact",
    component: Contact,
  },
  {
    path: "/ourstory",
    name: "ourStory",
    component: OurStory,
  },
  {
    path: "/founder",
    name: "founder",
    component: Founder,
  },
  {
    path: "/sellingpoints",
    name: "sellingPointsMain",
    component: SellingPointsMain,
    redirect: "/sellingpoints",
    children: [
      {
        path: "/sellingpoints/ourstudio",
        name: "ourStudio",
        component: OurStudio,
      },
      {
        path: "/sellingpoints/onlinesellingpoints",
        name: "onlinesellingpoints",
        component: OnlineSellingPoints,
      },
      {
        path: "/sellingpoints/sellingsites",
        name: "sellingSites",
        component: SellingSites,
      },
    ],
  },

  {
    path: "/events",
    name: "events",
    component: Events,
  },
  {
    path: "/press",
    name: "press",
    component: Press,
  },
  {
    path: "/press/:id",
    props: true,
    name: "pressDetails",
    component: PressDetails,
  },
  {
    path: "/shippingprices",
    name: "shippingPrices",
    component: ShippingPrices,
  },
  {
    path: "/terms",
    name: "terms",
    component: Terms,
  },
  {
    path: "/privacypolicy",
    name: "privacyPolicy",
    component: PrivacyPolicy,
  },
  {
    path: "/workshops",
    name: "workshops",
    component: Workshops,
  },
  {
    path: "/whitelabel/request",
    name: "whiteLabelRequest",
    component: Request,
  },
  {
    path: "/whitelabel/projects",
    name: "whiteLabelProjects",
    component: Projects,
  },
  {
    path: "/projects/:id",
    props: true,
    name: "projectDetails",
    component: ProjectDetails,
  },
  {
    path: "/personalized-candles",
    name: "PersonalizedCandles",
    component: PersonalizedCandles,
  },
  {
    path: "/giftsubscription",
    name: "giftSubscription",
    component: GiftSubscription,
  },
  {
    path: "/giftsubscription/checkout",
    name: "subscriptionCheckout",
    component: SubscriptionCheckout,
  },
  {
    path: "/shop",
    name: "shop",
    component: Shop,
  },
  {
    path: "/collections",
    name: "collections",
    component: Collections,
  },
  {
    path: "/collections/:id",
    props: true,
    name: "collectionsDetails",
    component: CollectionDetails,
  },
  {
    path: "/cart",
    name: "cart",
    component: Cart,
    // meta: { requiresAuth: true },
  },
  {
    path: "/refill",
    name: "refill",
    component: Refill,
    // meta: { requiresAuth: true },
  },
  {
    path: "/search",
    name: "search",
    component: Search,
  },
  {
    path: "/products/:id",
    props: true,
    name: "productDetails",
    component: ProductDetails,
  },
  {
    path: "/checkout",
    name: "checkout",
    component: Checkout,
    // meta: { requiresAuth: true },
  },
  {
    path: '/payment/success',
    name: 'paymentSuccess',
    component: PaymentSuccess,
  },
  {
    path: '/payment/fail',
    name: 'paymentFail',
    component: PaymentFail,
  },
  { path: '/:notFound(.*)', component: NotFound },
];
