<template>
  <div
    class="flex justify-between items-center"
    :class="type == 'withBg' ? 'page-title-with-bg' : 'page-title-no-bg'"
  >
    <div class="page-title lora-bold pe-8" v-html="title"></div>
    <div class="breadcrumbs">
      <span class="cursor-pointer" @click="$router.push('/')">
        {{ content.home }}
      </span>
      <span
        class="cursor-pointer"
        v-if="childOne"
        @click="$router.push(routeOne)"
      >
        / {{ childOne }}</span
      >
      <span
        class="cursor-pointer"
        v-if="childTwo"
        @click="$router.push(routeTwo)"
      >
        / {{ childTwo }}</span
      >
    </div>
  </div>
</template>

<script>
export default {
  props: ["title", "type", "childOne", "childTwo", "routeOne", "routeTwo"],
  inject: ["content"],
};
</script>