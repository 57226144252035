import actions from './actions'
import getters from './getters'
import mutations from './mutations'

export default {
  namespaced: true,
  state: () => ({
    products: [],
    candles: [],
    accessories: [],
    giftCards: [],
    giftSets: [],
    topRated: [],
    mostSelling: [],
    product: null,
    similar: [],
    searchProducts: [],
  }),
  actions,
  getters,
  mutations,
}