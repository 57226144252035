import { http } from '../../../graphql'
import gql from "graphql-tag";

export default {
  allNews: async (context, payload) => {
    let page = payload?.page ?? 1
    let first = payload?.first ?? 6

    let query = gql`
      query allNews($page: Int!, $first: Int!) {
        news: allNews (page: $page, first: $first){
          paginatorInfo {
            count
            currentPage
            firstItem
            hasMorePages
            lastItem
            lastPage
            perPage
            total
          }
          data {
            id
            title
            description
            date
            image
            images {
              id
              url
            }
          }
        }
      }
    `
    const response = await http(true, query, false, { page: page, first: first })
    context.commit('setAllNews', response.news)
  },
  news: async (context, payload) => {
    let query = gql`
      query news($id: ID!) {
        news (id: $id){
          id
          title
          description
          date
          image
          images {
            id
            url
          }
        }
      }
    `
    const response = await http(true, query, false, { id: payload })
    context.commit('setNews', response.news)
  }
}