import { http } from '../../../graphql'
import gql from "graphql-tag";

export default {
  studio: async context => {
    let query = gql`
      query studio {
        studio{
          id
          title
          description
          image
          latitude
          longitude
          drop
          city {
            id
            name
          }
          images {
            id
            url
          }
        }
      }
    `
    const response = await http(true, query, false)
    context.commit('setStudio', response.studio)
  },
  sellingPoints: async context => {
    let query = gql`
      query sellingPoints {
        sellingPoints {
          id
          title
          description
          image
          latitude
          longitude
          drop
          city {
            id
            name
          }
          images {
            id
            url
          }
        }
      }
    `
    const response = await http(true, query, false)
    context.commit('setSellingPoints', response.sellingPoints)
  },
  sellingPoint: async (context, payload) => {
    let query = gql`
      query sellingPoint($id: ID!) {
        sellingPoint (id: $id) {
          id
          title
          description
          image
          latitude
          longitude
          city {
            id
            name
          }
          images {
            id
            url
          }
        }
      }
    `
    const response = await http(true, query, false, { id: payload })
    context.commit('setSellingPoint', response.sellingPoint)
  },
  onlineShops: async context => {
    let query = gql`
      query onlineShops {
        onlineShops {
          id
          title
          description
          image
          link
        }
      }
    `
    const response = await http(true, query, false)
    context.commit('setOnlineShops', response.onlineShops)
  },
}