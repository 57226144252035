import { createRouter, createWebHistory } from "vue-router";
import commonRoutes from "./commonRoutes.js";
import userRoutes from "./userRoutes";
import authRoutes from "./authRoutes";
const routes = [...commonRoutes, ...authRoutes, ...userRoutes];

import store from '../store/index'

const router = createRouter({
  routes: routes,
  scrollBehavior(from, to, savedPosition) {
    // @M: we need to configure this based on routes

    if (
      from.fullPath.startsWith("/freelancers") ||
      from.fullPath.startsWith("/account") ||
      from.fullPath.startsWith("/order") ||
      from.name == to.name
    ) {
      return savedPosition;
    }

    return { top: 0, left: 0 };
  },
  history: createWebHistory(),
});

router.beforeEach(async (to, from, next) => {
  let auth = store.getters['auth/auth']
  let checked = store.getters['auth/checked']
  // console.log(auth);
  // console.log(from);
  // console.log(to);
  // next()


  if (auth) {
    if (to.meta.requiresAuth || !to.meta.requiresUnauth) {

      next()
      // }else if(to.meta.requiresUnauth){
      //   next(from.path)
    }
  } else {
    // console.log('no auth');
    if (to.meta.requiresUnauth || !to.meta.requiresAuth) {
      next()
    } else if (!checked) {
      try {
        await store.dispatch('user/user', true, { root: true })
        await store.dispatch('auth/checkLogin', null, { root: true })
        next()
      } catch (error) {
        store.dispatch('setIntended', to.fullPath)
        next('/signin')
      }
    } else {
      store.dispatch('setIntended', to.fullPath)
      next('/signin')
    }
    // console.log('no auth finish');
  }

})

export default router;
