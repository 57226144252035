<template>
  <div class="section-title layout-padding mt-14 lora-bold">
    {{ content.shopByCategory }}
  </div>
  <div class="grid grid-cols-4 gap-5 mt-10 layout-padding">
    <div class="col-span-4 md:col-span-2 lg:col-span-1"
      v-for="(category, index) in categories"
      :key="index"
    >
      <router-link 
      :to="categoryUrl(category.id)">
        <CategoryCard
          :title="category.title"
          :img="category.image"
      /></router-link>
    </div>
    <!-- <div class="col-span-4 md:col-span-2 lg:col-span-1">
      <router-link to="/shop?section=candles">
        <CategoryCard
          :title="content.candles"
          :img="
            lang == 'en'
              ? '/static/images/category-1.png'
              : '/static/images/category-1-rtl.png'
          "
      /></router-link>
    </div>
    <div class="col-span-4 md:col-span-2 lg:col-span-1">
      <router-link to="/shop?section=giftsets">
        <CategoryCard
          :title="content.giftSets"
          :img="
            lang == 'en'
              ? '/static/images/category-2.png'
              : '/static/images/category-2-rtl.png'
          "
      /></router-link>
    </div>
    <div class="col-span-4 md:col-span-2 lg:col-span-1">
      <router-link to="/shop?section=accessories">
        <CategoryCard
          :title="content.accessories"
          :img="
            lang == 'en'
              ? '/static/images/category-3.png'
              : '/static/images/category-3-rtl.png'
          "
      /></router-link>
    </div>
    <div class="col-span-4 md:col-span-2 lg:col-span-1">
      <router-link to="/shop?section=giftcards">
        <CategoryCard
          :title="content.giftCards"
          :img="
            lang == 'en'
              ? '/static/images/category-4.png'
              : '/static/images/category-4-rtl.png'
          "
      /></router-link>
    </div> -->
  </div>
</template>

<script>
import CategoryCard from "/src/components/ui/Home/CategoryCard.vue";
export default {
  inject: ["content"],
  components: {
    CategoryCard,
  },
  computed: {
    lang() {
      return localStorage.getItem("lang");
    },
    categories(){
      return this.$store.getters['categories/categories'];
    }
  },
  methods:{
    categoryUrl(id){
      let type = 'candles'
      switch (id) {
        case '1':
          type = 'candles'
          break;
        case '2':
          type = 'accessories'
          break;
        case '3':
          type = 'giftcards'
          break;
        case '4':
          type = 'giftsets'
          break;
      
        default:
          break;
      }

      // console.log(id, type);

      return `/shop?section=${type}`
    }
  }
};
</script>