export default {
  setProducts: (state, payload) => { state.products = payload },
  setCandles: (state, payload) => { state.candles = payload },
  setAccessories: (state, payload) => { state.accessories = payload },
  setGiftCards: (state, payload) => { state.giftCards = payload },
  setGiftSets: (state, payload) => { state.giftSets = payload },
  setTopRated: (state, payload) => { state.topRated = payload },
  setMostSelling: (state, payload) => { state.mostSelling = payload },
  setProduct: (state, payload) => { state.product = payload },
  setSimilar: (state, payload) => { state.similar = payload },
  setSearchProducts: (state, payload) => { state.searchProducts = payload },
}