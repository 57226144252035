<template>
  <div class="product-card-skeleton">
    <div class="product-card-img-skeleton overflow-hidden"></div>
    <div>
      <div>
        <div class="text-main-skeleton"></div>
        <div class="text-sec-skeleton"></div>
        <div class="rating-skeleton"></div>

        <div class="price-skeleton lora-bold"></div>
      </div>
    </div>
  </div>
</template>

