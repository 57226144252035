<template>
  <div class="languages-dropdown-menu bg-blueLight" id="languages-menu">
    <div class="menu-item text-bluePrimary">
      {{ content.language }}
    </div>
    <div
      class="menu-item hover:text-secondaryColor cursor-pointer"
      :class="{ 'text-primaryColor': language == 'en' }"
      @click="updateLanguage('en')"
    >
      {{ content.english }}
    </div>
    <div
      class="menu-item hover:text-secondaryColor cursor-pointer"
      :class="{ 'text-primaryColor': language == 'ku' }"
      @click="updateLanguage('ku')"
    >
      {{ content.kurdish }}
    </div>
    <div
      class="menu-item hover:text-secondaryColor cursor-pointer"
      :class="{ 'text-primaryColor': language == 'ar' }"
      @click="updateLanguage('ar')"
    >
      {{ content.arabic }}
    </div>
  </div>
</template>

<script>
export default {
  inject: ["content"],
  computed: {
    language() {
      return localStorage.getItem("lang");
    },
  },
  methods: {
    updateLanguage(lang) {
      localStorage.setItem("lang", lang);
      this.$router.go();
    },
  },
};
</script>