export default{
  products: state => state.products,
  candles: state => state.candles,
  accessories: state => state.accessories,
  giftCards: state => state.giftCards,
  giftSets: state => state.giftSets,
  topRated: state => state.topRated,
  mostSelling: state => state.mostSelling,
  product: state => state.product,
  similar: state => state.similar,
  searchProducts: state => state.searchProducts,
}