import { http } from '../../../graphql'
import gql from "graphql-tag";

export default {
  events: async context => {
    let query = gql`
      query events {
        events{
          id
          title
          description
          date
        }
      }
    `
    const response = await http(true, query, false)
    context.commit('setEvents', response.events)
  },
  event: async (context, payload) => {
    let query = gql`
      query event(id: ID!) { 
        event(id: $id) {
          id
          title
          description
          date
        }
      }
    `
    const response = await http(true, query, false, { id: payload })
    context.commit('setEvent', response.event)
  }
}