<template>
  <teleport to="body">
    <div class="relative z-40">
      <div
        v-if="show"
        id="feedback-backdrop"
        class="fixed min-w-full min-h-full top-0 left-0 z-45 bg-black bg-opacity-50"
        @click="closeModal"
      ></div>
      <transition
        enter-active-class="transition ease-out duration-100"
        enter-from-class="transform opacity-0 scale-95"
        enter-to-class="transform opacity-100 scale-100"
        leave-active-class="transition ease-in duration-75"
        leave-from-class="transform opacity-100 scale-100"
        leave-to-class="transform opacity-0 scale-95"
      >
        <div
          class="fixed top-0 left-0 z-50 h-screen w-full grid place-content-center"
          v-if="show"
          @click="closeModal"
        >
          <div
            class="relative w-11/12 sm:w-96 p-3 sm:p-6 bg-menuColor rounded"
            @click.stop="() => {}"
          >
            <h4>{{ content.completeRegistration }}</h4>
            <hr class="mt-2 mb-6" />
            <p class="mx-5 text-center">
              {{ content.enterPhone }}
            </p>
            <div class="mt-5 flex flex-col items-center justify-center">
              <div class="mb-16">
                <input
                  type="tel"
                  id="phone"
                  class="form-input w-full"
                  v-model="phone"
                  @input="onPhoneUpdate"
                  placeholder="07xx xxx xxxx"
                />
                <p class="error h-3 mt-2">
                  {{ error }}
                </p>
              </div>
              <div class="flex items-center justify-evenly space-x-4">
                <button
                  @click="submit"
                  class="flex items-center justify-between w-max-content btn-signIn"
                >
                  <div class="lora-bold">
                    {{ content.addNumber }}
                  </div>
                </button>
                <!-- <button
                  @click="closeModal"
                  class="flex items-center justify-between w-max-content btn-signIn"
                >
                  <div class="lora-bold">
                    {{ content.addLater }}
                  </div>
                </button> -->
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </teleport>
</template>

<script>
export default {
  inject: ["content"],
  data() {
    return {
      phone: null,
      error: null,
      // show: true,
    };
  },
  computed: {
    show() {
      return (
        this.$store.getters["user/popup"] == true &&
        this.$store.getters["user/showedPopup"] == false &&
        !this.user.phone
      );
    },
    user() {
      return this.$store.getters["user/user"];
    },
  },
  mounted() {
    if (this.user && !this.user.phone) {
      this.$store.dispatch("user/setPopup", true);
    }
  },
  watch: {
    user() {
      if (this.user && !this.user.phone) {
        this.$store.dispatch("user/setPopup", true);
      }
    },
  },
  methods: {
    closeModal() {
      this.$store.dispatch("user/setPopup", false);
      // this.$store.dispatch("user/setShowedPopup", true);
    },
    onPhoneUpdate(event){
      let phone = event.target.value;

      if(phone.length >= 11){
        this.phone = phone.slice(0, 11);
      }
    },
    async submit() {
      this.error = null;

      if (!this.phone) {
        this.error = this.content.phoneRequired;
        return;
      }

      // phone number should be in the format of 07xx xxx xxxx
      if (!/^07[0-9]{9}$/.test(this.phone) && !/^7[0-9]{9}$/.test(this.phone)) {
        this.error = this.content.phoneInvalid;
        return;
      }

      try {
        let phone = this.phone;
        this.phone = null;
        await this.$store.dispatch("user/updatePhone", phone);
      } catch (error) {
        this.error = this.content.phoneTaken;
      }
    },
  },
};
</script>
