import { http } from '../../../graphql'
import gql from "graphql-tag";

export default {
  requests: async context => {
    let query = gql`
      query requests {
        requests: personalizedRequests{
          id
          description
          status
          file
          date
          price
          accepted
        }
      }
    `
    const response = await http(true, query, true)
    context.commit('setRequests', response.requests)
  },
  request: async (context, payload) => {
    let query = gql`
      query request($id: ID!) {
        request: personalizedRequest(id: $id){
          id
          description
          status
          file
          date
          price
          accepted
        }
      }
    `
    const response = await http(true, query, true, { id: payload })
    context.commit('setRequest', response.request)
  },
  makeRequest: async (context, payload) => {
    let mutation = gql`
      mutation personalizedRequest($input: PersonalizedInput!) {
        personalizedRequest (input: $input) {
          id
        }
      }
    `
    let input = {
      description: payload.notes,
    }
    if (payload.token) input.token = payload.token
    if (payload.file) input.file = payload.file

    const response = await http(false, mutation, true, { input: input })
    context.commit('setRequests', response.requests)
  },
  accept: async (context, payload) => {
    let mutation = gql`
      mutation acceptPersonalized ($input: AcceptPersonalizedInput!) {
        order: acceptPersonalized (input: $input) {
          id
          token
          requests {
            id
            description
            status
            file
            date
            price
            accepted
          }
        }
      }
    `
    let input = {
      id: payload.id,
      method: payload.method ?? 'cash',
    }

    const response = await http(false, mutation, true, { input: input })
    context.commit('setRequests', response.order.requests)

    if (payload.method === 'zaincash') {
      window.location.replace(`https://api.zaincash.iq/transaction/pay?id=${response.order.token}`)
    } else if (payload.method == 'fastpay') {
      window.location.replace(response.order.token)
    } else if (payload.method === 'nasspay') {
      let json = JSON.parse(response.order.token)
      let transactionId = json.transactionId
      let token = json.token
      // let user = '7735313620'
      let user = '7517924925'

      window.location.replace(`https://checkout.nasswallet.com/payment-gateway?id=${transactionId}&token=${token}&userIdentifier=${user}`, '_blank')
      // window.open(`https://uatcheckout1.nasswallet.com/payment-gateway?id=${transactionId}&token=${token}&userIdentifier=${user}`, '_blank')
    }
  }
}