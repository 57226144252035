import store from './store'

const addPhoto = async (image) => {
  let extension = image.name.split(".").pop();
  let hash = require("crypto")
    .createHash("sha256")
    .update(image.name.split(".")[0])
    .digest("base64");

  hash = hash.replace("/", "");
  hash = hash.replace(".", "");

  let name = hash + "." + extension;

  let signedUrl = await store.dispatch('auth/signedUrl', 'images/' + name)



  let awsUrl = 'https://s3.eu-central-1.amazonaws.com/image.lala-candles.com/'

  const response = await putFile(signedUrl, image);

  if (response.statusText == "OK") {
    return response.url.split('?')[0].replace(awsUrl, '')
  } else throw new Error('image')

}


const putFile = async (url, body) => {
  let requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "multipart/form-data",
    },

    body: body,
  };

  return await fetch(url, requestOptions).then((response) => response);
}

// const uploadFile = async (inputElement) => {
//   let binaryBlob = null;
//   let reader = new FileReader();
//   reader.onloadend = function () {
//     let data = reader.result.split(",")[1];
//     binaryBlob = atob(data);
//   };
//   reader.readAsDataURL(inputElement);
//   return binaryBlob;
// }

export default addPhoto
