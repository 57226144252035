import actions from './actions'
import getters from './getters'
import mutations from './mutations'

export default {
  namespaced: true,
  state: () => ({
    wicks: [],
    wick: null,
    scents: [],
    scent: null,
    sizes: [],
    size: null,
    colors: [],
    color: null,
    containers: [],
    container: null,
    packages: [],
    package: null,
    refillContainers: [],
    refillContainer: null,
  }),
  actions,
  getters,
  mutations,
}