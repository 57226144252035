import { http } from '../../graphql'
import gql from "graphql-tag";

export default {
  setPopup: (context, payload) => {
    context.commit('setPopup', payload)
  },
  setShowedPopup: (context, payload) => {
    context.commit('setShowedPopup', payload)
  },
  user: async context => {
    let query = gql`
      query user {
        user{ 
          id
          name
          email
          phone
          status
          address
          profile
          gender
          dob
          age
          city {
            id
            name
          }
          cart {
            id
            quantity
            total
            product {
              id
              stock
              rating
              ratings
              details {
                title
                description
                image
                price
              }
              collection {
                id
                title
              }
            }
            color {
              id
              title
              color
            }
            scent {
              id
              title
              image
            }
            size {
              id
              size {
                id
                title
              }
              price
            }
          }
          refillCart{
            id
            quantity
            wick {
              id
              title
              image
            }
            container {
              id
              title
            }
            color {
              id
              title
              color
              refill
            }
            size {
              id
              title
            }
            scent {
              id
              title
              image
            }
            total
          }
        }
      }
    `
    const response = await http(true, query, true)
    let user = { ...response.user }
    
    let cart = response.user.cart
    delete user.cart
    let refillCart = response.user.refillCart
    delete user.refillCart

    context.commit('cart/setCart', cart, { root: true })
    context.commit('refill/setCart', refillCart, { root: true })
    context.commit('setUser', response.user)
    context.dispatch('auth/auth', true, { root: true })
    context.dispatch('auth/checkLogin', null, { root: true })
    context.commit('auth/setPending', false, { root: true })
  },
  update: async (context, payload) => {
    let mutation = gql`
      mutation updateProfile ($input: ProfileInput!){
        user: updateProfile (input: $input){
          id
          name
          email
          phone
          status
          address
          profile
          gender
          age
          dob
          city {
            id
            name
          }
        }
      }
    `

    let input = {
      name: payload.name,
      email: payload.email,
    }

    if (payload.phone) input.phone = payload.phone
    if (payload.city) input.city = parseInt(payload.city)
    if (payload.address) input.address = payload.address
    if (payload.profile) input.profile = payload.profile
    if (payload.gender) input.gender = payload.gender
    if (payload.dob) input.dob = payload.dob

    const response = await http(false, mutation, true, { input: input })
    context.commit('setUser', response.user)
  },
  updatePassword: async (context, payload) => {
    let mutation = gql`
      mutation updatePassword ($input: UpdatePasswordInput!){
        updatePassword (input: $input)
        {
          id
        }
      }
    `
    await http(false, mutation, true, {
      input: {
        old: payload.oldPassword,
        new: payload.newPassword,
      }
    })
  },
  updatePhone: async (context, payload) => {
    let mutation = gql`
      mutation updatePhone ($input: PhoneInput!){
        user: updatePhone (input: $input){
          id
          name
          email
          phone
          status
          address
          profile
          city {
            id
            name
          }
        }
      }
    `

    const response = await http(false, mutation, true, { input: { phone: payload } })
    context.commit('setUser', response.user)
    context.dispatch('setPopup', false)
  }
}