<template>
<!-- active-product-color -->
  <div
    class="product-color-radio"
    :class="{ 'ring-2 ring-secondaryColor': selectedColor }"
    @click="$emit('updateColor', color)"
    :style="`background:${color}`"
  ></div>
</template>
<script>
export default {
  emits: ["updateColor"],
  props: ["color", "selectedColor"],
};
</script>