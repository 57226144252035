<template>
  <Modal :modalOpened="true" @close="closeModal">
    <div class="bg-white rounded-lg p-6">
      <h4 class="text-lg">{{ content.chooseMethod }}</h4>
      <hr class="mt-3 mb-6 opacity-30" />
      <div class="flex flex-col items-center justify-center space-y-3">
        <button
          class="w-full text-white alert-btn bg-beingDelivered grid grid-cols-3 items-center"
          @click="submit('cash')"
        >
          <span class="col-span-1 flex justify-start px-2">
            <i class="fas fa-money-bill-wave-alt"></i>
          </span>
          <span class="col-span-2 flex justify-start">
            {{ content.cash }}
          </span>
        </button>
        <button
          class="w-full text-white alert-btn bg-nassWallet grid grid-cols-3 items-center"
          @click="submit('nasspay')"
        >
          <span class="col-span-1 flex justify-start px-2">
            <img class="h-5" src="/static/images/nw-icon.png" />
          </span>
          <span class="col-span-2 flex justify-start">
            {{ content.nasswallet }}
          </span>
        </button>
        <button
          class="w-full text-white alert-btn bg-black grid grid-cols-3 items-center"
          @click="submit('zaincash')"
        >
        <span class="col-span-1 flex justify-start px-2">
            <img class="h-5" src="/static/images/zaincash-icon.png" />
          </span>
          <span class="col-span-2 flex justify-start">
          {{ content.zaincash }}
          </span>
        </button>
        <!-- <button
          class="w-full text-white alert-btn bg-bluePrimary"
          @click="submit('fastpay')"
        >
          {{ content.fastpay }}
        </button> -->
      </div>
    </div>
  </Modal>
</template>

<script>
export default {
  inject: ["content"],
  emits: ["submit", "close"],
  methods: {
    submit(method) {
      this.$emit("submit", method);
    },
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>
