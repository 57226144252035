<template>
  <div class="pill">
    {{ text }}
  </div>
</template>

<script>
export default {
  props: ["text"],
};
</script>