<template>
  <div class="product-review-card-skeleton">
    <div class="flex flex-wrap justify-between">
      <div class="flex items-center whitespace-nowrap">
        <div class="customer-image-review-skeleton me-4"></div>
        <div>
          <div class="title mb-2 w-36"></div>
          <div class="title mb-2 w-28"></div>
        </div>
      </div>
      <div class="rating text-size-16"></div>
    </div>
    <div class="review mt-4"></div>
    <div class="review mt-1"></div>
  </div>
</template>

