const load = () => {
  let siteKey = "6LeeHJEeAAAAAHNJtMdpCWjDX6bD6rJTzJfmDkTc"

  let recaptchaScript = document.createElement("script");
  recaptchaScript.setAttribute('id', 'g-recaptcha');
  recaptchaScript.setAttribute(
    "src",
    `https://www.google.com/recaptcha/api.js?render=${siteKey}`
  );
  document.body.append(recaptchaScript);

  recaptchaScript.onload = () => {
    let loadScript = document.createElement("script");
    loadScript.setAttribute('id', 'g-recaptcha-load');
    loadScript.innerHTML = "window.grecaptcha = grecaptcha;";
    document.body.append(loadScript);
  };
}

const unload = () => {
  document.getElementById('g-recaptcha-load').remove();
  document.getElementById('g-recaptcha').remove();
  document.querySelector('div>.grecaptcha-badge').remove();
  delete window.grecaptcha
}

export default { load, unload }
