import { http } from '../../../graphql'
import gql from "graphql-tag";

export default {
  texts: async context => {
    let query = gql`
      query texts {
        texts {
          type
          id
          title
          subtitle
          description
          image
          link
          video
          images {
            id
            url
          }
        }
      }
    `
    const response = await http(true, query, false)
    context.commit('setTexts', response.texts)
  },
  sliders: async context => {
    let query = gql`
      query sliders {
        sliders {
          id
          title
          subtitle
          description
          image
          linkOne
          linkTwo
          buttonOne
          buttonTwo
          color
        }
      }
    `
    const response = await http(true, query, false)
    context.commit('setSliders', response.sliders)
  },
  values: async context => {
    let query = gql`
      query valueBoxes {
        values: valueBoxes {
          type
          id
          title
          subtitle
          description
          image
          link
          video
          images {
            id
            url
          }
        }
      }
    `
    const response = await http(true, query, false)
    context.commit('setTexts', response.texts)
  },
  banner: async context => {
    let query = gql`
      query banner {
        banner: banner {
          id
          title
          link
          bgColor
          textColor
          image
        }
      }
    `
    const response = await http(true, query, false)
    context.commit('setBanner', response.banner)
  }
}