import { http } from '../../../graphql'
import gql from "graphql-tag";

export default {
  cities: async context => {
    let query = gql`
      query cities {
        cities {
          id
          name
          price
        }
      }
    `
    const response = await http(true, query, false)
    context.commit('setCities', response.cities)
  },
  city: async (context, payload) => {
    let query = gql`
      query city($id: ID!) {
        city (id: $id){
          id
          name
          price
        }
      }
    `
    const response = await http(true, query, false, { id: payload })
    context.commit('setCity', response.city)
  },
  // districts: async context => {
  //   let query = `
  //     query districts {
  //       id
  //       name
  //       price
  //       city {
  //         id
  //         name
  //       }
  //     }
  //   `
  // },
  // district: async (context, payload) => {
  //   let query = `
  //     query district(id: ${payload}) {
  //       id
  //       name
  //       price
  //       city {
  //         id
  //         name
  //       }
  //     }
  //   `
  // }
}