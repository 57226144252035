import { http } from '../../graphql'
import gql from "graphql-tag";

export default {
  subscribe: async (context, payload) => {
    let mutation = gql`
      mutation newsletter ($input: NewsletterInput!) {
        newsletter (input: $input) {
          status
        }
      }
    `

    let input = {
      email: payload.email,
    }
    if (payload.token) input.token = payload.token

    await http(false, mutation, false, { input: input })
  }
}