import { http } from '../../graphql'
import gql from "graphql-tag";

import languages from '../../assets/Languages';

export default {
  checkLogin: async context => {
    context.commit('setCheck', true)
  },
  userPending: async context => {
    context.commit('setPending', true)
  },
  auth: async (context, payload) => {
    context.commit('setAuth', payload)
  },
  login: async (context, payload) => {
    let mutation = gql`
      mutation login ($input: LoginInput!){
        user: login(input: $input){
          id
          name
          email
          phone
          status
          address
          profile
          dob
          gender
          age
          city {
            id
            name
          }
          cart {
            id
            quantity
            total
            product {
              id
              stock
              rating
              ratings
              details {
                title
                description
                image
                price
              }
              collection {
                id
                title
              }
            }
            color {
              id
              title
              color
            }
            scent {
              id
              title
              image
            }
            size {
              id
              size {
                id
                title
              }
              price
            }
          }
          refillCart{
            id
            quantity
            wick {
              id
              title
              image
            }
            container {
              id
              title
            }
            color {
              id
              title
              color
              refill
            }
            size {
              id
              title
            }
            scent {
              id
              title
              image
            }
            total
          }
        }
      }
    `
    const response = await http(false, mutation, true, {
      input: {
        email: payload.email,
        password: payload.password,
      }
    })
    // if (response.user) {
    let user = { ...response.user }

    let cart = response.user.cart
    delete user.cart
    let refillCart = response.user.refillCart
    delete user.refillCart

    context.commit('refill/setCart', refillCart, { root: true })
    context.commit('cart/setCart', cart, { root: true })
    context.commit('user/setUser', response.user, { root: true })
    context.dispatch('auth', true)
    context.dispatch('checkLogin', null)
    context.commit('setPending', false)


    // context.dispatch('auth', true)
    // context.commit('user/setUser', response.user, { root: true })
    // context.dispatch('user/user', null, { root: true })  

    // }
  },
  register: async (context, payload) => {
    let mutation = gql`
      mutation register ($input: RegisterInput!){
        user: register(input: $input){
          id
          name
          email
          phone
          status
          address
          profile
          dob
          gender
          city {
            id
            name
          }
        }
      }
    `

    let input = {
      name: payload.name,
      email: payload.email,
      phone: payload.phone,
      password: payload.password,
      city: payload.city,
      // address: payload.address,
    }

    if (payload.profile) input.profile = payload.profile
    if (payload.gender) input.gender = payload.gender
    if (payload.dob) input.dob = payload.dob
    if (payload.token) input.token = payload.token

    const response = await http(false, mutation, true, { input: input })
    context.commit('user/setUser', response.user, { root: true })
    context.commit('setAuth', true)
    context.commit('setCheck', true)
    context.commit('setPending', response.user.status == 'pending')
  },
  // verify: async (context, payload) => {
  //   mutation = `
  //     mutation vrrifyAccount (code: ${payload}) {
  //       id
  //       name
  //       email
  //       phone
  //       status
  //       address
  //       profile
  //       city 
  //         id
  //         name
  //       }
  //     }
  //   `
  //   const response = await http(false, mutation, false)
  //   context.commit('setWicks', response.wicks)
  // },
  logout: async context => {
    let mutation = gql`
      mutation logout {
        logout {
          status
        }
      }
    `
    await http(false, mutation, true)
    context.commit('setAuth', false)
    context.commit('setPending', false)
    context.commit('setCheck', false)
    context.commit('user/setUser', null, { root: true })
    context.commit('user/setShowedPopup', false, { root: true })
  },
  // deactivate: async (context) => {
  //   let mutation = gql`
  //     mutation deactivate {
  //       deactivate {
  //         status
  //       }
  //     }
  //   `
  //   await http(false, mutation, true)
  //   context.commit('setAuth', false)
  //   context.commit('setPending', false)
  //   context.commit('setCheck', false)
  //   context.commit('user/setUser', null, { root: true })
  // },
  forgotPassword: async (context, payload) => {
    let mutation = gql`
      mutation forgotPassword ($email: String!){
        forgotPassword (email: $email) {
          status
        }
      }
    `
    await http(false, mutation, false, { email: payload })
  },
  resetPassword: async (context, payload) => {
    let mutation = gql`
      mutation resetPassword ($input: ResetPasswordInput!){
        res: resetPassword (input: $input) {
          status
          message
        }
      }`

    let response = await http(false, mutation, false, {
      input: {
        token: payload.token,
        email: payload.email,
        password: payload.password
      }
    })

    if (response.res.status == 'fail') {
      throw new Error(response.res.message)
    }
  },
  socialLogin: async (context, payload) => {
    let mutation = gql`
      mutation socialLogin ($input: SocialLoginInput!) {
        user: socialLogin (input: $input){
          id
          name
          email
          phone
          status
          address
          profile
          city {
            id
            name
          }
        }
      }
    `
    const response = await http(false, mutation, true, {
      input: {
        id: payload.id,
        name: payload.name,
        email: payload.email,
        profile: payload.profile,
        type: payload.type,
      }
    })
    context.dispatch('auth', true)
    context.commit('user/setUser', response.user, { root: true })
  },
  resendEmail: async () => {
    let mutation = gql`
      mutation resendEmail {
        res: resendEmail {
          status
          message
        }
      }
    `

    const response = await http(false, mutation, true)

    if (response.res.status == 'fail') {
      let lang = localStorage.getItem("lang");
      throw new Error(languages[lang].errorOccured)
    }
  },
  // google: async (context, payload) => {
  //   let url = ''
  // },
  // facebook: async (context, payload) => {
  //   let url = ''
  // },

  signedUrl: async (_, payload) => {
    let mutation = gql`
      mutation signedUrl ($file: String!) {
        res: signedUrl (file: $file) {
          url
        }
      }
    `

    const response = await http(false, mutation, true, {file: payload})
    if (response.res.url) {
      return response.res.url
    }
  }
}

