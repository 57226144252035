import { http } from '../../../graphql'
import gql from "graphql-tag";

export default {
  categories: async context => {
    let query = gql`
      query categories {
        categories{
          id
          title
          image
          subCategories {
            id
            title
          }
        }
      }
    `
    if (context.state.categories.length >= 1) return
    const response = await http(true, query, false)
    context.commit('setCategories', response.categories)
  },
  category: async (context, payload) => {
    let query = gql`
      query category {
        category (id: ${payload}){
          id
          title
          story
          image
          subCategories {
            id
            title
          }
        }
      }
    `
    const response = await http(true, query, false)
    context.commit('setCategory', response.category)
  },
}