<template>
  <PaymentModal v-if="modalVisibility" @close="closeModal" @submit="submitOrder" />

  <div class="layout-padding min-h-96">
    <PageTitle class="my-8" :title="content.checkout" :childOne="content.shop" :childTwo="content.checkout" />
    <div class="my-5 grid grid-cols-12 gap-x-0 md:gap-x-10">
      <div class="col-span-12 lg:col-span-8">
        <!-- <div class="order-card">
          <label>
            <input
              type="radio"
              :value="pickup ? false : true"
              :checked="pickup ? true : false"
              @change="(event) => setPickup(event)"
            />
            <span class="ms-2"> {{ content.pickup }} </span>
          </label>
          <div
            v-if="pickup"
            class="grid grid-cols-2 gap-x-10 gap-y-5 w-full mt-5 ps-5"
          >
            <div class="col-span-2 md:col-span-1">
              <div class="flex flex-col sm:flex-row">
                <label
                  for="city"
                  class="input-label mb-2 sm:mb-0 flex items-center"
                >
                  {{ content.city }}
                </label>
                <select id="city" class="form-input bg-white" v-model="city">
                  <option disabled selected :value="null">City</option>
                  <option
                    v-for="city in cities"
                    :key="city.id"
                    :value="city.id"
                  >
                    {{ city.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-span-2 md:col-span-1">
              <div class="flex flex-col sm:flex-row">
                <label
                  for="point"
                  class="input-label mb-2 sm:mb-0 flex items-center"
                >
                  {{ content.location }}
                </label>
                <select
                  id="point"
                  class="form-input bg-white"
                  v-model="sellingPoint"
                >
                  <option
                    v-if="sellingPoints.length < 1"
                    disabled
                    selected
                    :value="null"
                  >
                    {{ content.noDrop }}
                  </option>
                  <option v-else disabled selected :value="null">
                    {{ content.location }}
                  </option>
                  <option
                    v-for="location in sellingPoints"
                    :key="location.id"
                    :value="location.id"
                  >
                    {{ location.title }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-span-2">
              <div class="flex flex-col sm:flex-row mt-8">
                <label
                  for="notes"
                  class="input-label mb-2 sm:mb-0 flex items-start"
                >
                  {{ content.notes }}
                </label>
                <textarea
                  type="text"
                  class="form-input"
                  v-model="notes"
                  id="notes"
                  :placeholder="content.notes"
                />
              </div>
            </div>
          </div>
        </div> -->
        <div class="order-card">
          <!-- Guest User -->
          <div class="mb-5 w-full" v-if="!auth">
            <span class="ms-5 text-bluePrimary text-base"> {{ content.contactInformation }} </span>
            <div class="grid grid-cols-2 gap-x-10 gap-y-10 w-full mt-5 ps-5">

              <div class="col-span-2 md:col-span-1">
                <div class="flex flex-col sm:flex-row">
                  <label for="name" class="input-label mb-2 sm:mb-0 flex items-center">
                    {{ content.name }}
                  </label>
                  <div class="relative w-full">
                    <input type="text" class="form-input" v-model="guest.name" id="name" :placeholder="content.name" />
                    <span class="error absolute left-0 -bottom-7">
                      {{ guestNameError }}
                    </span>
                  </div>
                </div>
              </div>
              <!-- <div class="col-span-2 md:col-span-1">
                <div class="flex flex-col sm:flex-row">
                  <label for="address" class="input-label mb-2 sm:mb-0 flex items-center">
                    {{ content.address }}
                  </label>
                  <div class="relative w-full">
                    <input type="text" class="form-input" v-model="guest.address" id="address"
                      :placeholder="content.address" />
                    <span class="error absolute left-0 -bottom-7">
                      {{ guestAddressError }}
                    </span>
                  </div>
                </div>
              </div> -->
              <div class="col-span-2 md:col-span-1">
                <div class="flex flex-col sm:flex-row">
                  <label for="phone" class="input-label mb-2 sm:mb-0 flex items-center">
                    {{ content.phone }}
                  </label>
                  <div class="relative w-full">
                    <input type="text" class="form-input" v-model="guest.phone" id="phone"
                      :placeholder="content.phone" />
                    <span class="error absolute left-0 -bottom-7">
                      {{ guestPhoneError }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-span-2 md:col-span-1">
                <div class="flex flex-col sm:flex-row">
                  <label for="email" class="input-label mb-2 sm:mb-0 flex items-center">
                    {{ content.email }}
                  </label>
                  <div class="relative w-full">
                    <input type="email" class="form-input" v-model="guest.email" id="email"
                      :placeholder="content.email" />
                    <span class="error absolute left-0 -bottom-7">
                      {{ guestEmailError }}
                    </span>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <span class="ms-5 text-bluePrimary text-base"> {{ content.delivery }} </span>
          <div v-if="delivery" class="grid grid-cols-2 gap-x-10 gap-y-5 w-full mt-5 ps-5">
            <div class="col-span-2 md:col-span-1">
              <div class="flex flex-col sm:flex-row">
                <label for="city" class="input-label mb-2 sm:mb-0 flex items-center">
                  {{ content.city }}
                </label>
                <div class="relative w-full">
                  <select id="city" class="form-input bg-white" v-model="city">
                    <option disabled selected :value="null">City</option>
                    <option v-for="city in cities" :key="city.id" :value="city.id">
                      {{ city.name }}
                    </option>
                  </select>

                  <span class="error absolute left-0 -bottom-7"> {{ cityError }} </span>
                </div>
              </div>
            </div>
            <div class="col-span-2 md:col-span-1">
              <div class="flex flex-col sm:flex-row">
                <label for="address" class="input-label mb-2 sm:mb-0 flex items-center">
                  {{ content.address }}
                </label>
                <div class="relative w-full">
                  <input type="text" class="form-input" v-model="address" id="address" :placeholder="content.address" />
                  <span class="error absolute left-0 -bottom-7">
                    {{ deliveryError }}
                  </span>
                </div>
              </div>
            </div>
            <div class="col-span-2">
              <div class="flex flex-col sm:flex-row mt-8">
                <label for="notes" class="input-label mb-2 sm:mb-0 flex items-start">
                  {{ content.notes }}
                </label>
                <textarea type="text" class="form-input" v-model="notes" id="notes" :placeholder="content.notes" />
              </div>
            </div>
          </div>



        </div>
        <div v-if="option" class="col-span-12 lg:col-span-8 mb-4">
          <Alert type="info" :message="content.chooseShipping"> </Alert>
        </div>
      </div>
      <div class="col-span-12 lg:col-span-4">
        <OrderSummery v-if="single" :product="item" :delivery="this.delivery" :city-id="city"
          :button-disabled="buttonDisabled" :option="option" @submitOrder="openModal" />
        <OrderSummery v-else :delivery="this.delivery" :city-id="city" :button-disabled="buttonDisabled"
          :option="option" @submitOrder="openModal" />
      </div>
    </div>
    <div class="w-full flex justify-center">
      <Alert v-if="error" customClasses="max-w-550" type="fail" :title="content.fail" :message="content.orderFail">
        <router-link to="/"
          class="alert-btn text-text-white text-white lora-bold whitespace-nowrap bg-secondaryColor duration-300 mt-6">
          {{ content.goHome }}
        </router-link>
      </Alert>
    </div>
  </div>
</template>

<script>
import OrderSummery from "/src/components/ui/Cart/CheckoutOrderSummery.vue";
import PaymentModal from "../../../components/ui/PaymentModal.vue";
// import CustomCheckbox from "/src/components/ui/CustomCheckbox.vue";
import recaptcha from '/src/assets/googleRecaptcha';

export default {
  components: {
    OrderSummery,
    PaymentModal,
    // CustomCheckbox,
  },
  inject: ["content"],
  data() {
    return {
      option: false,
      single: false,
      // delivery: false,
      delivery: true,
      pickup: false,
      notes: "",
      address: "",
      accountAddress: false,
      city: null,
      token: null,
      guest: {
        name: null,
        address: null,
        phone: null,
        email: null
      },
      guestNameError: null,
      guestEmailError: null,
      guestPhoneError: null,
      sellingPoint: null,
      error: null,
      modalVisibility: false,
      cityError: null,
      deliveryError: null,
      buttonDisabled: false,
    };
  },
  mounted() {
    recaptcha.load();
    if (
      this.$route.query.product &&
      // this.$route.query.color &&
      // this.$route.query.size &&
      this.$route.query.quantity
    ) {
      this.single = true;
      this.$store.dispatch("products/product", this.$route.query.product);
    }
  },

  unmounted() {
    recaptcha.unload();
  },
  watch: {
    city() {
      if (this.city) {
        this.cityError = null;
      }
    },
    address() {
      if (this.address && this.address != "") {
        this.deliveryError = null;
      }
    },
  },
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
    studio() {
      return this.$store.getters["shops/studio"];
    },
    sellingPoints() {
      let locations = [...this.$store.getters["shops/sellingPoints"], this.studio];
      return this.city
        ? locations.filter((location) => location.city.id == this.city && location.drop)
        : [];
    },
    item() {
      let item = {};
      // console.log({
      //   product: this.product,
      //   size: this.size,
      //   color: this.color,
      //   quantity: this.$route.query.quantity,
      // });
      if (this.$route.query.product) item.product = this.product;
      if (this.$route.query.color) item.color = this.color;
      if (this.$route.query.size) item.size = this.size;
      if (this.$route.query.scent) item.scent = this.scent;
      if (this.$route.query.quantity) item.quantity = this.$route.query.quantity;

      return item == {} ? null : item;
      // return this.$route.query.product &&
      //   this.$route.query.color &&
      //   this.$route.query.size &&
      //   this.$route.query.quantity
      //   ? {
      //       product: this.product,
      //       size: this.size,
      //       color: this.color,
      //       quantity: this.$route.query.quantity,
      //     }
      //   : null;
    },
    product() {
      return this.$route.query.product ? this.$store.getters["products/product"] : null;
    },
    cities() {
      return this.$store.getters["cities/cities"];
    },
    // city() {
    //   return this.user?.city?.id;
    // },
    color() {
      return this.$route.query.color
        ? this.$store.getters["materials/color"](this.$route.query.color)
        : null;
    },
    size() {
      return this.$route.query.size
        ? this.product?.sizes?.filter((size) => size.id == this.$route.query.size)[0]
        : null;
    },
    cart() {
      return this.auth ? this.$store.getters["cart/cart"] : JSON.parse(localStorage.getItem('cart'))?.items ?? [];
    },
    refillCart() {
      return this.auth ? this.$store.getters["refill/cart"] : JSON.parse(localStorage.getItem('refill-cart'))?.items ?? [];
    },
    auth() {
      return this.$store.getters["auth/auth"];
    },
  },
  methods: {
    async recaptcha(method) {
      let grecaptcha = window.grecaptcha;

      grecaptcha.ready(() => {
        grecaptcha
          .execute('6LeeHJEeAAAAAHNJtMdpCWjDX6bD6rJTzJfmDkTc', {
            action: 'submit',
          })
          .then((token) => {
            this.token = token;
            this.submitOrder(method);
          })
      });
    },
    setAddress() {
      this.accountAddress = !this.accountAddress;
    },
    setDelivery(event) {
      if (!this.city) this.city = this.user?.city?.id ?? null;
      this.delivery = event.target.value;
      if (this.pickup == this.delivery) this.pickup = !this.pickup;
      this.option = false;
    },
    setPickup(event) {
      if (!this.city) this.city = this.user?.city?.id ?? null;
      this.pickup = event.target.value;
      if (this.delivery == this.pickup) this.delivery = !this.delivery;
      this.option = false;
    },
    openModal() {
      this.cityError = null;
      this.guestNameError = null;
      this.guestPhoneError = null;
      this.guestEmailError = null;
      this.deliveryError = null;
      if (!this.delivery && !this.pickup) {
        this.option = true;
        return;
      }
      if(!this.auth){
        if (!this.guest.name) {
          this.guestNameError = this.content.nameRequired;
          return;
        }
        if (!this.guest.phone) {
          this.guestPhoneError = this.content.phoneRequired;
          return;
        }
        if (!this.guest.email) {
          this.guestEmailError = this.content.emailRequired;
          return;
        }
      }
      if (this.delivery) {
       

        if (!this.city) {
          this.cityError = this.content.chooseCity;
          return;
        }
        if (!this.address) {
          this.deliveryError = this.content.enterAddress;
          return;
        }
      }

      this.modalVisibility = true;
    },
    closeModal() {
      this.modalVisibility = false;
    },
    async submitOrder(method) {
      if (!this.token) {
        await this.recaptcha(method);
      }
      if(!this.token)return;
      this.closeModal();
      this.buttonDisabled = true;

      let input = {
        method: method,
        token: this.token
      };
      if (this.delivery) {
        input.city = this.city;
        input.address = this.address;
        input.sellingPoint = null;
      } else if (this.pickup) {
        input.sellingPoint = this.sellingPoint;
        input.city = null;
        input.address = null;
      }

      if (this.notes) input.notes = this.notes;

      if (this.single) {
        input.id = this.$route.query.product;
        input.size = this.$route.query.size;
        input.color = this.$route.query.color;
        input.scent = this.$route.query.scent;
        input.quantity = this.$route.query.quantity;
      }



      if (!this.auth) {
        input.guest = {
          ...this.guest,
          address: this.address,
        }
        if (this.cart?.length) {
          input.products = this.cart?.map((item) => {
            let product = {};
            if (item.size) product.size = item.size.id;
            if (item.scent) product.scent = item.scent.scent.id;
            if (item.color) product.color = item.color.color.id;
            if (item.product) product.id = item.product.id;
            if (item.quantity) product.quantity = parseInt(item.quantity);
            return product
          })
        }
        if (this.refillCart?.length) {
          input.refillItems = this.refillCart?.map((item) => {
            let product = {};
            if (item.product) product.id = item.product.id;
            if (item.container) product.container = item.container.id;
            if (item.size) product.size = item.size.id;
            if (item.wick) product.wick = item.wick.id;
            if (item.scent) product.scent = item.scent.id;
            if (item.color) product.color = item.color.id;
            if (item.quantity) product.quantity = parseInt(item.quantity);
            return product
          })
        }
      }

      try {
        this.closeModal();
        if (this.auth) {
          this.single
            ? await this.$store.dispatch("orders/buyNow", input)
            : await this.$store.dispatch("orders/checkout", input);
        } else {
          this.single
            ? await this.$store.dispatch("orders/buyNowGuest", input)
            : await this.$store.dispatch("orders/guestCheckout", input);
        }
        localStorage.removeItem('cart')
        localStorage.removeItem('refill-cart')
        await this.$store.dispatch("feedback/setFeedback", {
          type: "success",
          title: this.content.success,
          message: this.content.orderSuccess,
        });

        this.buttonDisabled = false;
        if (this.auth) {
          this.$router.replace("/");
        }
      } catch (error) {
        this.buttonDisabled = false;
        // this.error = true;
        // if (error[0].debugMessage == "Unauthorized.phone") {
        //   this.$store.dispatch("user/setShowedPopup", false);
        //   this.$store.dispatch("user/setPopup", true);
        // } else 
        if (error[0].message == "stock") {
          this.$store.dispatch("feedback/setFeedback", {
            type: "fail",
            title: this.content.fail,
            message: this.content.moreThanStock,
          });
        } else if (!this.single || error[0].message.split(".")[0] == "stock") {
          let index = error[0].message.split(".")[1];
          this.$store.dispatch("feedback/setFeedback", {
            type: "fail",
            title: `${this.content.fail}: ${this.cart[index]?.product.details.title}`,
            message: this.content.moreThanStock,
          });
        } else if (error[0].message == "bad.request") {
          this.$store.dispatch("feedback/setFeedback", {
            type: "fail",
            title: this.content.badRequest,
            message: this.content.tryAgainLater,
          });
        } else {
          this.$store.dispatch("feedback/setFeedback", {
            type: "fail",
            title: this.content.fail,
            message: this.content.orderFail,
          });
        }
      }
      this.buttonDisabled = false;
      this.token=null;
    },
    // toggleContact(event, id) {
    //   let item = this.contact.filter((item) => item.id == id)[0];
    //   item.active = event.target.value;
    //   // this.social[index].active = event.target.value;
    // },
  },
};
</script>
