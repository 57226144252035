import { http } from '../../../graphql'
import gql from "graphql-tag";

export default {
  request: async (_, payload) => {
    let mutation = gql`
      mutation whiteLabel($input: WhiteLabelInput!){
        whiteLabel(input: $input){
          id
        }
      }
    `

    let input = {
      name: payload.name,
      phone: payload.phone,
      email: payload.email,
      logo: payload.logo,
      label: payload.label,
      description: payload.notes,
      quantity: payload.quantity,
      container: payload.containerSize,
      color: payload.color,
      scent: payload.scent,
      package: payload.package,
      wick: payload.wick,
    }
    
    if (payload.token) input.token = payload.token

    await http(false, mutation, false, { input: input })
  },
  labelPrice: async context => {
    let query = gql`
      query whiteLabelPrice {
        label: whiteLabelPrice {
          price
        }
      }
    `
    const response = await http(true, query, false)
    context.commit('setPrice', response.label.price)
  }
}