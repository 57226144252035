import SignIn from "/src/views/Main/Auth/SignIn.vue";
import SignUp from "/src/views/Main/Auth/SignUp.vue";
import ForgetPassword from "/src/views/Main/Auth/ForgetPassword.vue";
import ResetPassword from "/src/views/Main/Auth/ResetPassword.vue";
import Verification from "/src/views/Main/Auth/Verification.vue";

export default [
  {
    path: "/signin",
    name: "signin ",
    component: SignIn,
    meta: { requiresUnauth: true },
  },
  {
    path: "/signup",
    name: "signup",
    component: SignUp,
    meta: { requiresUnauth: true },
  },
  {
    path: "/forgetpassword",
    name: "forgetPassword",
    component: ForgetPassword,
    meta: { requiresUnauth: true },
  },
  {
    path: "/resetpassword",
    name: "resetPassword",
    component: ResetPassword,
    meta: { requiresUnauth: true },
  },  
  {
    path: "/verification/:status",
    name: "verification",
    props: true,
    component: Verification,
  },  
];
