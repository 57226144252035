<template>
  <div class="layout-padding min-h-96">
    <div class="flex flex-wrap justify-between items-center mb-6 mt-12">
      <div class="page-title lora-bold mb-6 me-6" v-html="content.search"></div>
      <div class="relative mb-6 me-6">
        <input
          type="text"
          class="form-input search-input"
          :placeholder="content.search"
          v-model="keyword"
        />
        <button class="search-input-button lora-bold" @click="search">
          {{ content.search }}
        </button>
      </div>
      <div class="breadcrumbs mb-6">
        <span class="cursor-pointer" @click="$router.push('/')">
          {{ content.home }}
        </span>
        <span class="cursor-pointer" @click="$router.push('/shop')">
          / {{ content.shop }}
        </span>
        <span class="cursor-pointer"> / {{ content.search }}</span>
      </div>
    </div>
    <div v-if="searched">
      <div v-if="loading"></div>
      <div class="text-grayColor mb-12" v-else>
        {{ products.length }} {{ content.resultsWereFoundFor }} "{{ keyword }}".
      </div>
      <div class="grid grid-cols-12 gap-y-10 gap-x-0 sm:gap-x-10 mb-12">
        <div
          class="col-span-12 sm:col-span-6 lg:col-span-4 2xl:col-span-3"
          v-for="product in products"
          :key="product.id"
        >
          <ProductCard :product="product" />
        </div>
      </div>
    </div>
    <div v-else>{{ content.startSearching }}</div>
  </div>
</template>

<script>
import ProductCard from "/src/components/ui/Products/ProductCard.vue";
export default {
  components: { ProductCard },
  inject: ["content"],
  data() {
    return {
      loading: true,
      searched: false,
      keyword: "",
    };
  },
  computed: {
    products() {
      return this.$store.getters["products/searchProducts"];
    },
  },
  async mounted() {
    if (this.$route.query.s) {
      await this.$store.dispatch("products/search", this.$route.query.s);
      this.searched = true;
    }

    this.loading = false;
  },
  methods: {
    async search() {
      if (this.keyword != "") {
        this.$router.push({ path: "/search", query: { s: this.keyword } });
        try {
          await this.$store.dispatch("products/search", this.keyword);
          this.searched = true;
        } catch (error) {
          console.log(error);
        }
      }
    },
  },
};
</script>