<template>
  <div
    class="
      shop-dropdown-menu
      layout-padding
      grid grid-cols-3
      gap-x-5
      bg-blueLight
    "
    id="shop-menu"
  >
    <div class="col-span-1">
      <div class="menu-section-title">{{ content.shopCandles }}</div>
      <div class="items mt-6" v-if="candles">
        <router-link
          v-for="item in candles"
          :key="item.id"
          class="item block w-full"
          :to="{
            name: 'shop',
            query: { section: 'candles', category: item.id },
          }"
        >
          {{ item.title }}
        </router-link>
        <div class="All-btn">
          <MenuAllButton
            @click="$emit('closeMenu')"
            :text="content.allCandles"
            :to="{ name: 'shop', query: { section: 'candles' } }"
          ></MenuAllButton>
        </div>
      </div>
    </div>
    <div class="col-span-1 flex flex-col justify-between">
      <div>
        <div class="menu-section-title">{{ content.shopGifts }}</div>
        <div class="items mt-6">
          <router-link
            @click="$emit('closeMenu')"
            :to="{ name: 'shop', query: { section: 'giftsets' } }"
          >
            <div class="item">{{ content.giftSet }}</div></router-link
          >
          <router-link
            @click="$emit('closeMenu')"
            :to="{ name: 'shop', query: { section: 'giftcards' } }"
          >
            <div class="item">
              {{ content.giftCards }}
            </div>
          </router-link>
          <router-link @click="$emit('closeMenu')" to="/personalized-candles">
            <div class="item">
              {{ content.personalizedCandles }}
            </div>
          </router-link>
          <router-link @click="$emit('closeMenu')" to="/giftsubscription">
            <div class="item">{{ content.giftSubscription }}</div></router-link
          >
        </div>
      </div>
      <div>
        <div class="menu-section-title">{{ content.shopAccessories }}</div>
        <MenuAllButton
          @click="$emit('closeMenu')"
          :text="content.allAccessories"
          :to="{ name: 'shop', query: { section: 'accessories' } }"
        ></MenuAllButton>
      </div>
    </div>
    <div class="col-span-1">
      <div class="menu-section-title">{{ content.shopByCollection }}</div>
      <div class="items mt-6">
        <router-link
          class="item w-full block"
          v-for="item in collections"
          :key="item.id"
          :to="{ name: 'collectionsDetails', params: { id: item.id } }"
        >
          {{ item.title }}
        </router-link>
        <div class="All-btn">
          <MenuAllButton
            @click="$emit('closeMenu')"
            :text="content.allCollections"
            to="/collections"
          ></MenuAllButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MenuAllButton from "/src/components/ui/Buttons/MenuAllButton.vue";
export default {
  emits: ["closeMenu"],

  inject: ["content"],
  components: { MenuAllButton },
  computed: {
    language() {
      return localStorage.getItem("lang");
    },
    collections() {
      let collections = this.$store.getters["collections/collections"];
      return collections.length < 6 ? collections : collections.slice(0, 5);
    },
    categories() {
      return this.$store.getters["categories/categories"];
    },
    candles() {
      let candles = this.categories[0]?.subCategories ?? null;
      return candles
        ? candles.length < 6
          ? candles
          : candles.slice(0, 5)
        : null;
    },
  },
};
</script>

<style scoped>
.items .item {
  padding: 5px 0px;
  cursor: pointer;
  font-size: 14px;
}
.items .item:hover {
  padding: 5px 0px;
  cursor: pointer;
  color: #37328b;
}
</style>