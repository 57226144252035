<template>
  <div
    class="order-item-card smd:flex-row flex-col flex items-center mb-5"
    v-for="(item, index) in cart"
    :key="index"
  >
    <!-- <div
      :style="`background: url(${item.product.details.image})`"
      class="order-item-img"
    /> -->
    <div class="px-6 flex justify-between md:px-8 w-full mb-8 smd:mb-0">
      <div class="w-full">
        <div class="w-full flex items-start justify-between">
          <div>
            <div class="item-title lora-bold">
              {{ content.refillRequest }}
            </div>
            <div class="mt-2">
              <span class="capitalize" v-if="item.container">
                {{ item.container?.title }}
              </span>
              <span v-if="item.container && item.size" class="text-lg">
                &nbsp;&nbsp;|&nbsp;&nbsp;
              </span>
              <span class="capitalize" v-if="item.size">
                {{ item.size?.title ?? "" }}
              </span>
              <span v-if="(item.container || item.size) && item.wick" class="text-lg">
                &nbsp;&nbsp;|&nbsp;&nbsp;
              </span>
              <span class="capitalize" v-if="item.wick">
                {{ item.wick?.title ?? "" }}
              </span>
            </div>
          </div>
          <button
            @click="removeItem(index)"
            class="rounded-full h-10 w-10 grid place-content-center bg-rejected hover:opacity-80 transition-opacity ease-in text-white"
          >
            <i class="far fa-trash-alt"></i>
          </button>
        </div>
        <div class="flex items-center space-x-5 mt-2">
          <div
            class="product-color-radio"
            v-if="item.color"
            :style="`background-color: ${item.color.color}`"
          ></div>
          <p v-if="item.scent">{{ item.scent.title ?? item.scent.scent.title }}</p>
        </div>
        <div class="flex mt-4 mb-4">
          <div class="relative">
            <input type="number" class="qty-input" min="1" v-model="item.quantity" />
            <button class="qty-increase" @click="increaseQty(index)">+</button>
            <button class="qty-decrease" @click="decreaseQty(index)">-</button>
          </div>
        </div>
        <div class="item-category text-size-20 lora-bold mt-4">
          {{ format(item.total) }}
          {{ content.iqd }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { format } from "/src/assets/numberFormat";

export default {
  inject: ["content"],
  props: ["cart"],
  emits: ["updateQuantity", "removeItem"],
  computed: {
    auth() {
      return this.$store.getters["auth/auth"];
    },
  },
  methods: {
    increaseQty(index) {
        this.$emit("updateQuantity", index, this.cart[index]?.quantity + 1);
    },
    decreaseQty(index) {
      if (this.cart[index].quantity > 1)
        this.$emit("updateQuantity", index, this.cart[index]?.quantity - 1);
      else this.removeItem(index);
    },
    format(number) {
      return format(number);
    },
    removeItem(index) {
      this.$emit("removeItem", index);
    },
  },
};
</script>
