<template>
	<div class="layout-padding mt-8">
		<component
			:is="banner?.link ? 'a' : 'div'"
			:href="banner?.link"
			class="block relative offer-banner overflow-hidden"
			:style="`background:${banner?.bgColor ? banner?.bgColor : '#37328B'};border-radius: 10px;`"
		>
			<img
				:src="banner?.image"
				v-if="banner?.image"
				class="h-full w-full object-contain"
			/>
			<div
				class="bg-gradient-to-b z-[1] layout-padding  flex items-center inset-0 text-base md:text-lg lg:text-xl  to-transparent absolute"
				:style="`color:${banner?.textColor ? banner?.textColor : '#fff'}`"
			>
				{{ banner?.title }}
			</div>
		</component>
	</div>
</template>
<script>
export default {
	computed: {
		banner() {
			return this.$store.getters['pages/banner'];
		},
	},
	mounted() {
		this.$store.dispatch('pages/banner');
	},
};
</script>
