<template>
	<OfferBanner />
	<HomeSlide />
	<WholesaleSection />
	<Category />
	<div class="mt-10 layout-padding">
		<div
			class="refill-section-home grid grid-cols-4 gap-x-15 gap-y-10 py-4 px-8"
			style="background: url(/static/images/home-refill-bg.png)"
		>
			<div
				class="col-span-4 md:col-span-3 flex lora-bold items-center refill-home-text"
			>
				{{ content.homeRefillText }}
			</div>
			<div
				class="col-span-4 md:col-span-1 flex lora-bold md:justify-end items-center justify-center"
			>
				<FillButton :text="content.requestRefill" to="/refill" />
			</div>
		</div>
	</div>
	<TopRatedProducts />
	<OurCollections />
	<MostSellingProducts />
</template>

<script>
import HomeSlide from '/src/components/Home/HomeSlide.vue';
import WholesaleSection from '/src/components/Home/WholesaleSection.vue';
import Category from '/src/components/Home/Category.vue';
import TopRatedProducts from '/src/components/Home/TopRatedProducts.vue';
import MostSellingProducts from '/src/components/Home/MostSellingProducts.vue';

import OurCollections from '/src/components/Home/OurCollections.vue';
import OfferBanner from '../../components/Layout/OfferBanner.vue';

export default {
	components: {
		HomeSlide,
		WholesaleSection,
		Category,
		TopRatedProducts,
		OurCollections,
		MostSellingProducts,
    OfferBanner
	},
	inject: ['content'],
};
</script>
