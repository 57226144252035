import { http } from '../../../graphql'
import gql from 'graphql-tag'

export default {
  packages: async context => {
    let query = gql`
      query subscriptions {
        packages: subscriptions{
          id
          title
          description
          image
          prices {
            id
            months
            price
          }
        }
      }
    `
    const response = await http(true, query, false)
    context.commit('setPackages', response.packages)
  },
  subscribe: async (context, payload) => {
    let mutation = gql`
      mutation subscription ($input: SubscriptionInput!){
        subscription (input: $input){
          id
          token
        }
      }
    `
    let input = {
      subscription: payload.id,
      method: payload.method ?? 'cash',
      address: payload.address,
      gift: payload.gift,
    }
    if (payload.city) input.city = payload.city
    if (payload.sellingPoint) input.selling_point = payload.sellingPoint

    let response = await http(false, mutation, true, { input: input })

    if (payload.method === 'zaincash') {
      window.location.replace(`https://api.zaincash.iq/transaction/pay?id=${response.subscription.token}`)
    } else if (payload.method == 'fastpay') {
      window.location.replace(response.subscription.token)
    } else if (payload.method === 'nasspay') {
      let json = JSON.parse(response.subscription.token)
      let transactionId = json.transactionId
      let token = json.token
      // let user = '7735313620'
      let user = '7517924925'

      window.location.replace(`https://checkout.nasswallet.com/payment-gateway?id=${transactionId}&token=${token}&userIdentifier=${user}`, '_blank')
      // window.open(`https://uatcheckout1.nasswallet.com/payment-gateway?id=${transactionId}&token=${token}&userIdentifier=${user}`, '_blank')
    }
  },
  mySubscriptions: async context => {
    let query = gql`
      query mySubscriptions {
        subs: mySubscriptions{
          id
          package {
            id
            title
            description
            image
          }
          price {
            months
            price
          }
          total
          method
          paid
          gift
          date
          months {
            month
            date
            status
          }
          city {
            id
            name
            price
          }
          status
        }
      }
    `
    const response = await http(true, query, true)
    context.commit('setMySubs', response.subs)
  }
}