<template>
  <div class="pill flex items-center my-1">
    <img :src="image" class="h-5 w-5 rounded-full border border-cardBorder" />
    <p class="ms-2">
      {{ text }}
    </p>
  </div>
</template>

<script>
export default {
  props: ["text", "image"],
};
</script>