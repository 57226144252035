<template>
  <Modal :modalOpened="modalOpened" @close="modalOpened = false">
    <div class="lg:m-18 md:m-6 m-0 flex">
      <div class="shop-mobile-filter-container relative">
        <div class="lora-bold title text-secondaryColor w-full p-6">
          {{ content.filter }}
        </div>
        <Collapse
          :title="content.category"
          :first="true"
          v-if="category?.subCategories?.length > 0"
        >
          <div
            v-for="item in subCategories"
            :key="item.id"
            class="custom-radio flex items-center my-1"
            @click="selectCategory(item.id)"
          >
            <div
              class="radio-rec-fake-check me-2"
              :class="{
                'selected-radio': sections[section].filter.subCategory.includes(item.id),
              }"
            >
              <i class="fas fa-check"></i>
            </div>
            <div class="text">{{ item.title }}</div>
          </div>
        </Collapse>
        <Collapse :title="content.collection">
          <div
            v-for="item in collections"
            :key="item.id"
            class="custom-radio flex items-center my-1"
            @click="selectCollection(item.id)"
          >
            <div
              class="radio-rec-fake-check me-2"
              :class="{
                'selected-radio': sections[section].filter.collection.includes(item.id),
              }"
            >
              <i class="fas fa-check"></i>
            </div>
            <div class="text">{{ item.title }}</div>
          </div>
        </Collapse>
        <Collapse :title="content.size">
          <div
            v-for="item in sizes"
            :key="item.id"
            class="custom-radio flex items-center my-1"
            @click="selectSize(item.id)"
          >
            <div
              class="radio-rec-fake-check me-2"
              :class="{
                'selected-radio': sections[section].filter.size.includes(item.id),
              }"
            >
              <i class="fas fa-check"></i>
            </div>
            <div class="text">{{ item.title }}</div>
          </div>
        </Collapse>
        <Collapse :title="content.color">
          <div
            v-for="item in colors"
            :key="item.id"
            class="custom-radio flex items-center my-1"
            @click="selectColor(item.id)"
          >
            <div
              class="radio-rec-fake-check me-2"
              :class="{
                'selected-radio': sections[section].filter.color.includes(item.id),
              }"
            >
              <i class="fas fa-check"></i>
            </div>
            <div class="text">{{ item.title }}</div>
          </div>
        </Collapse>
      </div>
    </div>
  </Modal>

  <div class="layout-padding relative min-h-96">
    <PageTitle
      class="my-8"
      :title="content.shop"
      :childOne="content.shop"
      :childTwo="content.items"
      routeOne="/shop"
    />
    <div id="shop-items" class="grid grid-cols-4 gap-10 mb-6 xol">
      <div class="col-span-full lg:col-span-3">
        <!-- MENU -->
        <div class="flex items-start justify-between">
          <div class="flex items-center flex-wrap mb-6">
            <Pill
              class="me-4 mb-3"
              :class="{ 'active-pill': section == 'all' }"
              :text="content.all"
              @click="changeTab(null, 'all')"
            />
            <Pill
              class="me-4 mb-3"
              :class="{ 'active-pill': section == 'accessories' }"
              :text="content.accessories"
              @click="changeTab(2, 'accessories')"
            />
            <Pill
              class="me-4 mb-3"
              :class="{ 'active-pill': section == 'candles' }"
              :text="content.candles"
              @click="changeTab(1, 'candles')"
            />
            <Pill
              class="me-4 mb-3"
              :class="{ 'active-pill': section == 'giftsets' }"
              :text="content.giftSets"
              @click="changeTab(4, 'giftsets')"
            />
            <Pill
              class="me-4 mb-3"
              :class="{ 'active-pill': section == 'giftcards' }"
              :text="content.giftCards"
              @click="changeTab(3, 'giftcards')"
            />
            <router-link class="pill me-4 mb-3" to="/personalized-candles">
              {{ content.personalizedCandles }}
            </router-link>
            <router-link class="pill me-4 mb-3" to="/giftsubscription">
              {{ content.giftSubscription }}
            </router-link>
          </div>
          <div
            @click="modalOpened = !modalOpened"
            class="mobile-filter md:hidden text-secondaryColor hover:text-white hover:bg-secondaryColor lora-bold whitespace-nowrap bg-btnColor duration-300 xl:mx-5 mx-3"
          >
            {{ content.filter }}
          </div>
        </div>
        <!-- PRODUCTS -->
        <div
          v-if="loading"
          class="w-full grid gap-y-5 gap-0 sm:gap-5 mb-12 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3"
        >
          <div class="col-span-1" v-for="index in 12" :key="index">
            <ProductCardSkeleton />
          </div>
        </div>
        <p
          class="text-2xl text-center mt-10 text-borderColor"
          v-else-if="products?.products?.length == 0"
        >
          {{ content.noProducts }}
        </p>
        <div
          v-else
          class="w-full grid gap-y-5 gap-0 sm:gap-5 mb-12 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3"
        >
          <div class="col-span-1" v-for="product in products.products" :key="product.id">
            <ProductCard :product="product" />
          </div>
          <div class="col-span-full">
            <Pagination
              v-if="products.pagination.pages != 1"
              :data="products.pagination"
            />
          </div>
        </div>
      </div>
      <div class="lg:block hidden col-span-1">
        <div class="shop-filter-container">
          <div class="lora-bold title text-bluePrimary w-full p-6">
            {{ content.filter }}
          </div>
          <Collapse
            :title="content.category"
            :first="true"
            v-if="category?.subCategories?.length > 0"
          >
            <div
              v-for="item in subCategories"
              :key="item.id"
              class="custom-radio flex items-center my-1"
              @click="selectCategory(item.id)"
            >
              <div
                class="radio-rec-fake-check me-2"
                :class="{
                  'selected-radio': sections[section].filter.subCategory.includes(
                    item.id
                  ),
                }"
              >
                <i class="fas fa-check"></i>
              </div>
              <div class="text">{{ item.title }}</div>
            </div>
          </Collapse>
          <Collapse
            :title="content.collection"
            :first="section == 'all' || section == 'accessories'"
          >
            <div
              v-for="item in collections"
              :key="item.id"
              class="custom-radio flex items-center my-1"
              @click="selectCollection(item.id)"
            >
              <div
                class="radio-rec-fake-check me-2"
                :class="{
                  'selected-radio': sections[section].filter.collection.includes(item.id),
                }"
              >
                <i class="fas fa-check"></i>
              </div>
              <div class="text">{{ item.title }}</div>
            </div>
          </Collapse>
          <Collapse :title="content.size">
            <div
              v-for="item in sizes"
              :key="item.id"
              class="custom-radio flex items-center my-1"
              @click="selectSize(item.id)"
            >
              <div
                class="radio-rec-fake-check me-2"
                :class="{
                  'selected-radio': sections[section].filter.size.includes(item.id),
                }"
              >
                <i class="fas fa-check"></i>
              </div>
              <div class="text">{{ item.title }}</div>
            </div>
          </Collapse>
          <Collapse :title="content.color">
            <div
              v-for="item in colors"
              :key="item.id"
              class="custom-radio flex items-center my-1"
              @click="selectColor(item.id)"
            >
              <div
                class="radio-rec-fake-check me-2"
                :class="{
                  'selected-radio': sections[section].filter.color.includes(item.id),
                }"
              >
                <i class="fas fa-check"></i>
              </div>
              <div class="text">{{ item.title }}</div>
            </div>
          </Collapse>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductCard from "/src/components/ui/Products/ProductCard.vue";
import Pill from "/src/components/ui/Pill.vue";
import Collapse from "/src/components/ui/Collapse/FilterCollapse.vue";
import ProductCardSkeleton from "../../../components/ui/Skeletons/ProductCardSkeleton.vue";
import Pagination from "../../../components/ui/Pagination.vue";

export default {
  components: {
    ProductCard,
    Pill,
    Collapse,
    ProductCardSkeleton,
    Pagination,
  },
  inject: ["content"],
  data() {
    return {
      loading: true,
      section: "all",
      sectionId: null,
      selectedSubCategory: null,
      selectedCollection: null,
      selectedColor: null,
      selectedSize: null,
      modalOpened: false,
      sections: {
        all: {
          action: "products/products",
          filter: {
            collection: [],
            size: [],
            color: [],
            page: 1,
          },
        },
        candles: {
          action: "products/candles",
          filter: {
            category: 1,
            subCategory: [],
            collection: [],
            size: [],
            color: [],
            page: 1,
          },
        },
        accessories: {
          action: "products/accessories",
          filter: {
            category: 2,
            collection: [],
            size: [],
            color: [],
            page: 1,
          },
        },
        giftcards: {
          action: "products/giftCards",
          filter: {
            category: 3,
            subCategory: [],
            collection: [],
            size: [],
            color: [],
            page: 1,
          },
        },
        giftsets: {
          action: "products/giftSets",
          filter: {
            category: 4,
            subCategory: [],
            collection: [],
            size: [],
            color: [],
            page: 1,
          },
        },
      },
      page: 1,
    };
  },
  watch: {
    async [`$route.query`]() {
      this.page = this.$route.query.page;
      await this.loadSection(this.section);
    },
  },
  async mounted() {
    if (this.$route.query.page && this.$route.query.page > 1) {
      this.page = this.$route.query.page;
    }

    let section = this.$route.query.section ?? "all";
    this.section = section;
    switch (section) {
      case "all":
        this.sectionId = null;
        break;
      case "candles":
        this.sectionId = 1;
        break;
      case "accessories":
        this.sectionId = 2;
        break;
      case "giftcards":
        this.sectionId = 3;
        break;
      case "giftsets":
        this.sectionId = 4;
        break;
      default:
        this.sectionId = null;
        break;
    }
    await this.$store.dispatch("categories/categories");
    await this.loadFilters();
    await this.loadSections(section);
    this.loading = false;
  },
  computed: {
    category() {
      return this.$store.getters["categories/categoryId"](this.sectionId);
    },
    subCategories() {
      return this.category?.subCategories;
    },
    collections() {
      return this.$store.getters["collections/collections"];
    },
    sizes() {
      return this.$store.getters["materials/sizes"];
    },
    colors() {
      return this.$store.getters["materials/colors"];
    },
    products() {
      let products =
        this.section == "all"
          ? this.$store.getters["products/products"]
          : this.$store.getters[this.sections[this.section].action];
      return products;
    },
  },
  methods: {
    async loadFilters() {
      if (this.$route.query.category) {
        for (const key in this.sections) {
          if (key != "accessories")
            this.sections[key].filter.subCategory = this.$route.query.category;
        }
      }
      if (this.$route.query.collection) {
        for (const key in this.sections) {
          this.sections[key].filter.collection = this.$route.query.collection;
        }
      }
      if (this.$route.query.size) {
        for (const key in this.sections) {
          this.sections[key].filter.size = this.$route.query.size;
        }
      }
      if (this.$route.query.color) {
        for (const key in this.sections) {
          this.sections[key].filter.color = this.$route.query.color;
        }
      }
    },
    async loadSections(section) {
      await this.$store.dispatch("products/filter", {
        ...this.sections[section].filter,
        page: this.page,
      });
      this.loading = false;
      for (const key in this.sections) {
        if (key != section)
          this.$store.dispatch("products/filter", {
            ...this.sections[key].filter,
            page: this.page,
          });
      }
    },
    async loadSection(section) {
      await this.$store.dispatch("products/filter", {
        ...this.sections[section].filter,
        page: this.page,
      });
    },
    changeTab(id, section) {
      this.sectionId = id;
      this.section = section;
    },
    async selectCategory(id) {
      if (this.sections[this.section].filter.subCategory.includes(id)) {
        this.sections[this.section].filter.subCategory = this.sections[
          this.section
        ].filter.subCategory.filter((item) => item != id);
      } else {
        this.sections[this.section].filter.subCategory.push(id);
      }
      this.page = 1;
      this.loadSection(this.section);
    },
    async selectCollection(id) {
      if (this.sections[this.section].filter.collection.includes(id)) {
        this.sections[this.section].filter.collection = this.sections[
          this.section
        ].filter.collection.filter((item) => item != id);
      } else {
        this.sections[this.section].filter.collection.push(id);
      }
      this.page = 1;
      this.loadSection(this.section);
    },
    async selectSize(id) {
      if (this.sections[this.section].filter.size.includes(id)) {
        this.sections[this.section].filter.size = this.sections[
          this.section
        ].filter.size.filter((item) => item != id);
      } else {
        this.sections[this.section].filter.size.push(id);
      }
      this.page = 1;
      this.loadSection(this.section);
    },
    async selectColor(id) {
      if (this.sections[this.section].filter.color.includes(id)) {
        this.sections[this.section].filter.color = this.sections[
          this.section
        ].filter.color.filter((item) => item != id);
      } else {
        this.sections[this.section].filter.color.push(id);
      }
      this.page = 1;
      this.loadSection(this.section);
    },
  },
};
</script>
