import { http } from '../../../graphql'
import gql from "graphql-tag";

export default {
  projects: async context => {
    let query = gql`
      query projects {
        projects {
          id
          title
          description
          image
          images {
            id
            url
          }
        }
      }
    `
    const response = await http(true, query, false)
    context.commit('setProjects', response.projects)
  },
  project: async (context, payload) => {
    let query = gql`
      query project($id: ID!) {
        project(id: $id){
          id
          title
          description
          image
          images {
            id
            url
          }
        }
      }
    `
    const response = await http(true, query, false, { id: payload })
    context.commit('setProject', response.project)
  }
}