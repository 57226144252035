export default {
  en: {
    lookingForWholesale: "Looking for Wholesale & Collaboration?",
    wholesaleCollaboration: "Wholesale & Collaboration",
    calculatePriceAndOrder: "Calculate Price & Order ",
    followUs: "Follow us",
    signIn: "Sign in",
    dontHaveAnAccount: "Don’t have an account?",
    chooseContainerRefill: "Choose Container Size & Type",
    alreadyHaveAnAccount: "Already have an account?",
    name: "Name",
    password: "Password",
    forgetPassword: "Forget Password ",
    orConnectUsing: "or connect using ",
    signInWithFacebook: "Sign in with Facebook",
    signInWithGoogle: "Sign in with Google",
    account: "Account",
    profile: "Profile",
    register: "Register",
    selectedLanguage: "English",
    language: "language",
    english: "English",
    arabic: "العربية",
    brandSticker: "Add your brand Logo as label sticker (+500 IQD)",
    logo: "logo",
    kurdish: "کوردی",
    or: "or",
    yourAccount: "Your Account",
    myProfile: "My Profile",
    passwordSetting: "Password Settings",
    signout: "Sign out",
    orderHistory: "Order History",
    home: "Home",
    shop: "Shop",
    whiteLabelCandles: "White Label Candles",
    refills: "Refills",
    workshops: "Workshops",
    workshop: "Workshop",
    startDate: "Start Date",
    ticketType: "Ticket Type",
    purchaseTicket: "Purchase Ticket",
    about: "About",
    more: "More",
    shopCandles: "Shop Candles",
    shopGifts: "Shop Gifts",
    shopByCollection: "Shop by Collections ",
    shopAccessories: "Shop Accessories",
    allCollections: "All Collections",
    allAccessories: "All Accessories",
    allCandles: "All Candles",
    WholesaleCollaboration: "Wholesale & Collaboration",
    generateQuote: "Generate Quote",
    orderWhiteLabelCandles: "Order White Label Candles",
    orderWhiteLabelCandlesContent:
      "Provide your requirements to get a price estimate & then order",
    clientsAndProject: "Clients & projects",
    aboutWorkshop: "About the workshops",
    tickets: "Tickets",
    searchForWord: "search for a word",
    aboutUs: "About us",
    whoWeAre: "Who we are",
    readAlso: "Read Also",
    view: "View",
    ourWorkshops: "Our Workshops",
    upcomingWorkshops: "Upcoming Workshops",
    ticketOptions: "Ticket Options ",
    playVideo: "Play Video",
    ourStory: "Our story",
    lalaCandlesFounder: "Lala Candles Founder",
    contactUs: "Contact us",
    moreLinks: "More Links",
    pressAndMentions: "Press and Mentions",
    eventsAndCalendar: "Events and Calendar",
    eventsCalendar: "Events Calendar",
    shopNow: "Shop Now",
    viewCollection: "View Collection",
    shippingPrices: "Shipping Prices",
    city: "City",
    area: "Area / District,",
    save: "Save",
    price: "Price",
    privacyPolicy: "Privacy Policy",
    termsAndConditions: "Terms & Conditions",
    terms: "Terms",
    uploadFile: "Upload File",
    hello: "Hello,",
    select: "Select",
    profileImage: "Profile Image",
    sellingSites: "Points of Sale",
    onlineSellingPoints: "Online selling Points",
    shopByCategory: "Shop by Category",
    signUpAndSave: "Sign up & save",
    signUp: "Sign up",
    soldOut: "Soldout",
    signUpForWorkshop: "Sign up for workshop",
    homeRefillText:
      "Save Money on your next order  by requesting a refill for your existing container!",
    requestRefill: "Request a Refill",
    refillRequest: "Refill Request",
    signUpAndSaveContent:
      "Subscribe to get special offers, news, event information, plus 15% discount on your next purchase ",
    email: "Email",
    subscribe: "Subscribe",
    navigate: "Navigate",
    startSearching:
      "Start searching for candles, gift sets, gift cards, and other items in the website ",
    phone: "Phone",
    agreeTerms: "By Registering, you agree to our ",
    address: "Address",
    topRatedItem: "Top Rated Items",
    mostSellingItems: "Best Selling Items",
    itemSpecifications: "Item Specifications",
    cost: "Cost",
    browseAllItems: "Browse all Items",
    reviews: "Reviews",
    review_s: "Reviews",
    iqd: "IQD",
    ourCollections: "Our Collections",
    viewAllCollections: "View all collections",
    reachUsAt: "Reach us at",
    followUsOnSocialMedia: "Follow us on social media",
    giftSet: "Gift Set",
    giftCards: "Gift Cards",
    giftSubscription: "Gift Subscription",
    personalizedCandles: "Personalized Candles",
    requestPersonalizedCandles: "Request Personalized Candles ",
    personalizedCandleRequest: "Personalized Candle Request ",
    sellingPoints: "Selling Points",
    accountCreationWasntSuccessful: "Account creation was not successful!",
    numberIsUsed: "This number is registered with another account.",
    tryWithDifferentNumber: "Try Again with a different number",
    tryWithDifferentEmail: "Try Again with a different email",
    ourStudio: "Our Studio",
    viewShop: "View Shop",
    location: "Location",
    locationOnMap: "Location On Map",
    container: "Container",
    containerSize: "Container Size",
    scent: "Scent",
    scents: "Scents",
    wick: "Wick",
    color: "Color",
    colorPrice: "Color Price",
    printEstimate: "Print Estimate",
    labelPackage: "Label & Package",
    quantity: "Quantity",
    chooseContainer: "Choose Container",
    chooseScent: "Choose Scent",
    chooseColor: " Choose Color",
    packagingOptions: "Packaging Options",
    packagingOption: "Packaging Option",
    brandedLabel: "Branded Label",
    totalPerItem: "Total per Item",
    wickType: " Wick Type",
    updateYourProfile: "Update your profile",
    updateYourPassword: "Update your Password",
    district: "District",
    updateInformation: "Update Information",
    moreOptions: "More Options ",
    deactivateAccount: "Deactivate Account",
    oldPassword: "Old Password",
    newPassword: "New Password",
    confirmPassword: "Confirm Password",
    updatePassword: "Update Password",
    yourOrderHistory: "Your Order History",
    shopItems: "Shop Items & Refills",
    subscriptions: "Subscriptions",
    personalized: "Personalized",
    date: "Date",
    ticket:"Ticket",
    status: "Status",
    total: "Total",
    pending: "Pending",
    completed: "Completed ",
    rejected: "Rejected",
    cancelled: "Cancelled",
    accepted: "Accepted",
    beingPrepared: "Being Prepared",
    beingDelivered: "Being Delivered",
    readyForPickup: "Ready for Pickup",
    pickup: "Pickup",
    pickupInfo: "Pickup Information",
    contactInformation:"Contact Information",
    pickupLocation: "Pickup Location",
    viewOrder: "View Order",
    order: "Order",
    orderDate: "Order Date",
    items: "Items",
    orderSummery: "Order Summary",
    yourOrderSummery: "Your Order Summary",
    whiteLabelTitle: "Please fill in the bellow fields for us to contact you ",
    whiteLabelNotes: "Write instructions about your order here ",
    qty: "Qty",
    subtotal: "Subtotal",
    delivery: "Delivery",
    deliveryCost: "Delivery Cost",
    payNow: "Pay Now",
    shippingInformation: "Shipping information",
    notes: "Notes",
    yourReviews: "Your Reviews",
    product: "Product",
    products: "Products",
    products_: "Products",
    ratingReview: "Rating & Review ",
    search: "Search",
    resultsWereFoundFor: "Results were found for",
    all: "All",
    submitOrder: "Submit Order",
    checkout: "Checkout",
    useMyAccountShippingAddress: "Use my account address information",
    accessories: "Accessories",
    candles: "Candles",
    giftSets: "Gift Sets",
    filter: "Filter",
    submitRequest: "Submit Request",
    category: "Category",
    collection: "Collection",
    collections: "Collections",
    story: "Story",
    size: "Size",
    inStock: "In Stock",
    outOfStock: "Out of Stock",
    large: "Large",
    medium: "Medium",
    small: "Small",
    colors: "Colors",
    buyNow: "Buy Now",
    addToCart: "Add to Cart",
    customerReviews: "Customer Reviews",
    writeReview: "Write a Review",
    youMightAlsoLike: "You might also like",
    post: "Post",
    yourRating: "Your Rating ",
    yourReview: "Your Review",
    reviewPlaceHolder: "Tell others what you think about this product",
    theCandleSubscription: "The Candle Subscriptions",
    getStarted: "Get Started",
    itsForMe: "It’s for me",
    subscriptionStepOne:
      "Is This Candle Subscription For You Or A Gift For Someone Else?",
    thisIsGift: "This is a gift",
    whichPackageWillItBe: "Which package will it be?",
    selected: "Selected",
    howWouldYouLikeToPay: "How would you like to pay?",
    next: "Next",
    previous: "Previous",
    goToCheckout: "Go to Checkout",
    cart: "Cart",
    addedToCart: "Added to Cart",
    addingToCart: "Adding to Cart",
    addToCartSuccess: "Added to Cart Successfully",
    addToCartFail: "Failed to add item to Cart",
    proceedToCheckout: "Proceed to Checkout",
    summery: "Summary",
    lalaContainers: "Lala Containers",
    nonLalaContainers: "Non-Lala Containers",
    back: "Back",
    chooseWickType: "Choose Wick Type",
    addFiles: "Add Files",
    sendPasswordResetEmail: "Send password reset link",
    new: "New",
    accountVerified: "Account verified successfully",
    accountAlreadyVerified: "This account is already verified",
    accountPending:
      "Your account is pending verification. Please check your email!",
    resendEmail: "Resend email",
    accountVerificationFailed: "Failed to verify your account please try again",
    goHome: "Back to home page",
    attendees: "Attendees",
    month: "Month",
    monthly: "Monthly",
    months: "Months",
    month_s: "Months",
    package: "Package",
    gift: "Gift",
    isThisGift:
      "Is This Candle Subscription For You Or A Gift For Someone Else?",
    step1: "Step 1",
    step2: "Step 2",
    step3: "Step 3",
    yes: "yes",
    no: "no",
    largeFile: "File too large",
    fileType: "File type not supported",
    packageName: "Package Name",
    GiftType: "Gift Type",
    forMe: "It is for me",
    itIsGift: "It is a gift",
    attachment: "Attachment",
    download: "DOWNLOAD",
    image: "Image",
    document_: "Document",
    groupOf: "Group of",
    chooseShipping: "Choose one of the options above before proceeding",
    deleteReviewQ: "Are you sure you want to delete this review?",
    delete: "Delete",
    cancel: "Cancel",
    success: "Success",
    fail: "Fail",
    loading: "LOADING",
    coma: ",",
    requestQuote:
      "Want to have something similar for your business? Reach out to us or request a quote now!",
    quoteSuccess: "Your request has been successfully sent.",
    youWillBeContacted: "You will be contacted as soon as possible.",
    quoteFail: "Failed to send your request, please try again.",
    chooseOption: "Choose one of the options above, please!",
    addLogo: "Add a logo if you want to add a custom label.",
    enterQuantity: "Enter a number please!",
    enterName: "Enter your name please.",
    enterEmail: "Enter your email so we can contact you.",
    enterPhone: "Enter your phone number so we can contact you.",
    chooseCity: "Choose a city please!",
    enterAddress: "Enter delivery address please!",
    chooseLocation: "Choose a pickup location",
    pickColor: "Pick a color",
    pickScent: "Pick a scent",
    orderFail: "Failed to process your order, please try again later.",
    reviewAdded: "You have successfully submited your review.",
    reviewFail: "Failed to submit your review, try again later.",
    reviewDeleted: "You have successfully deleted your review.",
    reviewDeleteFail: "Failed to delete your review.",
    alreadyReviewed: "You have already reviewed this product.",
    subscriptionFail: "An error occured, please try again later!",
    personalizedFail: "Failed to send your request, please try again.",
    requestSuccess: "Your request was successfully sent.",
    orderSuccess: "Your order was successfully sent.",
    newsletterSuccess: "You are now subscribed to our newsletter",
    newsletterFail: "Failed to subscribe to our newsletter",
    checkEmail:
      "Verification email was sent successfully, please check your email.",
    passwordResetSuccess: "Password updated successfully",
    facebook: "Facebook",
    instagram: "Instagram",
    twitter: "Twitter",
    passwordLength: "Password must be 8 or more characters",
    needToSignIn: "You need to be signed in to perform this task",
    needToBeVerified: "Your account needs to be verified",
    inlcuded: "Included",
    notInlcuded: "Not included",
    emailTaken: "This email is already tekan",
    phoneTaken: "This phone is already tekan",
    errorOccured: "An error occured, please try again!",
    nameRequired: "Name is required",
    emailRequired: "Email is required",
    phoneRequired: "Phone is required",
    addressRequired: "Address is required",
    cityRequired: "City is required",
    passwordRequired: "Password is required",
    acceptPrice: "Accept Price",
    description: "Description",
    priceAccepted: "Price accepted",
    copyrightPre: "Copyright",
    copyright: "Lala Candles - Website by",
    submit: "Submit",
    phoneInvalid: "Phone number is invalid",
    chooseMethod: "Choose a payment method",
    paymentMethod: "Payment Method",
    cash: "Cash",
    nasswallet: "Nass Wallet",
    zaincash: "ZainCash",
    fastpay: "FastPay",
    noDrop: "There are no drop-off locations available in your city",
    paymentSuccess: "Payment Successful",
    paymentFail: "Payment Failed",
    paid: "Paid",
    completeRegistration: "Complete Registration",
    addNumber: "Add Number",
    addLater: "Add Later",
    emailFormat: "Wrong Email Format.",
    required: "This field is required",
    gender: "Gender",
    chooseGender: "Select you gender",
    male: "Male",
    female: "Female",
    preferNotToSay: "Prefer not to say",
    dob: "Date of birth",
    dobRule: "You must be 18 years or older to register",
    cartIsEmpty: "Your Cart is Empty.",
    noOrders: "You have not made any orders.",
    noProducts: "There are no products of this type.",
    pageNotFound: "Page Not Found",
    badRequest: "Bad Request",
    tryAgainLater: "Try Again Later.",
    booked: "Booked",
    moreThanStock:
      "The quantity you are trying to order is more than the stock available.",
    loadMore: "Load More",
    currentlyUnavailable: "Currently unavailable."
  },
  ar: {
    lookingForWholesale: "هل تبحث عن البيع بالجملة والتعاون؟",
    wholesaleCollaboration: "البيع بالجملة والتعاون",
    calculatePriceAndOrder: "حساب السعر و طلب",
    followUs: "تابعنا",
    signIn: "تسجيل الدخول",
    dontHaveAnAccount: "ليس لديك حساب؟",
    alreadyHaveAnAccount: "هل لديك حساب؟",
    chooseContainerRefill: "اختر حجم الحاوية ونوعها",
    name: "اسم",
    password: "كلمه السر",
    forgetPassword: "نسيت كلمة المرور",
    orConnectUsing: "أو الاتصال باستخدام ",
    signInWithFacebook: "Sign in with Facebook",
    signInWithGoogle: "Sign in with Google",
    account: "حساب",
    profile: "الملف الشخصي",
    register: "تسجيل",
    selectedLanguage: "عربي",
    language: "لغة",
    english: "English",
    arabic: "العربية",
    brandSticker: "اضف شعار علامتك التجارية كملصق (+500 دينار عراقي)",
    logo: "لوجو",
    kurdish: "کوردی",
    or: "أو",
    yourAccount: "الحساب الخاص بك",
    myProfile: "ملفي شخصي",
    passwordSetting: "ضبط كلمة المرور",
    signout: "خروج",
    orderHistory: "تاريخ الطلب",
    home: "الصفحة الرئيسية",
    shop: "محل",
    whiteLabelCandles: "شموع بالملصق الخاص",
    refills: "إعادة تعبئة",
    workshops: "ورش عمل",
    workshop: "ورش عمل",
    startDate: "تاريخ البدء",
    ticketType: "نوع التذكرة",
    purchaseTicket: "شراء تذكرة",
    about: "معلومات عنا",
    more: "أكثر",
    shopCandles: "تسوق  الشموع",
    shopGifts: "تسوق الهدايا",
    shopByCollection: "تسوق حسب المجموعات ",
    shopAccessories: "متجر الملحقات",
    allCollections: "كل المجموعات",
    allAccessories: "جميع الملحقات",
    allCandles: "كل الشموع",
    WholesaleCollaboration: "البيع بالجملة والتعاون",
    generateQuote: "عرض السعر",
    orderWhiteLabelCandles: "اطلب الشمع بملصقك الخاص",
    orderWhiteLabelCandlesContent:
      "قدم متطلباتك للحصول على تقدير للسعر ثم اطلب",
    clientsAndProject: "العملاء والمشاريع",
    aboutWorkshop: "حول ورش العمل",
    tickets: "تذاكر",
    searchForWord: "ابحث عن کلمة",
    aboutUs: "معلومات عنا",
    whoWeAre: "من نحن",
    readAlso: "اقرأ أيضا",
    view: "رؤية",
    ourWorkshops: "ورش العمل لدينا",
    upcomingWorkshops: "ورش العمل القادمة",
    ticketOptions: "خيارات التذاكر",
    playVideo: "شغل الفيديو",
    ourStory: "قصتنا",
    lalaCandlesFounder: "مؤسس Lala Candles",
    contactUs: "اتصل بنا",
    moreLinks: "المزيد من الروابط",
    pressAndMentions: "الصحافة و التذكير",
    eventsAndCalendar: "الأحداث والتقويم",
    eventsCalendar: "تقويم الأحداث",
    shopNow: "تسوق الآن",
    viewCollection: "مشاهدة المجموعة",
    shippingPrices: "أسعار الشحن",
    city: "مدينة",
    area: "المنطقة / الحي ،",
    save: "احفظ",
    price: "سعر",
    privacyPolicy: "سياسة الخصوصية",
    termsAndConditions: "البنود و الظروف",
    terms: "شرو‌ط",
    uploadFile: "رفع ملف",
    hello: "مرحبا,",
    select: "تحديد",
    profileImage: "الصوره الشخصيه",
    sellingSites: "نقاط البيع",
    onlineSellingPoints: "نقاط البيع عبر الإنترنت",
    shopByCategory: "تسوق حسب الاقسام",
    signUpAndSave: "سجل وحفظ",
    signUp: "اشتراك",
    soldOut: "نفذ",
    signUpForWorkshop: "اشترك في ورشة العمل",
    homeRefillText:
      "وفر أموالك في طلبك التالي عن طريق طلب إعادة تعبئة الحاوية الموجودة لديك!",
    requestRefill: "طلب إعادة التعبئة",
    refillRequest: "إعادة التعبئة",
    signUpAndSaveContent:
      "اشترك للحصول على عروض خاصة وأخبار ومعلومات عن الأحداث بالإضافة إلى خصم 15٪ على عملية الشراء التالية",
    email: "بريد الالكتروني",
    subscribe: "الإشتراك",
    navigate: "التنقل",
    startSearching:
      "ابدأ البحث عن الشموع ومجموعات الهدايا وبطاقات الهدايا والعناصر الأخرى في موقع الويب",
    phone: "هاتف",
    agreeTerms: "بالتسجيل ، فإنك توافق على",
    address: "عنوان",
    topRatedItem: "المنتجات الأعلى تقييما",
    mostSellingItems: "المنتجات الأكثر مبيعًا",
    itemSpecifications: "مواصفات العنصر",
    cost: "كلفة",
    browseAllItems: "تصفح جميع العناصر",
    reviews: "المراجعات",
    review_s: "مراجعات",
    iqd: "د.ع",
    ourCollections: "مجموعاتنا",
    viewAllCollections: "عرض كل المجموعات",
    reachUsAt: "تصل بنا على",
    followUsOnSocialMedia: "تابعنا على وسائل التواصل الإجتماعي",
    giftSet: "باقات الهدايا",
    giftCards: "بطاقات الهدايا",
    giftSubscription: "اشتراك الهدايا",
    personalizedCandles: "شموع شخصية",
    requestPersonalizedCandles: "طلب شموع مخصصة",
    personalizedCandleRequest: "طلب شمعة شخصية",
    sellingPoints: "نقاط البیع",
    accountCreationWasntSuccessful: "لم يكن إنشاء الحساب ناجحًا!",
    numberIsUsed: "هذا الرقم مسجل في حساب آخر.",
    tryWithDifferentEmail: "حاول مرة أخرى برید الکترونی مختلف",
    tryWithDifferentNumber: "حاول مرة أخرى برقم مختلف",
    ourStudio: "الاستوديو الخاص بنا",
    viewShop: "مشاهدة المتجر",
    location: "موقع",
    locationOnMap: "الموقع على الخريطة",
    container: "وعاء",
    containerSize: "حجم الوعاء",
    scent: "رائحة",
    scents: "رائحة",
    wick: "فتيلة",
    color: "اللون",
    colorPrice: "اللون",
    printEstimate: "تقدير الطباعة",
    labelPackage: "التسمية والحزمة",
    quantity: "كمية",
    chooseContainer: "اختر وعاء",
    chooseScent: "اختر رائحة",
    chooseColor: " اختر اللون",
    packagingOptions: "خيارات التغليف",
    packagingOption: "خيار التغليف",
    brandedLabel: "تسمية ذات علامة تجارية",
    totalPerItem: "الإجمالي لكل عنصر",
    wickType: "نوع الفتيل",
    updateYourProfile: "تحديث ملفك الشخصي",
    updateYourPassword: "قم بتحديث كلمة المرور الخاصة بك",
    district: "منطقة",
    updateInformation: "تحديث المعلومات",
    moreOptions: "المزيد من الخيارات",
    deactivateAccount: "تعطيل الحساب",
    oldPassword: "كلمة سر قديمة",
    newPassword: "كلمة مرور جديدة",
    confirmPassword: "تأكيد كلمة المرور",
    updatePassword: "تطوير كلمة السر",
    yourOrderHistory: "تاريخ الطلباتک ",
    shopItems: "تسوق العناصر وإعادة التعبئة",
    subscriptions: "الاشتراكات",
    personalized: "شخصية",
    ticket:"تکت",
    date: "تاريخ",
    status: "وضع",
    total: "المجموع",
    pending: "قيد الانتظار",
    completed: "مكتمل ",
    rejected: "مرفوض",
    cancelled: "ألغيت",
    accepted: "وافقت",
    beingPrepared: "ان تكون جاهزا",
    beingDelivered: "يجري تسليمها",
    readyForPickup: "جاهز للإستلام",
    pickup: "الالتقاط",
    pickupInfo: "معلومات الالتقاط",
    contactInformation:"معلومات اتصال",
    pickupLocation: "موقع الالتقاط",
    viewOrder: "مشاهدة الطلب",
    order: "طلب",
    orderDate: "تاريخ الطلب",
    items: "العناصر",
    orderSummery: "ملخص الطلب",
    yourOrderSummery: "ملخص طلبك",
    whiteLabelTitle: "يرجى ملء الحقول أدناه حتى يتسنى لنا الاتصال بك",
    whiteLabelNotes: "اكتب تعليمات حول طلبك هنا",
    qty: "الكمية",
    subtotal: "المجموع الفرعي",
    delivery: "توصيل",
    deliveryCost: "تكلفة التوصيل",
    payNow: "ادفع الآن",
    shippingInformation: "معلومات الشحن",
    notes: "ملحوظات",
    yourReviews: "تعليقاتك",
    product: "المنتج",
    products: "المنتجات",
    products_: "منتجات",
    ratingReview: "التقييم والمراجعة ",
    search: "بحث",
    resultsWereFoundFor: "تم العثور على نتائج ل",
    all: "الجميع",
    submitOrder: "تقديم الطلب",
    checkout: "الدفع",
    useMyAccountShippingAddress: "استخدام معلومات عنوان حسابي",
    accessories: "مستلزمات",
    candles: "شموع",
    giftSets: "طقم هدايا",
    filter: "فلتر",
    submitRequest: "تقديم الطلب",
    category: "فئة",
    collection: "مجموعة",
    collections: "المجموعات",
    story: "قصة",
    size: "مقاس",
    inStock: "مخزون معد للبيع",
    outOfStock: "غير متوفر",
    large: "كبير",
    medium: "وسط",
    small: "صغیر",
    colors: "الألوان",
    buyNow: "اشتري الآن",
    addToCart: "أضف إلى السلة",
    customerReviews: "آراء العملاء",
    writeReview: "أكتب مراجعة",
    youMightAlsoLike: "قد يعجبك ايضا",
    post: "نشر",
    yourRating: "تقييمك ",
    yourReview: "مراجعتك",
    reviewPlaceHolder: "أخبر الآخرين عن رأيك في هذا المنتج",
    theCandleSubscription: "اشتراكات الشمعة",
    getStarted: "البدء",
    itsForMe: "أنه لي",
    subscriptionStepOne: "هل اشتراك الشمعة هذا لك أم هدية لشخص آخر؟",
    thisIsGift: "هذه هدية",
    whichPackageWillItBe: "أي حزمة ستكون؟",
    selected: "المحدد",
    howWouldYouLikeToPay: "كيف تريد الدفع؟",
    next: "التالي",
    previous: "السابق",
    goToCheckout: "الدفع",
    cart: "عربة التسوق",
    addedToCart: "تمت إضافة المنتج إلى السلة",
    addingToCart: "جاري الاضافة إلى السلة",
    addToCartSuccess: "تمت إضافة المنتج إلى السلة بنجاح",
    addToCartFail: "فشل في إضافة المنتج إلى السلة",
    proceedToCheckout: "الدفع",
    summery: "ملخص",
    lalaContainers: "وعاء لالة",
    nonLalaContainers: "وعاء غير لالة",
    back: "رجوع",
    chooseWickType: "اختر نوع الفتيل",
    addFiles: "إضافة ملفات",
    sendPasswordResetEmail: "إرسال رابط إعادة تعيين كلمة السر",
    new: "جديد",
    accountVerified: "تم التحقق من الحساب بنجاح",
    accountAlreadyVerified: "تم التحقق من هذا الحساب بالفعل",
    accountPending:
      "  حسابك في الانتظار للتاكيد, ارجوا التحقق من بريدك الاكتروني",
    resendEmail: "إعادة إرسال البريد الإلكتروني",
    accountVerificationFailed:
      " فشل في التحقق من حسابك الرجاء المحاولة مرة أخرى",
    goHome: "الرجوع الى الصفحة الرئيسية",
    attendees: "الحضور",
    month: "شهر",
    monthly: "شهريا",
    months: "أشهر",
    month_s: "أشهر",
    package: "باقة",
    gift: "هدية",
    isThisGift: "هل هذا الاشتراك لك ام هدية ؟",
    step1: "الخطوة ١",
    step2: "الخطوة ٢",
    step3: "الخطوة ٣",
    yes: "نعم",
    no: "لا",
    largeFile: "الملف كبير جدا",
    fileType: " نوع الملف غير معتمد",
    packageName: "اسم الباقة",
    GiftType: "نوع الهدية",
    forMe: "انه لي",
    itIsGift: "انها هدية",
    attachment: "مرفق",
    download: "تنزيل",
    image: "صورة",
    document_: " مستند ",
    groupOf: "مجموعة من",
    chooseShipping: " اختر أحد الخيارات أعلاه قبل المتابعة ",
    deleteReviewQ: "هل أنت متأكد أنك تريد حذف هذه المراجعة؟",
    delete: "حذف",
    cancel: "الغاء",
    success: "Success",
    fail: "Fail",
    loading: "جار التحميل",
    coma: "،",
    requestQuote:
      "هل تريد أن يكون لديك شيء مماثل لعملك؟ تواصل معنا أو اطلب عرض أسعار الآن!",
    quoteSuccess: " تم إرسال طلبك بنجاح.",
    youWillBeContacted: " سيتم الاتصال بك في أقرب وقت ممكن.",
    quoteFail: " فشل إرسال طلبك، الرجاء المحاولة مرة أخرى.",
    chooseOption: " اختر أحد الخيارات أعلاه، من فضلك!",
    addLogo: " إضافة شعارك إذا كنت تريد إضافة تسمية مخصصة.",
    enterQuantity: " أدخل رقم من فضلك!",
    enterName: " أدخل اسمك من فضلك.",
    enterEmail: " أدخل بريدك الإلكتروني حتى نتمكن من الاتصال بك.",
    enterPhone: " أدخل رقم هاتفك حتى نتمكن من الاتصال بك.",
    chooseCity: " اختر مدينة من فضلك!",
    enterAddress: " أدخل عنوان التسليم من فضلك!",
    chooseLocation: " اختيار موقع التقاط ",
    pickColor: " اختر الون ",
    pickScent: " اختر رائحة ",
    orderFail: " فشل في معالجة طلبك، الرجاء المحاولة مرة أخرى لاحقا.",
    reviewAdded: " لقد قمت بإرسال مراجعتك بنجاح.",
    reviewFail: " فشل في إرسال المراجعة، حاول مرة أخرى لاحقا.",
    reviewDeleted: " لقد قمت بحذف مراجعتك بنجاح.",
    reviewDeleteFail: " فشل حذف المراجعة.",
    alreadyReviewed: "لقد قمت بالفعل بمراجعة هذا المنتج.",
    subscriptionFail: " حدث خطأ، الرجاء المحاولة مرة أخرى لاحقا!",
    personalizedFail: " فشل إرسال طلبك، الرجاء المحاولة مرة أخرى.",
    requestSuccess: " تم إرسال طلبك بنجاح.",
    orderSuccess: " تم إرسال طلبك بنجاح.",
    newsletterSuccess: " أنت الآن مشترك في نشرتنا الإخبارية ",
    newsletterFail: " فشل الاشتراك في نشرتنا الإخبارية ",
    checkEmail:
      "تم إرسال رسالة التحقق بنجاح ، يرجى التحقق من بريدك الإلكتروني.",
    passwordResetSuccess: "تم تحديث كلمة المرور بنجاح",
    facebook: "Facebook",
    instagram: "Instagram",
    twitter: "Twitter",
    passwordLength: " يجب أن تكون كلمة المرور 8 أحرف أو أكثر ",
    needToSignIn: " يجب أن يتم تسجيل الدخول لتنفيذ هذه المهمة ",
    needToBeVerified: " يجب التحقق من حسابك ",
    inlcuded: "مشمول ",
    notInlcuded: "غير مشمول",
    emailTaken: " تم تسجيل هذا البريد الإلكتروني من قبل ",
    phoneTaken: " تم استعمال هذا الرقم من قبل ",
    errorOccured: " حدث خطأ، الرجاء المحاولة مرة أخرى!",
    nameRequired: " الاسم مطلوب ",
    emailRequired: " البريد الإلكتروني مطلوب ",
    phoneRequired: " الهاتف مطلوب ",
    addressRequired: "عنوان مطلوب",
    cityRequired: " المدينة مطلوبة ",
    passwordRequired: " كلمة المرور مطلوبة ",
    acceptPrice: " قبول السعر ",
    description: "وصف",
    priceAccepted: " تم قبول السعر ",
    copyrightPre: "حقوق النشر",
    copyright: "لالة كاندلز - الموقع بواسطة",
    submit: " إرسال ",
    phoneInvalid: " رقم الهاتف غير صالح ",
    chooseMethod: " اختيار طريقة الدفع ",
    paymentMethod: "طريقة الدفع",
    cash: "نقد",
    nasswallet: "ناس والت",
    zaincash: "زین کاش",
    fastpay: "فاستبي",
    noDrop: " لا توجد مواقع للتحميل او الانزال متوفرة في مدينتك ",
    paymentSuccess: "تم الدفع بنجاح",
    paymentFail: " فشل في الدفع ",
    paid: "مدفوع",
    completeRegistration: "اكمل التسجيل",
    addNumber: " إضافة رقم ",
    addLater: "اضف لاحقا",
    emailFormat: "تنسيق بريد إلكتروني خاطئ.",
    required: "هذه الخانة مطلوبه",
    gender: "جنس",
    chooseGender: "اختر جنسك",
    male: "ذكر",
    female: "أنثى",
    preferNotToSay: "افضل عدم القول",
    dob: "تاريخ الولادة",
    dobRule: "يجب أن يكون عمرك 18 عامًا أو أكثر للتسجيل",
    cartIsEmpty: "سلة الشراء فارغة",
    noOrders: "لم تقم بإجراء أي طلبات.",
    noProducts: "لا يوجد منتجات من هذا النوع.",
    pageNotFound: "الصفحة غير موجودة",
    badRequest: "خطأ في الارسال",
    tryAgainLater: "الرجاء المحاولة مرة أخرى",
    booked: "تم الحجز",
    moreThanStock: "الكمية المطلوبة أكبر من المتوفرة",
    loadMore: "تحميل المزيد",
    currentlyUnavailable: "غير متاح حاليا."
  },
  ku: {
    lookingForWholesale: "بە دوای کڕینی  کۆ و پێکەوە کاردن دەکەی ؟",
    wholesaleCollaboration: "کرینی کۆ و پێکەوە کارکردن",
    calculatePriceAndOrder: "ژماردنی نرخ و داواکردن ",
    followUs: "فۆڵۆومان بکە",
    signIn: "چوونەژورەوە",
    dontHaveAnAccount: "هەژمارت نیە ؟",
    alreadyHaveAnAccount: "‌هەژمارت هەیە ؟",
    chooseContainerRefill: "جۆری دەفرو قەبارەکەی هەڵبژێرە",
    name: "ناو",
    password: "وشەی نهێنی",
    forgetPassword: "وشەی نهێنیم لە بیرکردوە",
    orConnectUsing: "یان بەردەوام بە لە رێگەی",
    signInWithFacebook: "Sign in with Facebook",
    signInWithGoogle: "Sign in with Google",
    account: "هەژمار",
    profile: "پڕۆفایل",
    register: "خۆتۆمارکردن",
    selectedLanguage: "کوردی",
    language: "زمان",
    english: "English",
    arabic: "العربية",
    logo: "لۆگۆ",
    brandSticker: "زیادکردنی لۆگۆی براندەکەت وەک لکێنەری پێناسە (+500 IQD)",
    kurdish: "کوردی",
    or: "یان",
    yourAccount: "هەژمارەکەت",
    myProfile: "پرۆفایلەکەم",
    passwordSetting: "ڕێکخستنی وشەی نهێنی",
    signout: "چوونەدەرەوە",
    orderHistory: "تۆماری داواکاری",
    home: "سەرەتا",
    shop: "بازاڕ",
    whiteLabelCandles: "مۆمی لەیبڵی سپی",
    refills: "پڕکردنەوە",
    workshops: " وۆرکشۆپەکان",
    workshop: " وۆرکشۆپەکان ",
    startDate: "بەرواری دەستپێکردن",
    ticketType: "جۆری تیکت",
    purchaseTicket: "کڕینی تیکت",
    about: "دەربارە",
    more: "زیاتر",
    shopCandles: "كڕینی مۆم",
    shopGifts: "کڕینی دیاری",
    shopByCollection: "بازاڕکردن بە پێی کۆکراوەکان",
    shopAccessories: "کڕینی ئێکسسوارەکان",
    allCollections: "هەموو کۆکراوەکان",
    allAccessories: "هەموو ئێکسسوارەکان",
    allCandles: "هەموو مۆمەکان",
    WholesaleCollaboration: " کرینی کۆ و پێکەوە کارکردن ",
    generateQuote: "پشاندانی نرخ",
    orderWhiteLabelCandles: "مۆمی لەیبڵی سپی داوا بکە",
    orderWhiteLabelCandlesContent:
      "دواکاریەکانت بنێرە نرخەکەت بۆ ئەخەمڵینین و دواکاری بنێرە",
    clientsAndProject: " کڕیارەکان & پرۆژەکان",
    aboutWorkshop: " سەبارەت بە وۆرکشۆپەکان ",
    tickets: "تیکتەکان",
    searchForWord: "بە دوای وشەیەکدا بگەڕێ",
    aboutUs: "دەربارەی ئێمە",
    whoWeAre: "ئێمە کێین",
    readAlso: "خوێنەری ئەمانەش بە",
    view: "بینین",
    ourWorkshops: "وۆرکشۆپەکانمان",
    upcomingWorkshops: " وۆرکشۆپەکانی داهاتوو ",
    ticketOptions: "هەڵبژاردەی تیکتەکان",
    playVideo: " ڤیدیۆ پەخش بکە ",
    ourStory: "چیرۆکەکەمان",
    lalaCandlesFounder: "دامەزرێنەری لالە کاندڵز",
    contactUs: "پەیوەندیمان پێوەبکە",
    moreLinks: "بەستەری زیاتر",
    pressAndMentions: "هەواڵ",
    eventsAndCalendar: "ڕووداوەکان و ڕۆژژمێر",
    eventsCalendar: "ڕۆژژمێری ڕووداوەکان",
    shopNow: "بازاڕ بکە",
    viewCollection: "بینینی کۆکراوە",
    shippingPrices: "نرخەکانی گەیاندن",
    city: "شار",
    save: "پاشەکەوتی",
    area: "ناوچە / گەڕەک,",
    price: "نرخ",
    privacyPolicy: "زانیاری تایبەتی کەسە",
    termsAndConditions: "مەرجەکانی بەکارهێنان",
    terms: "مەرجەکان",
    uploadFile: "بەرزکردنەوەی فایل",
    hello: "سڵاو,",
    select: "دیارکردن",
    profileImage: "وێنەی هەژمار",
    sellingSites: "خاڵەکانی فرۆشتن",
    onlineSellingPoints: "فرۆشگا ئۆنڵاینەکان",
    shopByCategory: "بازاڕکردن بە پێی پۆل",
    signUpAndSave: "خۆتۆمارکردن و پاشەکەوتکردن",
    signUp: "خۆتۆمارکردن",
    soldOut: "فرۆشراوە",
    signUpForWorkshop: " ناوت تۆمار بکە بۆ وۆرکشۆپ ",
    homeRefillText:
      "پارە پاشەکەوت بکە بۆ داواکاری داهاتووت بە پڕکردنەوەی دەفرە بەتاڵەکەت",
    requestRefill: "داواکاری پڕکردنەوە",
    refillRequest: "پڕکردنەوە",
    signUpAndSaveContent:
      "سەبسکرایب بکە بۆ ئەوەی هەواڵ و داشکاندنەکانت پێ بگات وە ١٥% داشکاندن بە دەستبێنیت لە داواکاری داهاتووت",
    email: "ئیمەڵ",
    subscribe: "سەبسکرایب",
    navigate: "بەستەرەکان",
    startSearching:
      "دەست بکە بە گەڕان بەدوای مۆم و کۆمەڵە دیاریەکان و کارتی دیاری و کاڵاکانی تر لە وێبسایتەکە ",
    phone: "موبایل",
    agreeTerms: "بە خۆتمارکردن ، ڕازی دەبیت بە ",
    address: "ناونیشان",
    topRatedItem: "بەرهەمە پلە بەرزەکان",
    mostSellingItems: "باشترین فرۆشراوەکان",
    itemSpecifications: "تایبەتمەندی",
    cost: "تێچوو",
    browseAllItems: "هەموو بەرهەمەکان",
    reviews: "پێداچوونەوەکان",
    review_s: "پێداچوونەوە",
    iqd: "د.ع",
    ourCollections: "کۆکراوەکانمان",
    viewAllCollections: "بینینی هەموو کۆکراوەکان",
    reachUsAt: "بە ئێمە بگە",
    followUsOnSocialMedia: "فۆڵومان بکە لە سۆشیاڵ میدیا",
    giftSet: "سێتی دیاری",
    giftCards: "کارتی دیاری",
    giftSubscription: "بەشداری دیاری",
    personalizedCandles: "مۆمی تایبەت",
    requestPersonalizedCandles: "داواکردنی مۆمی تایبەت",
    personalizedCandleRequest: " داواکاری مۆمی تایبەت ",
    sellingPoints: "خاڵەکانی فرۆشتن",
    accountCreationWasntSuccessful: "دروستکردنی هەژمارەکە سەرکەوتوو نەبوو!",
    numberIsUsed: "ئەم ژمارەیە پێشتر خۆی تۆمارکردووە.",
    tryWithDifferentEmail: "بە ئیمەیڵێکی جیاواز هەوڵ بدە",
    tryWithDifferentNumber: "بە ژمارەیەکی جیاواز هەوڵ بدە",
    ourStudio: "ستۆدیۆکەمان",
    viewShop: "بینینی بازاڕ",
    location: "ناونیشان",
    locationOnMap: "شوێن لەسەر نەخشە",
    container: "دەفر",
    containerSize: "دەفر",
    scent: "بۆن",
    scents: "بۆن",
    wick: "داو",
    color: "ڕەنگ",
    colorPrice: "ڕەنگ",
    printEstimate: "چاپکردن",
    labelPackage: "لەیبڵ و پێچانەوە",
    quantity: "چەندێتی",
    chooseContainer: "دەفرێک هەڵبژێرە",
    chooseScent: "بۆنێک هەڵبژێرە",
    chooseColor: " ڕەنگێک هەڵبژێرە",
    packagingOptions: " هەڵبژاردنەکانی پێچانەوە ",
    packagingOption: " هەڵبژاردنەکانی پێچانەوە ",
    brandedLabel: " لەیبڵی براندکراو ",
    totalPerItem: "نرخی هەر دانەیەک",
    wickType: " جۆری داو",
    updateYourProfile: "پڕۆفایلەکەت ئەپدەیت بکە",
    updateYourPassword: "وشەی نهێنیەکەت ئەپدەیت بکە",
    district: "گەڕەک",
    updateInformation: "نوێکردنەوەی زانیاری",
    moreOptions: "هەڵبژاردنی زیاتر",
    deactivateAccount: "ڕاوەستاندی هەژمار",
    oldPassword: "وشەی نهێنی کۆن",
    newPassword: "وشەی نهێنی نوێ",
    confirmPassword: "دڵنیایی وشەی نهێنی",
    updatePassword: "نوێکردنەوەی وشەی نهێنی",
    yourOrderHistory: "تۆماری داواکاریەکانت",
    shopItems: "کڕینی بەرهەمەکان و پڕکردنەوە",
    subscriptions: "بەشداریکردنەکان",
    personalized: "تایبەت",
    ticket:"تکت",
    date: "بەروار",
    status: "دۆخ",
    total: "کۆی گشتی",
    pending: "چاوەڕوان",
    completed: "تەواوبووە ",
    rejected: "ڕەتکراوەتەوە",
    cancelled: "هەڵوەشاوەتەوە",
    accepted: "وەرگیراوە",
    beingPrepared: "ئامادەکراوە",
    beingDelivered: "ئامادەکراوە بۆ گەیاندن",
    readyForPickup: "ئامادەیە بۆ وەرگرتن",
    pickup: "هەڵگرتن",
    pickupInfo: "زانیاری هەڵگرتن",
    contactInformation:"زانیاری پەیوەندی",
    pickupLocation: "ناونیشانی هەڵگرتن",
    viewOrder: "بینینی داواکراری",
    order: "داواکاری",
    orderDate: "بەرواری داواکاری",
    items: "بەرهەمەکان",
    orderSummery: "پوختەی داواکاری ",
    yourOrderSummery: "پوختەی داواکاریەکەت",
    whiteLabelTitle:
      "خانەکانی خوارەوە پڕبکەوەرە بۆ ئەوەی بتوانین پەیوەندیت پێوە بکەین",
    whiteLabelNotes: "زانیاری بنووسە دەربارەی دواکاریەکەت",
    qty: "دانە",
    subtotal: "کۆی گشتی",
    delivery: "گەیاندن",

    deliveryCost: "تێجووی گەیاندن",
    payNow: "پارەدان",
    shippingInformation: "زانیاری گواستنەوە",
    notes: "تێبینی",
    yourReviews: "پێداچوونەوەکانت",
    product: "بەرهەم",
    products: "بەرهەمەکان",
    products_: "بەرهەمەکانی",
    ratingReview: "هەڵسەنگاندن و پێداچوونەوە",
    search: "گەڕان",
    resultsWereFoundFor: "ئەنجام دۆزرایەوە بۆ",
    all: "هەمووی",
    submitOrder: "ناردنی داواکاری",
    checkout: "پارەدان",
    useMyAccountShippingAddress: "ناونیشانی هەژمارەکەم بەکاربهێنە",
    accessories: "ئەکسسوارەکان",
    candles: "مۆم",
    giftSets: "سێتی دیاری",
    filter: "پاڵاوتن",
    submitRequest: "ناردنی داواکاری",
    category: "پۆل",
    collection: "کۆکراوە",
    collections: "کۆکراوەکان",
    story: "چیرۆک",
    size: "قەبارە",
    inStock: "لە کۆگادایە",
    outOfStock: "نەماوە",
    large: "گەورە",
    medium: "مامناوەند",
    small: "بچووک",
    colors: "ڕەنگەکان",
    buyNow: "کڕین",
    addToCart: "زیادکردن بۆ سەبەتە",
    customerReviews: "پێداچوونەوەی بەکارهێنەرەکان",
    writeReview: "پێداچوونەوەیەک بنووسە",
    youMightAlsoLike: "لەوانەیە حەزت لەمانەش بێت",
    post: "دانان",
    yourRating: "هەڵسەنگاندنەکەت",
    yourReview: "پێداچوونەوەکەت",
    reviewPlaceHolder: "ڕای خۆت بڵی لەسەر ئەم بەرهەمە",
    theCandleSubscription: "سەبسکریبشنی مۆمەکان",
    getStarted: "دەستپێکردن",
    itsForMe: "بۆ خۆم",
    subscriptionStepOne: "ئایە ئەمە بۆ دیاریە یان بۆ خۆتە?",
    thisIsGift: "ئەمە دیاریە",
    whichPackageWillItBe: "کام لەم پاکێجانە بێت؟",
    selected: "هەڵبژێردراو",
    howWouldYouLikeToPay: "حەزئەکەی چۆن پارەبەی?",
    next: "دواتر",
    previous: "پێشووتر",
    goToCheckout: "پارەدان",
    cart: "سەبەتە",
    addedToCart: "زیادکرا بۆ سەبەتە",
    addingToCart: "زیاد دەکرێت بۆ سەبەتە",
    addToCartSuccess: "بە سەرکەوتوویی زیادکرا بۆ سەبەتە",
    addToCartFail: "زیادکردن بۆ سەبەتە سەرکەوتوو نەبوو",
    proceedToCheckout: "پارەدان",
    summery: "پوختە",
    lalaContainers: "دەفری لالە",
    nonLalaContainers: "دەفری لالە نیە",
    back: "گەڕانەوە",
    chooseWickType: " جۆری داو هەڵبژێرە ",
    addFiles: "دانانی فایل",
    sendPasswordResetEmail: "ناردنی لینک  ",
    new: "نوێ",
    accountVerified: " ئەژمێرەکە بە سەرکەوتوویی دووپاتکراوەتەوە ",
    accountAlreadyVerified: " ئەم ئەژمێرە پێشتر پشتڕاستکراوەتەوە ",
    accountPending:
      " ئەژمێرەکەت هەڵواسراوە بۆ سەلماندن. تکایە ئیمەیلەکەت بپشکنە!",
    resendEmail: "دووبارە ئیمەیڵ بنێرە",
    accountVerificationFailed:
      " سەرکەوتوو نەبوو لە پشتڕاستکردنەوەی ئەژمێرەکەت تکایە دووبارە هەوڵ بدە ",
    goHome: " گەڕانەوە بۆ لاپەڕەی سەرەکی ",
    attendees: " ئامادەبوان ",

    month: "مانگ",
    monthly: "مانگانە",
    months: "مانگەکان",
    month_s: "مانگ",
    package: " پاکێج ",
    gift: "دیاری",

    isThisGift: " ئایا سەبسکریبشنی ئەم مۆمە بۆ تۆیە یان دیارییە بۆ کەسێکی تر?",

    step1: " هەنگاوی ١",
    step2: "هەنگاوی ٢",
    step3: "هەنگاوی ٣",
    yes: "بەڵی",
    no: "نەخێر",
    largeFile: " فایلەکە زۆر گەورەیە ",
    fileType: " جۆری فایل پشتگیری نەکراوە ",
    packageName: "ناوی پاکێج",
    GiftType: "جۆری دیاری",
    forMe: "بۆ من",
    itIsGift: "بۆ دیاری",
    attachment: " هاوپێچ ",
    download: "دابەزاندن",
    image: "وێنە",

    document_: "دۆکیومێنت",
    groupOf: "گروپی",
    chooseShipping:
      " یەکێک لە هەڵبژاردەکانی سەرەوە هەڵبژێرە پێش ئەوەی بەردەوام بێت ",
    deleteReviewQ: "دڵنیایت لە سڕینەوەی ئەم پێداچوونەوەیە",
    delete: "سڕینەوە",
    cancel: "پاشگەزبوونەوە",
    success: "سەرکەوتوو بوو",
    fail: " شکست ",
    loading: "چاوەڕوانبە",
    coma: "،",
    requestQuote:
      " دەتەوێت شتێکی هاوشێوەت هەبێت بۆ بازرگانیەکەت؟ پەیوەندیمان پێوە بکە  یان ئێستا داوای ئۆفەرێک بکە! ",
    quoteSuccess: "  داواکەت بە سەرکەوتوویی نێردراوە.",
    youWillBeContacted: " بە زووترین کات پەیوەندیت پێوە دەکرێت.",
    quoteFail:
      " سەرکەوتوو نەبوو لە ناردنی داواکاریەکەت، تکایە دووبارە هەوڵ بدە.",
    chooseOption: " یەکێک لە هەڵبژاردەکانی سەرەوە هەڵبژێرە، تکایە!",
    addLogo: " لۆگۆیەک زیادبکە ئەگەر دەتەوێت ناونیشانێکی تایبەت زیادبکەیت.",
    enterQuantity: "تکایە ژمارەیەک دابنێ!",
    enterName: "ناوت زیادبکە.",
    enterEmail: " ئیمەیڵەکەت بنوسە بۆ ئەوەی بتوانین پەیوەندیت پێوە بکەین.",
    enterPhone:
      " ژمارەی تەلەفۆنەکەت بنوسە بۆ ئەوەی بتوانین پەیوەندیت پێوە بکەین.",

    chooseCity: "شار هەڵبژێرە !",
    enterAddress: " تکایە ناونیشانی گەیاندن بنووسە!",
    chooseLocation: "ناونیشانی هەڵگرتن بنووسە",
    pickColor: "ڕەنگێک هەڵبژێرە",
    pickScent: "بۆنێک هەڵبژێرە",
    orderFail:
      " سەرکەوتوو نەبوو لە پڕۆسەی داواکاریەکەت، تکایە دواتر دووبارە هەوڵ بدە.",
    reviewAdded: " تۆ بە سەرکەوتوویی پێداچونەوەی خۆتت پێشکەش کرد.",
    reviewFail:
      " سەرکەوتوو نەبوو لە پێشکەشکردنی پێداچونەوەکەت، دواتر دووبارە هەوڵ بدە.",
    reviewDeleted: " تۆ بەسەرکەوتوویی پێداچوونەوەکەت سڕیەوە.",
    reviewDeleteFail: " سەرکەوتوو نەبوو لە سڕینەوەی پێداچونەوەکەت.",
    alreadyReviewed: "زووتر پێداچوونەوەت بۆ ئەم بەرهەمە کردووە.",
    subscriptionFail: " هەڵەیەک ڕوویدا، تکایە دواتر هەوڵ بدە!",
    personalizedFail:
      " سەرکەوتوو نەبوو لە ناردنی داواکاریەکەت، تکایە دووبارە هەوڵ بدە.",
    requestSuccess: " داواکەت بە سەرکەوتوویی نێردرا.",
    orderSuccess: " داواکارییەکەت بە سەرکەوتوویی نێردرا.",
    newsletterSuccess: " تۆ ئێستا بەشداریت کردووە لە هەواڵنامەی ئێمە ",
    newsletterFail: " سەرکەوتوو نەبوو لە بەشداری کردن لە هەواڵنامەکەمان ",
    checkEmail:
      "ئیمەیڵی پەسەندکردن بە ئیمەیڵەکەت بە سەرکەوتوویی نێردرا، تکایە ئیمەیڵەکەت بپشکنە.",
    passwordResetSuccess: " ووشەی نهێنیت بە سەرکەوتوویی نوێکرایەوە ",
    facebook: "Facebook",
    instagram: "Instagram",
    twitter: "Twitter",
    passwordLength: " وشەی نهێنی دەبێت 8 پیت  یان زیاتربێت ",
    needToSignIn: " پێویستە بچیتە ژوورەوە بۆ جێبەجێ کردنی ئەم ئەرکە ",
    needToBeVerified: " ئەژمێرەکەت پێویستە پەسەند بکرێت ",
    inlcuded: " لەخۆدەگرێت ",
    notInlcuded: "لەخۆ ناگرێت",
    emailTaken: "  ئەم ئیمەیڵە پێشتر بەکارهاتووە ",
    phoneTaken: " ئەم ژمارەیە پێشتر بەکارهاتووە ",
    errorOccured: " هەڵەیەک ڕوویدا، تکایە دووبارە هەوڵ بدە!",
    nameRequired: " ناو پێویستە ",
    emailRequired: " ئیمەیڵ پێویستە ",
    phoneRequired: " تەلەفۆن پێویستە ",
    addressRequired: "ناونیشان پێویستە",
    cityRequired: " شار پێویستە ",
    passwordRequired: "  ووشەی نهێنی پێویستە ",
    acceptPrice: " قەبووڵکردنی نرخ ",
    description: " وەسف ",
    priceAccepted: " نرخ پەسندکرا ",
    copyrightPre: "Copyright",
    copyright: "Lala Candles - Website by",
    submit: " ناردن ",
    phoneInvalid: " ژمارەی تەلەفۆن نادروستە ",
    chooseMethod: " هەڵبژاردنی شێوازی پارەدان ",
    paymentMethod: "شێوازی پارەدان",
    cash: "کاش",
    nasswallet: "ناس واڵێت",
    zaincash: "زەین کاش",
    fastpay: "فاستپەی",
    noDrop: "هیچ خاڵێکی دانان نییە لە شارەکەت",
    paymentSuccess: " پارەدان سەرکەوتوو بوو ",
    paymentFail: " پارەدان سەرکەوتوونە بوو ",
    paid: "پارەی دراوە",
    completeRegistration: " تەواوکردنی تۆمارکردن ",
    addNumber: " زیادکردنی ژمارە ",
    addLater: " دواتر زیاد بکە",
    emailFormat: "ئیمەیلەکەت هەڵەیە.",
    required: "ئەم خانەیە داواکراوە",
    gender: "ڕەگەز",
    chooseGender: "ڕەگەزت هەڵبژێرە",
    male: "نێر",
    female: "مێ",
    preferNotToSay: "پێمباشە نەیڵێم",
    dob: "بەرواری لەدایکبوون",
    dobRule: "پێویستە ١٨ ساڵ یان زیاتر بیت بۆ خۆتۆمارکردن",
    cartIsEmpty: "سەبەتەکەت بەتاڵە",
    noOrders: "هیچ داواکارییەکت نییە",
    noProducts: "هیچ بەرهەمێکی لەم جۆرە بەردەست نییە",
    pageNotFound: "ئەم پەڕەیە بوونی نییە",
    badRequest: "هەڵەیەک ڕوویدا",
    tryAgainLater: "تکایە دواتر هەوڵ بدە",
    booked: "Booked",
    moreThanStock: "ئەو بڕەی داوات کردووە زیاترە لەوەی کە لە کۆگا بەردەستە",
    loadMore: "نیشاندانی زیاتر",
    currentlyUnavailable: "لە ئێستادا بەردەست نییە."
  },
};
