<template>
  <router-link :to="to">
    <div class="flex items-center cursor-pointer btn-all">
      <div class="btn-text text-borderColor">{{ text }}</div>
      <i
        class="
          fas
          fa-chevron-right
          btn-arrow-icon
          text-base text-borderColor
          btn-arrow-icon
        "
      ></i>
    </div>
  </router-link>
</template>

<script>
export default {
  props: ["text", "to"],
};
</script>
<style scoped>
.btn-arrow-icon {
  margin-inline-start: 10px;

  margin-inline-end: 10px;
  transition: 0.3s all ease-out;
}
.btn-text {
  transition: 0.3s all ease-out;
}
.btn-all:hover .btn-text {
  margin-inline-end: 10px;
}
.rtl .fas {
  transform: rotate(180deg);
}
.btn-all:hover .btn-arrow-icon {
  margin-inline-end: 0px;
}
</style>