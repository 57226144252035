<template>
  <div class="product-review-card">
    <div class="flex flex-wrap justify-between">
      <div class="flex items-center whitespace-nowrap mb-4">
        <div
          class="customer-image-review me-4"
          :style="`background: url(${
            review.customer.profile
              ? review.customer.profile
              : '/static/images/profile-ph.png'
          }) center center / cover no-repeat`"
        ></div>
        <div>
          <div class="lora-bold">{{ review.customer.name }}</div>
          <div>{{ date }}</div>
        </div>
      </div>
      <div class="rating text-size-16">
        <div
          class="rating-upper"
          :style="`width:calc( ( ${review.rating} / 5 ) * 100%)`"
        >
          <span><i class="fas fa-star"></i></span>
          <span><i class="fas fa-star"></i></span>
          <span><i class="fas fa-star"></i></span>
          <span><i class="fas fa-star"></i></span>
          <span><i class="fas fa-star"></i></span>
        </div>
        <div class="rating-lower">
          <span><i class="fas fa-star"></i></span>
          <span><i class="fas fa-star"></i></span>
          <span><i class="fas fa-star"></i></span>
          <span><i class="fas fa-star"></i></span>
          <span><i class="fas fa-star"></i></span>
        </div>
      </div>
    </div>
    <div class="mt-4">
      {{ review.review }}
    </div>
  </div>
</template>

<script>
export default {
  props: ["review"],
  computed: {
    date() {
      return `${this.review?.date.split(",")[2]}/${
        this.review?.date.split(",")[1]
      }/${this.review?.date.split(",")[0]}`;
    },
  },
};
</script>