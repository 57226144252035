<template>
  <div class="category-card-container">
    <div class="category-card" :style="`background: url(${img}) `">
      <div class="px-8 lora-bold">{{ title }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["img", "title", "id"],
};
</script>