<template>
  <div class="grid place-content-center layout-padding mt-4 md:mt-8">
    <Alert
      v-if="error"
      customClasses="max-w-550"
      type="fail"
      :title="error"
      :message="message"
    >
      <span
        v-if="buttonMessage"
        class="
          alert-btn
          text-text-white text-white
          lora-bold
          whitespace-nowrap
          bg-secondaryColor
          duration-300
          mt-6
        "
      >
        {{ buttonMessage }}
      </span>
    </Alert>
    <!-- <Alert
      customClasses="max-w-550"
      type="success"
      :title="content.accountCreationWasntSuccessful"
      :message="content.numberIsUsed"
    >
      <button
        class="
          alert-btn
          text-text-white text-white
          lora-bold
          whitespace-nowrap
          bg-secondaryColor
          duration-300
          mt-6
        "
      >
        {{ content.tryWithDifferentNumber }}
      </button>
    </Alert> -->
    <!-- <Alert
      customClasses="max-w-550"
      type="info"
      message="Passwords must be 8 Character long, contain  at least 1 letter and 1 number and a special character "
    >
    </Alert> -->
    <div class="signin-container flex flex-col items-center my-16">
      <div class="text-size-24 section-padding">
        {{ content.register }}
      </div>
      <div class="mt-5 lora-bold section-padding">
        {{ content.alreadyHaveAnAccount }}
        <router-link to="/signin">
          <span class="text-secondaryColor lora-bold">
            {{ content.signIn }}
          </span>
        </router-link>
      </div>
      <form @submit.prevent="recaptcha" class="mt-8 w-full section-padding">
        <div class="grid grid-cols-4">
          <label for="name" class="col-span-1 flex items-center">
            {{ content.name }}
          </label>
          <input
            type="text"
            class="form-input col-span-4 sm:col-span-3"
            v-model.trim="user.name"
            @change="clearError('name')"
            id="name"
            :placeholder="content.name"
          />
          <p v-if="showErrors" class="error h-3 mt-2 col-span-3 col-start-2">
            {{ errors.name }}
          </p>
        </div>
        <div class="grid grid-cols-4 mt-4">
          <label for="email" class="col-span-1 flex items-center">
            {{ content.email }}
          </label>
          <input
            type="email"
            id="email"
            class="form-input col-span-4 sm:col-span-3"
            v-model.trim="user.email"
            @change="clearError('email')"
            placeholder="example@companyname.com"
          />
          <p v-if="showErrors" class="error h-3 mt-2 col-span-3 col-start-2">
            {{ errors.email }}
          </p>
        </div>
        <div class="grid grid-cols-4 mt-4">
          <label for="phone" class="col-span-1 flex items-center">
            {{ content.phone }}
          </label>
          <input
            type="text"
            id="phone"
            class="form-input col-span-4 sm:col-span-3"
            v-model="phone"
            @change="clearPhoneError()"
            placeholder="07xx xxx xxxx"
          />
          <p v-if="showErrors" class="error h-3 mt-2 col-span-3 col-start-2">
            {{ errors.phone }}
          </p>
        </div>
        <div class="grid grid-cols-4 mt-4">
          <label for="city" class="col-span-1 flex items-center">
            {{ content.city }}
          </label>
          <select
            id="city"
            class="form-input bg-white col-span-4 sm:col-span-3"
            v-model="user.city"
          >
            <option disabled selected :value="null">{{ content.city }}</option>
            <option
              v-for="city in cities"
              :key="city.id"
              :value="city.id"
              @change="clearError('city')"
            >
              {{ city.name }}
            </option>
          </select>
          <p v-if="showErrors" class="error h-3 mt-2 col-span-3 col-start-2">
            {{ errors.city }}
          </p>
        </div>
        <div class="grid grid-cols-4 mt-4">
          <label for="dob" class="col-span-1 flex items-center">
            {{ content.dob }}
          </label>
          <input
            type="date"
            id="dob"
            class="form-input col-span-4 sm:col-span-3"
            v-model="user.dob"
          />
          <p v-if="showErrors" class="error h-3 mt-2 col-span-3 col-start-2">
            {{ errors.dob }}
          </p>
        </div>
        <div class="grid grid-cols-4 mt-4">
          <label for="gender" class="col-span-1 flex items-center">
            {{ content.gender }}
          </label>
          <select
            id="gender"
            class="form-input bg-white col-span-4 sm:col-span-3"
            v-model="user.gender"
          >
            <option disabled selected :value="null">
              {{ content.chooseGender }}
            </option>
            <option value="male">{{ content.male }}</option>
            <option value="female">{{ content.female }}</option>
            <option :value="null">{{ content.preferNotToSay }}</option>
          </select>
          <p v-if="showErrors" class="error h-3 mt-2 col-span-3 col-start-2">
            {{ errors.gender }}
          </p>
        </div>
        <div class="grid grid-cols-4 mt-4">
          <label for="password" class="col-span-1 flex items-center">
            {{ content.password }}
          </label>
          <input
            :type="showPassword ? 'text' : 'password'"
            id="password"
            class="form-input col-span-4 sm:col-span-3"
            v-model="user.password"
            @change="clearError('password')"
            placeholder="********"
          />
          <p v-if="showErrors" class="error h-3 mt-2 col-span-3 col-start-2">
            {{ errors.password }}
          </p>
        </div>
        <div class="grid grid-cols-4 mt-4">
          <label for="file" class="col-span-4 sm:col-span-1 flex items-center">
            {{ content.profileImage }}
          </label>
          <label
            for="file"
            class="w-full col-span-4 sm:col-span-3 border-with-radius"
          >
            <input type="file" id="file" class="hidden" @change="setImage" />
            <div class="flex justify-between w-full">
              <div class="flex items-center ms-4">{{ content.uploadFile }}</div>
              <div class="upload-select lora-bold">{{ content.select }}</div>
            </div>
          </label>
        </div>
        <div class="grid grid-cols-4 mt-12">
          <div class="col-span-1 flex items-center"></div>
          <div
            class="col-span-4 sm:col-span-3 flex justify-between items-center"
          >
            <div class="text-size-12 w-max-content">
              {{ content.agreeTerms }}
              <span class="lora-bold cursor-pointer">
                <router-link to="/terms"> {{ content.terms }}. </router-link>
              </span>
            </div>
            <div class="text-size-12 me-1 w-max-content cursor-pointer">
              <button
                v-if="canRegister"
                class="
                  flex
                  items-center
                  justify-between
                  w-max-content
                  btn-signIn
                "
              >
                <div class="lora-bold">
                  {{ content.register }}
                </div>
              </button>

              <button
                v-else
                class="btn-signIn w-max-content lora-bold"
                disabled
              >
                {{ content.register }}
              </button>
              <!-- <button @click="recaptcha" type="button">recaptcha</button> -->
            </div>
          </div>
        </div>
      </form>
      <SocialLogin />
    </div>
  </div>
</template>

<script>
import addPhoto from "../../../imageUpload";
import SocialLogin from "./SocialLogin.vue";
// import recaptcha from "../../../assets/googleRecaptcha";

export default {
  components: { SocialLogin },
  inject: ["content"],
  data() {
    return {
      user: {
        email: "",
        password: "",
        name: "",
        city: null,
        dob: null,
        gender: null,
      },
      phone: "",
      errors: {
        email: null,
        password: null,
        name: null,
        phone: null,
        city: null,
        dob: null,
      },
      image: null,
      showPassword: false,
      error: null,
      message: null,
      buttonMessage: null,
      formatLoading: false,
      canRegister: false,
      showErrors: false,
      token: null,
    };
  },
  update() {
    if (!this.validation()) return;
    this.canRegister = true;
  },
  watch: {
    user: {
      handler() {
        if (!this.validation()) return;
        this.canRegister = true;
      },
      deep: true,
    },
    phone(newValue, oldValue) {
      this.clearError("phone");

      if (newValue.length > oldValue.length) {
        let postValue = newValue.replace(oldValue, "");
        if (
          !"٠١٢٣٤٥٦٧٨٩".includes(postValue) &&
          !"0123456789".includes(postValue)
        ) {
          this.phone = oldValue;
          return;
        }
      }

      let number = "";
      for (let i = 0; i < newValue.length; i++) {
        if ("٠١٢٣٤٥٦٧٨٩".includes(newValue[i]))
          number += this.formatPhone(newValue[i]);
        else number += newValue[i];
      }
      this.phone = number;

      if (number > 7799999999) {
        this.phone = oldValue;
        return;
      }

      if (!this.validation()) return;
      this.canRegister = true;
    },
  },
  // created() {
  //   this.$watch("user", (newUser) => {
  //     console.log(this.user, newUser);
  //   });
  // },
  // mounted() {
  //   // console.log(recaptcha);
  //   recaptcha.load();
  // },
  // unmounted() {
  //   recaptcha.unload();
  // },
  computed: {
    cities() {
      return this.$store.getters["cities/cities"];
    },
    intended() {
      return this.$store.getters.intended;
    },
  },
  methods: {
    async recaptcha() {
      let grecaptcha = window.grecaptcha;

      grecaptcha.ready(() => {
        grecaptcha
          .execute("6LeeHJEeAAAAAHNJtMdpCWjDX6bD6rJTzJfmDkTc", {
            action: "submit",
          })
          .then((token) => {
            this.token = token;
            this.register();
          });
      });
    },
    togglePassword() {
      this.showPassword = !this.showPassword;
    },
    setImage(event) {
      let image = event.target.files[0];

      if (image.size / 1024 > 2500) {
        this.error = this.content.largeFile;
        return;
      }

      if (image["type"].split("/")[0] != "image") {
        this.error = this.content.fileType;
        return;
      }

      let reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = async (e) => {
        this.showImage = e.target.result;
        this.$emit("done", image);
      };

      this.image = image;
    },
    formatPhone: (n) => "0123456789"["٠١٢٣٤٥٦٧٨٩".indexOf(n)],
    clearPhoneError() {
      this.errors.phone = "";
    },
    clearError(type) {
      if (type == "city") this.errors.city = null;
      else this.errors[type] = "";
    },
    validation() {
      this.buttonMessage = null;
      let pass = true;

      this.errors = {
        email: null,
        password: null,
        name: null,
        phone: null,
        city: null,
      };

      if (this.user.email == "") {
        this.errors.email = this.content.emailRequired;
        pass = false;
      }
      if (this.user.name == "") {
        this.errors.name = this.content.nameRequired;
        pass = false;
      }

      if (!this.phone || this.phone == "") {
        this.errors.phone = this.content.phoneRequired;
        pass = false;
      }

      
      // phone number should be in the format of 07xx xxx xxxx
      if (!/^07[0-9]{9}$/.test(this.phone) && !/^7[0-9]{9}$/.test(this.phone)) {
        this.errors.phone = this.content.phoneInvalid;
        pass = false;
      }

      if (this.user.password == "") {
        this.errors.password = this.content.passwordRequired;
        pass = false;
      }
      if (!this.user.city) {
        this.errors.city = this.content.cityRequired;
        pass = false;
      }

      // date of birth validation to be more than 18 years
      if (this.user.dob) {
        let today = new Date();
        let birthDate = new Date(this.user.dob);
        let date = new Date(
          birthDate.getFullYear() + 18,
          birthDate.getMonth(),
          birthDate.getDate()
        );

        if (date > today) {
          this.errors.dob = this.content.dobRule;
          pass = false;
        }

        // this.user.dob = `${birthDate.getFullYear()}-${birthDate.getMonth()}-${birthDate.getDate()}`
      }

      return pass;
    },
    async register() {
      this.showErrors = false;
      if (!this.validation()) {
        this.showErrors = true;
        return;
      }
      this.error = null;
      this.message = null;
      if (this.user.password.length < 8) {
        this.errors.password = this.content.passwordLength;
        return;
      }
      try {
        this.canRegister = false;
        let link = null;
        if (this.image) link = await addPhoto(this.image);

        let input = { ...this.user, phone: this.phone, token: this.token };
        if (link) input.profile = link;

        // console.log(input);

        await this.$store.dispatch("auth/register", input);
        await this.$store.dispatch("cart/saveLocalToDB");
        await this.$store.dispatch("refill/saveLocalToDB");
        let route = this.intended ?? "/";
        await this.$store.dispatch("setIntended", null);
        this.canRegister = true;
        this.$router.replace(route);
      } catch (error) {
        this.canRegister = true;
        let message = "";
        for (const key in error[0].extensions.validation) {
          message = error[0].extensions.validation[key][0];
          break;
        }
        this.error = this.content.fail;
        if (message == "input.email.duplicate") {
          this.message = this.content.emailTaken;
          this.buttonMessage = this.content.tryWithDifferentEmail;
        } else if (message == "input.phone.duplicate") {
          this.message = this.content.phoneTaken;
          this.buttonMessage = this.content.tryWithDifferentNumber;
        } else if (message == "bad.request") {
          this.message = this.content.badRequest;
          this.buttonMessage = this.content.subscriptionFail;
        } else this.message = this.content.errorOccured;

        window.scrollTo(0, 0);
      }
    },
  },
};
</script>
