<template>
  <!-- <teleport to="body"> -->
  <div class="text-bluePrimary" v-if="footer">
    <div class="footer layout-padding bg-blueLight grid grid-cols-4 gap-x-20">
      <div class="col-span-4 lg:my-0 my-8 lg:col-span-2 md:w-6/12 lg:w-10/12">
        <!-- <div class="title lora-bold text-bluePrimary">
            {{ content.signUpAndSave }}
          </div>
          <div class="mt-4">{{ content.signUpAndSaveContent }}</div> -->
        <div class="title lora-bold text-bluePrimary">
          {{ footer.title }}
        </div>
        <div class="mt-4">{{ footer.description }}</div>
        <div class="relative">
          <input
            type="text"
            class="mt-4 subscribe-input"
            v-model="email"
            :placeholder="content.email"
          />
          <button
            @click="recaptcha"
            class="subscribe-btn lora-bold text-primaryColor"
          >
            {{ content.subscribe }}
          </button>
        </div>
      </div>
      <div class="col-span-4 lg:my-0 my-8 lg:col-span-1" v-if="contact">
        <div class="title lora-bold text-bluePrimary">
          {{ content.contactUs }}
        </div>
        <div class="mt-2">{{ content.email }}: {{ contactEmail?.value }}</div>
        <div class="mt-2">{{ content.phone }}: {{ phone?.value }}</div>
        <div class="mt-2">{{ content.address }}: {{ address?.value }}</div>
      </div>
      <div class="col-span-4 lg:my-0 my-8 lg:col-span-1">
        <div class="title lora-bold text-bluePrimary">
          {{ content.navigate }}
        </div>
        <router-link to="/sellingpoints/sellingsites">
          <div class="mt-2 hover:underline duration-300 transition-all cursor-pointer">
            {{ content.sellingSites }}
          </div>
        </router-link>
        <router-link to="/events">
          <div class="mt-2 hover:underline duration-300 transition-all cursor-pointer">
            {{ content.eventsCalendar }}
          </div>
        </router-link>
        <router-link to="/whitelabel/request">
          <div class="mt-2 hover:underline duration-300 transition-all cursor-pointer">
            {{ content.WholesaleCollaboration }}
          </div>
        </router-link>
        <router-link to="/press">
          <div class="mt-2 hover:underline duration-300 transition-all cursor-pointer">
            {{ content.pressAndMentions }}
          </div>
        </router-link>
        <router-link to="/shippingprices">
          <div class="mt-2 hover:underline duration-300 transition-all cursor-pointer">
            {{ content.shippingPrices }}
          </div>
        </router-link>
        <router-link to="/privacypolicy">
          <div class="mt-2 hover:underline duration-300 transition-all cursor-pointer">
            {{ content.privacyPolicy }}
          </div>
        </router-link>
        <router-link to="/terms">
          <div class="mt-2 hover:underline duration-300 transition-all cursor-pointer">
            {{ content.termsAndConditions }}
          </div>
        </router-link>
      </div>
      <div class="col-span-4">
        <div
          v-if="socialMedia"
          class="flex flex-wrap items-center lower-footer"
        >
          <div v-if="mobile" class="whitespace-nowrap py-1">
            <a :href="facebook.url" target="_blank" v-if="facebook">
              <i class="fab me-1 fa-facebook-square"></i>
            </a>
            <a :href="instagram.url" target="_blank" v-if="instagram">
              <i class="fab mx-1 fa-instagram"></i>
            </a>
            <a :href="twitter.url" target="_blank" v-if="twitter">
              <i class="fab mx-1 fa-twitter-square"></i>
            </a>
            <a :href="pinterest.url" target="_blank" v-if="pinterest">
              <i class="fab mx-1 fa-pinterest-square"></i>
            </a>
            <a :href="tiktok.url" target="_blank" v-if="tiktok">
              <i class="fab mx-1 fa-tiktok"></i>
            </a>
          </div>
          <div v-else class="whitespace-nowrap py-1">
            <button @click="newWindow(facebook.url)" v-if="facebook">
              <i class="fab me-1 fa-facebook-square"></i>
            </button>
            <button @click="newWindow(instagram.url)" v-if="instagram">
              <i class="fab mx-1 fa-instagram"></i>
            </button>
            <button @click="newWindow(twitter.url)" v-if="twitter">
              <i class="fab mx-1 fa-twitter-square"></i>
            </button>
            <button @click="newWindow(pinterest.url)" v-if="pinterest">
              <i class="fab mx-1 fa-pinterest-square"></i>
            </button>
            <button @click="newWindow(tiktok.url)" v-if="tiktok">
              <i class="fab mx-1 fa-tiktok"></i>
            </button>
          </div>
          <div class="py-1">
            {{ content.copyrightPre }} {{ year }}
            {{ content.copyright }}
            <a href="https://blackace.tech" class="font-bold" target="_blank"
              >Black Ace</a
            >
          </div>
          <div></div>
        </div>
      </div>
    </div>
  </div>
  <!-- </teleport> -->
</template>

<script>
export default {
  inject: ["content"],
  data() {
    return {
      email: "",
      token: null,
    };
  },
  computed: {
    mobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      }
      return false;
    },
    year() {
      let year = new Date().getFullYear();

      // let lang = localStorage.getItem("lang");
      // if (lang == "ku" || lang == "ar")
      //   return new Intl.NumberFormat("ar", { maximumSignificantDigits: 5 }).format(year)
      return year;
    },
    socialMedia() {
      return this.$store.getters["contact/socialMedia"];
    },
    facebook() {
      return this.socialMedia.filter((item) => item.title == "facebook")[0];
    },
    instagram() {
      return this.socialMedia.filter((item) => item.title == "instagram")[0];
    },
    twitter() {
      return this.socialMedia.filter((item) => item.title == "twitter")[0];
    },
    pinterest() {
      return this.socialMedia.filter((item) => item.title == "pinterest")[0];
    },
    tiktok() {
      return this.socialMedia.filter((item) => item.title == "tiktok")[0];
    },
    contact() {
      return this.$store.getters["contact/contact"];
    },
    contactEmail() {
      return this.contact?.filter((item) => item.type == "email")[0];
    },
    phone() {
      return this.contact?.filter((item) => item.type == "phone")[0];
    },
    address() {
      return this.contact?.filter((item) => item.type == "address")[0];
    },
    footer() {
      return this.$store.getters["pages/text"]("footer");
    },
  },
  methods: {
    newWindow(url) {
      window.open(url, "Name", "popup");
    },
    validateEmail(inputed) {
      if (inputed === "" || inputed == null) {
        return this.content.required;
      } else if (!inputed.includes("@") || !inputed.includes(".")) {
        return this.content.emailFormat;
      } else {
        return null;
      }
    },
    async recaptcha() {
      let grecaptcha = window.grecaptcha;

      grecaptcha.ready(() => {
        grecaptcha
          .execute("6LeeHJEeAAAAAHNJtMdpCWjDX6bD6rJTzJfmDkTc", {
            action: "submit",
          })
          .then((token) => {
            this.token = token;
            this.newsletterSubscription();
          });
      });
    },
    async newsletterSubscription() {
      try {
        let emailError = this.validateEmail(this.email);
        if (!emailError) {
          await this.$store.dispatch("newsletter/subscribe", {
            email: this.email,
            token: this.token,
          });
          this.$store.dispatch("feedback/setFeedback", {
            type: "success",
            title: this.content.success,
            message: this.content.newsletterSuccess,
          });
        } else {
          this.$store.dispatch("feedback/setFeedback", {
            type: "fail",
            title: this.content.fail,
            message: emailError,
          });
        }
      } catch (error) {
        if (error[0].message == "bad.request") {
          this.$store.dispatch("feedback/setFeedback", {
            type: "fail",
            title: this.content.badRequest,
            message: this.content.tryAgainLater,
          });
        } else {
          this.$store.dispatch("feedback/setFeedback", {
            type: "fail",
            title: this.content.fail,
            message: this.content.newsletterFail,
          });
        }
      }
      this.email = "";
    },
  },
};
</script>