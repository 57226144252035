<template>
  <hr class="hr-text w-full h-24 my-12" :data-content="content.orConnectUsing" />
  <div class="section-padding flex flex-col items-center">
    <div class="signin-with-google grid grid-cols-4 place-content-center lora-bold mt-6">
      <img src="/static/images/icons/google.png" class="me-6 col-span-1" />
      <div @click="loginWithGoogle" class="col-span-3">
        {{ content.signInWithGoogle }}
      </div>
    </div>
    <div
      class="signin-with-facebook grid grid-cols-4 place-content-center lora-bold mt-4"
    >
      <img src="/static/images/icons/facebook.png" class="me-6 col-span-1" />
      <div @click="loginWithFacebook" class="col-span-3">
        {{ content.signInWithFacebook }}
      </div>
    </div>
  </div>
</template>

<script>
// import { initFbsdk } from "../../../config/facebookAuth";

export default {
  inject: ["content"],
  computed: {
    intended() {
      return this.$store.getters.intended;
    },
  },
  // mounted() {
  //   initFbsdk();
  // },
  methods: {
    async loginWithGoogle() {
      let input = null;
      try {
        const googleUser = await this.$gAuth.signIn();
        if (!googleUser) {
          return null;
        }

        let profile = googleUser.getBasicProfile();

        input = {
          id: profile.getId(),
          name: profile.getName(),
          email: profile.getEmail(),
          profile: profile.getImageUrl(),
          type: "google",
        };
        // console.log(input);
        this.socialLogin(input);
      } catch (error) {
        //on fail do something
        console.error(error);
        return null;
      }
    },
    loginWithFacebook() {
      window.FB.login(
        (response) => {
          if (response.status === "connected") {
            window.FB.api("/me", { fields: "name,email,picture" }, (response) => {
              // console.log(response);
              this.socialLogin({
                id: response?.id,
                name: response?.name,
                email: response?.email,
                profile: response?.picture?.data?.url ?? null,
                type: "facebook",
              });
            });
          } else {
            // show feedback
          }
        },
        { scope: "public_profile,email" }
      );

    },
    async socialLogin(input) {
      try {
        await this.$store.dispatch("auth/socialLogin", input);
        let route = this.intended ?? "/";
        await this.$store.dispatch("setIntended", null);

        let user = await this.$store.getters["user/user"];
        if (user) {
          // this.$gtag.event("login-age", {
          //   event_category: "age",
          //   event_label: 'Login By Age',
          //   value: user.age,
          // });
          let gender = user.gender ?? "None";
          // this.$gtag.event("login-gender", {
          //   event_category: "gender",
          //   event_label: 'Login By Gender',
          //   value: gender,
          // });
          this.$gtag.customMap({ dimension2: "age" });
          this.$gtag.event("age_dimension", { age: user.age });
          this.$gtag.customMap({ dimension2: "gender" });
          this.$gtag.event("gender_dimension", { gender: gender });
        }

        setTimeout(() => {
          this.$store.dispatch("user/setPopup", true);
        }, 200);
        this.$router.replace(route);
      } catch (error) {
        //
      }
    },
  },
};
</script>
