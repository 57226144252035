import { http } from '../../../graphql'
import gql from "graphql-tag";

export default {
  wicks: async context => {
    let query = gql`
      query wicks {
        wicks{
          id
          title
          image
          price
        }
      }
    `
    const response = await http(true, query, false)
    context.commit('setWicks', response.wicks)
  },
  scents: async context => {
    let query = gql`
      query scents {
        scents {
          id
          title
          image
        }
      }
    `
    const response = await http(true, query, false)
    context.commit('setScents', response.scents)
  },
  sizes: async context => {
    let query = gql`
      query sizes {
        sizes{
          id
          title
          value
        }
      }
    `
    const response = await http(true, query, false)
    context.commit('setSizes', response.sizes)
  },
  colors: async context => {
    let query = gql`
      query colors {
        colors {
          id
          title
          color
          label
          refill
        }
      }
    `
    const response = await http(true, query, false)
    context.commit('setColors', response.colors)
  },
  packages: async context => {
    let query = gql`
      query packages {
        packages {
          id
          title
          price
          image
        }
      }
    `
    const response = await http(true, query, false)
    context.commit('setPackages', response.packages)
  },
  containers: async context => {
    let query = gql`
      query containers {
        containers {
          id
          title
          image
          sizes {
            id
            price
            size {
              id
              title
              value
            }
          }
        }
      }
    `
    const response = await http(true, query, false)
    context.commit('setContainers', response.containers)
  },
  refillContainers: async context => {
    let query = gql`
      query refillContainers {
        refillContainers {
          id
          title
        }
      }
    `
    const response = await http(true, query, false)
    context.commit('setRefillContainers', response.refillContainers)
  },
}